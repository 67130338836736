import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { SnackbarUtil } from '../../components/SnackbarUtilsConfig';
import { API_ERROR_RESPONSE } from '../../utils/constant';
import {
    fetchPaymentSetupStatusAPI,
    fetchUserSubscriptionStatusAPI,
    fetchUserCardsAPI,
    cancelSubscriptionAPI,
    removeUserCardAPI,
    updateUserCardAPI,
    fetchSubscriptionHistoryAPI,
    fetchPricingPlansAPI,
    resubscribeAPI,
    fetchSubscriptionStatusAPI,
    fetchSubscriptionProratedAmountAPI,
    updateSubscriptionAPI,
    rescindCancellationAPI,
    fetchSubscriptionAPI
} from '../../api/paymentAPI';
import { updateUserStore } from '../user/userSlice';

const initialState = {
    paymentSetupStatus: '',
    fetchPaymentStatusCompleted: false,
    fetchPaymentStatusLoading: false,

    subscriptionInfo: null,
    subscriptionStatusLoading: false,
    fetchSubscriptionCompleted: false,

    pricingPlans: {},
    userCards: [],
    userCardsLoading: false,
    fetchUserCardsCompleted: false,

    fetchPricingFeaturesLoading: false,

    cancelSubscriptionLoading: false,
    rescindCancelLoading: false,

    updateUserPaymentLoading: false,

    fetchBillingHistoryCompleted: false,
    billingHistory: [],
    billHistoryLoading: false,

    resubscribeLoading: false,

    stripeSubscription: null
};

export const fetchPaymentSetupStatus = createAsyncThunk(
    'paymentSetupStatus/fetch',
    async ({ schoolId, signal }) => {
        const data = await fetchPaymentSetupStatusAPI(schoolId, signal);
        if (data instanceof DOMException) {
            throw data;
        }
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const fetchUserSubscriptionStatus = createAsyncThunk(
    'subscription/user-status',
    async (arg, { dispatch }) => {
        const data = await fetchUserSubscriptionStatusAPI();
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE );
        } else if (data.status === 'success' && (!arg || arg.update)) {
            const { subscriptionInfo } = data;
            dispatch(updateUserStore({
                subscriptionInfo: {
                    isActive: subscriptionInfo.isActive,
                    endAt: subscriptionInfo.endAt,
                    planId: subscriptionInfo.planId,
                }
            }));
        }
        return data;
    }
);

export const fetchUserCards = createAsyncThunk(
    'subscription/cards',
    async () => {
        const data = await fetchUserCardsAPI();
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const cancelSubscription = createAsyncThunk(
    'subscription/cancel',
    async (planId) => {
        const data = await cancelSubscriptionAPI(planId);
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const rescindCancellation = createAsyncThunk(
    'subscription/cancel/rescind',
    async () => {
        const data = await rescindCancellationAPI();
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const removeUserCard = createAsyncThunk(
    'user/card/remove',
    async (pmId) => {
        const data = await removeUserCardAPI(pmId);
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const updateUserCard = createAsyncThunk(
    'user/card/update',
    async (pmId) => {
        const data = await updateUserCardAPI(pmId);
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const fetchSubScriptionHistory = createAsyncThunk(
    'subscription/history',
    async () => {
        const data = await fetchSubscriptionHistoryAPI();
        if (data.status === 'fail' && data.error) {
            throw new Error(data.error.message  || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const fetchPricingPlans = createAsyncThunk(
    'subscription/plans',
    async (arg = {}) => {
        const { planIds = [], withFeatures = false } = arg;
        const data = await fetchPricingPlansAPI(planIds, withFeatures);
        if (data.status === 'fail' && data.error) {
            throw new Error(data.error.message|| API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const resubscribePlan = createAsyncThunk(
    'subscription/resubscribe',
    async ({ planId }) => {
        const data = await resubscribeAPI(planId);
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const fetchSubscriptionStatus = createAsyncThunk(
    'subscription/status',
    async () => {
        const data = await fetchSubscriptionStatusAPI();
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const fetchSubscriptionProratedAmount = createAsyncThunk(
    'subscription/prorated-amount',
    async (newPlanId) => {
        const data = await fetchSubscriptionProratedAmountAPI(newPlanId);
        if (data.status === 'fail' && data.error) {
            throw new Error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const updateSubscription = createAsyncThunk(
    'subscription/update',
    async ({ newPlanId, subscriptionUpdateType, newDashboardPlanType }) => {
        const data = await updateSubscriptionAPI(
            newPlanId,
            subscriptionUpdateType,
            newDashboardPlanType
        );
        if (data.status === 'fail' && data.error) {
            throw new Error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const fetchSubscription = createAsyncThunk(
    'subscription/fetch',
    async() => {
        const data = await fetchSubscriptionAPI();
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
)

const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        updatePaymentStore: (state, action) => {
            const { payload } = action;
            Object.keys(payload).forEach(key => {
                state[key] = payload[key];
            });
        },
        resetPaymentStatusAction: state => {
            state.paymentSetupStatus = '';
            state.fetchPaymentStatusCompleted = false;
        },
        resetStripeSubscription: state => {
            state.stripeSubscription = null;
        }
    },
    extraReducers: {
        [fetchPaymentSetupStatus.pending]: state => {
            state.fetchPaymentStatusLoading = true;
        },
        [fetchPaymentSetupStatus.fulfilled]: (state, action) => {
            const { status, paymentSetupStatus } = action.payload;
            if (status === 'success') {
                state.paymentSetupStatus = paymentSetupStatus;
            }
            state.fetchPaymentStatusLoading = false;
            state.fetchPaymentStatusCompleted = true;
        },
        [fetchUserSubscriptionStatus.pending]: state => {
            state.subscriptionStatusLoading = true;
        },
        [fetchUserSubscriptionStatus.fulfilled]: (state, action) => {
            const { arg } = action.meta;
            const { status, subscriptionInfo } = action.payload;
            if (status === 'success' && (!arg || arg.update)) {
                state.subscriptionInfo = subscriptionInfo
            }
            state.subscriptionStatusLoading = false;
            state.fetchSubscriptionCompleted = true;
        },
        [fetchUserSubscriptionStatus.rejected]: state => {
            state.subscriptionStatusLoading = false;
            state.fetchSubscriptionCompleted = true;
        },
        [fetchUserCards.pending]: state => {
            state.userCardsLoading = true;
        },
        [fetchUserCards.rejected]: state => {
            state.userCardsLoading = false;
            state.fetchUserCardsCompleted = true;
        },
        [fetchUserCards.fulfilled]: (state, action) => {
            const { status, cards } = action.payload;
            if (status === 'success') {
                state.userCards = cards;
            }
            state.userCardsLoading = false;
            state.fetchUserCardsCompleted = true;
        },
        [cancelSubscription.pending]: state => {
            state.cancelSubscriptionLoading = true;
        },
        [cancelSubscription.fulfilled]: (state, action) => {
            const { status } = action.payload;
            if (
                status === "success" &&
                state.subscriptionInfo &&
                !state.subscriptionInfo.isFreePlan &&
                !state.subscriptionInfo.subscriptionWillEnd
            ) {
                state.subscriptionInfo.subscriptionWillEnd = true;
            }
            state.cancelSubscriptionLoading = false;
        },
        [rescindCancellation.pending]: state => {
            state.rescindCancelLoading = true;
        },
        [rescindCancellation.fulfilled]: (state, action) => {
            const { status } = action.payload;
            if (status === "success" && state.subscriptionInfo && state.subscriptionInfo.subscriptionWillEnd) {
                state.subscriptionInfo.subscriptionWillEnd = false;
            }
            state.rescindCancelLoading = false;
        },
        [removeUserCard.pending]: state => {
            state.updateUserPaymentLoading = true;
        },
        [removeUserCard.fulfilled]: state => {
            state.updateUserPaymentLoading = false;
        },
        [updateUserCard.pending]: state => {
            state.updateUserPaymentLoading = true;
        },
        [updateUserCard.fulfilled]: state => {
            state.updateUserPaymentLoading = false;
        },
        [fetchSubScriptionHistory.pending]: state => {
            state.billHistoryLoading = true;
        },
        [fetchSubScriptionHistory.fulfilled]: (state, action) => {
            const { status, history } = action.payload;
            if (status === 'success') {
                state.billingHistory = history;
            }
            state.fetchBillingHistoryCompleted = true;
            state.billHistoryLoading = false;
        },
        [fetchSubScriptionHistory.rejected]: state => {
            state.fetchBillingHistoryCompleted = true;
            state.billHistoryLoading = false;
        },
        [fetchPricingPlans.pending]: (state, action) => {
            const { arg } = action.meta;
            if (arg && arg.withFeatures) {
                state.fetchPricingFeaturesLoading = true;
            }
        },
        [fetchPricingPlans.fulfilled]: (state, action) => {
            const { arg } = action.meta;
            const { status, plans } = action.payload;
            if (status === 'success') {
                state.pricingPlans = {
                    ...state.pricingPlans,
                    ...plans
                };
            }
            if (arg && arg.withFeatures) {
                state.fetchPricingFeaturesLoading = false;
            }
        },
        [fetchPricingPlans.rejected]: (state, action) => {
            const { arg } = action.meta;
            if (arg && arg.withFeatures) {
                state.fetchPricingFeaturesLoading = false;
            }
        },
        [fetchSubscription.fulfilled]: (state, action) => {
            const { status, latestInvoiceClientSecret, latestInvoiceAmount } = action.payload;
            if (status === 'success') {
                state.stripeSubscription = {
                    latestInvoiceClientSecret,
                    latestInvoiceAmount
                };
            }
        }
    }
});

export const { resetPaymentStatusAction, resetStripeSubscription, updatePaymentStore } = paymentSlice.actions;

export default paymentSlice.reducer;
