import { INTERNAL_SERVER_ERR_MSG } from './constant';

// Implementation Reference:
// https://kentcdodds.com/blog/replace-axios-with-a-simple-custom-fetch-wrapper
export default function fetchClient(endpoint, { body, method, ...customConfig } = {}) {
    const headers = { 'Content-Type': 'application/json' };

    const fetchConfig = {
        method: method || (body ? 'POST' : 'GET'),
        // if there is a method specified in customConfig, it will override
        // the method above.
        ...customConfig,
        headers: {
            ...headers,
            ...customConfig.headers,
        }
    };

    if (body) {
        fetchConfig.body = JSON.stringify(body);
    }

    // Note:
    // The Promise returned from fetch() won’t reject on HTTP error status even if the response is an HTTP 404 or 500.
    // Instead, it will resolve normally (with ok status set to false), and
    // it will only reject on network failure or if anything prevented the request from completing.
    try {
        return window
            .fetch(`${endpoint}`, fetchConfig)
            .then(async response => {
                let data;

                if (response.ok) {
                    data = await response.json();
                    data.apiStatusCode = response.status;
                    return data;
                }
                data = await response.json();
                data.apiStatusCode = response.status;

                // if no error message, default message is INTERNAL_SERVER_ERR_MSG
                if (!data.error || !data.error.message) {
                    data.error = {
                        message: INTERNAL_SERVER_ERR_MSG
                    };
                }

                // return data;
                return Promise.reject(data);
            });
    } catch (error) {
        // Could be rejected on network failure or if anything prevented the request from completing.
        // Note that apiStatusCode is not applicable in this case.
        // Instead, isFetchError is set to true.
        const data = {
            status: 'fail',
            isFetchError: true,
            error: {
                message: `There has been a problem with your browser fetch operation: ${error}`
            }
        };

        return Promise.reject(data);
    }
}
