import { makeStyles } from '@material-ui/styles';
import { grey } from '@material-ui/core/colors';

export default makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',

        '& .noPageFoundWrapper': {
            position: 'absolute',
            top: 0,
            width: '100%',
            height: '100%',
            backgroundColor: grey[100],

            '& .noPageFoundContainer': {
                position: 'relative',
                top: '50%',
                left: '50%',
                width: '600px',
                transform: 'translate(-50%, -50%)',

                '& .brokenLinkImageContainer': {
                    width: '150px',
                    height: '150px',
                    margin: theme.spacing(0, 'auto', 2)
                },

                '& .title': {
                    fontWeight: 500,
                    color: grey[700],
                },

                '& .description': {
                    fontSize: 18,
                    color: grey[700],
                },

                '& .dashboardButton': {
                    position: 'relative',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    marginTop: theme.spacing(3),
                },
            },
        },
    },
}));
