import fetchClient from '../utils/fetchClient';

export async function createCourseLectureAPI(schoolId, courseId, chapterId, name) {
    const url = `/api/schools/${schoolId}/courses/${courseId}/lectures`;
    let data = {};

    await fetchClient(url, {
        body: {
            chapterId,
            name
        }
    }).then(
        // success handler
        resData => {
            data = resData || {};
        },
        // error handler
        errData => {
            data = errData || {};
        }
    );

    return data;
}

// update single lecture
export async function updateCourseLectureAPI(schoolId, courseId, lectureId, courseLecture) {
    const url = `/api/schools/${schoolId}/courses/${courseId}/lectures/${lectureId}`;
    let data = {};

    await fetchClient(url, {
        method: 'PUT',
        body: {
            courseLecture
        }
    }).then(
        // success handler
        resData => {
            data = resData || {};
        },
        // error handler
        errData => {
            data = errData || {};
        }
    );

    return data;
}

// update batch lectures
export async function updateCourseLecturesAPI(schoolId, courseId, courseLectures) {
    const url = `/api/schools/${schoolId}/courses/${courseId}/lectures`;
    let data = {};

    await fetchClient(url, {
        method: 'PUT',
        body: {
            courseLectures
        }
    }).then(
        // success handler
        resData => {
            data = resData || {};
        },
        // error handler
        errData => {
            data = errData || {};
        }
    );

    return data;
}

// delete single lecture
export async function deleteCourseLectureAPI(schoolId, courseId, lectureId) {
    const url = `/api/schools/${schoolId}/courses/${courseId}/lectures/${lectureId}`;
    let data = {};

    await fetchClient(url, {
        method: 'DELETE'
    }).then(
        // success handler
        resData => {
            data = resData || {};
        },
        // error handler
        errData => {
            data = errData || {};
        }
    );

    return data;
}
