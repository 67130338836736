import fetchClient from '../utils/fetchClient';

export async function fetch() {
    let data = {};

    await fetchClient('/api/account/user')
        .then(
            // success handler
            resData => {
                data = resData || {};
            },
            // error handler
            errData => {
                data = errData || {};
            }
        );

    return data;
}

export async function updateUserAPI(updateInfo){
    let data = {};

    await fetchClient('/api/account/update-profile', {
        method: 'PUT',
        body: updateInfo
    })
        .then(
            // success handler
            resData => {
                data = resData || {};
            },
            // error handler
            errData => {
                data = errData || {};
            }
        );

	return data;
}

export async function updateUserPasswordAPI(updatePasswordInfo){
	let data = {};

    await fetchClient('/api/account/change-password', {
        method: 'PUT',
        body: updatePasswordInfo
    })
        .then(
            // success handler
            resData => {
                data = resData || {};
            },
            // error handler
            errData => {
                data = errData || {};
            }
        );

	return data;
}

export async function updateDefaultWebsiteAPI(updateDefaultWebsiteInfo){
    let data = {};

    await fetchClient('/api/account/default-website', {
        method: 'PUT',
        body: updateDefaultWebsiteInfo
    })
        .then(
            // success handler
            resData => {
                data = resData || {};
            },
            // error handler
            errData => {
                data = errData || {};
            }
        );

    return data;
}


export async function logOutAPI() {
    let data = {};

    await fetchClient('/api/users/logout', {
        method: 'POST'
    })
        .then(
            // success handler
            resData => {
                data = resData || {};
            },
            // error handler
            errData => {
                data = errData || {};
            }
        );

    return data;
}
