import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';

import { SnackbarUtil } from '../../components/SnackbarUtilsConfig';
import {
    fetchCoursesAPI,
    fetchIndividualCourseAPI,
    updateCourseAPI,
    updateCourseMediaAPI,
    fetchTotalPublishedLecturesAPI,
    fetchCourseEnrollmentAPI,
    updateAuthorMediaAPI,
    deleteCourseAPI,
    fetchMicrolearningAnalyticsAPI,
    fetchWebAnalyticsAPI,
    fetchProductRelatedOfferListAPI
}  from '../../api/courseAPI';
import { API_ERROR_RESPONSE, INTERNAL_SERVER_ERR_MSG } from '../../utils/constant';
import { routeToLoginPageWithCurrentPath } from '../../utils/helper';

const initialState = {
    course: {},
    courses: null,
    loading: false,
    simpleCourses: null,
    simpleCoursesPending: null,
    coursesWithLearner: [],
    coursesWithLearnerPending: false,
    updateCourseLoading: false,
    updateCourseError: false,
    updateCourseDetailsPending: false,
    updateCourseDetailsError: false,
    updateCourseSubdomainPending: false,
    updateCourseSubdomainError: false,
    updateAuthorIntroductionPending: false,
    updateAuthorIntroductionError: false,
    fetchIndividualCoursePending: false,
    fetchIndividualCourseError: '',

    // get total published lectures relating to the course
    totalPublishedLectures: 0,
    fetchTotalPublishedLecturesPending: false,

    courseEnrollment: {},
    fetchCourseEnrollmentPending: false,

    // course media file
    updateMediaFilePending: false,
    updateMediaFileError: '',

    // author media file
    updateAuthorMediaFilePending: false,
    updateAuthorMediaFileError: '',

    // delete course
    deleteCoursePending: false,

    // microlearning course analytics
    microLearningStudentUserAnalytics: [],
    webStudentUserAnalytics: [],
    productName: '',
    initSchoolAnalyticsCompleted: false,
    schooAnalyticsPending: false,
    schoolAnalyticsTotal: 0,
    schoolAnalyticsListPageNum: 0,

    productOffers: [],
    fetchProductOffersDone: false
};

export const updateCourseStore = createAction("course/updateStore");

export const fetchCourses = createAsyncThunk(
    'courses/fetch',
    async ({ schoolId }) => {
        const data = await fetchCoursesAPI(schoolId);

        return data;
    }
);

export const fetchSimpleCourses = createAsyncThunk(
    'courses/fetch-simple',
    async ({ schoolId }) => {
        const data = await fetchCoursesAPI(schoolId, true);

        if (data.status === 'fail' && data.error) {
            throw new Error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const fetchCoursesWithLearner = createAsyncThunk(
    'courses/fetch-withLearner',
    async ({ schoolId }) => {
        const data = await fetchCoursesAPI(schoolId, false, true);

        if (data.status === 'fail' && data.error) {
            throw new Error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const fetchTotalPublishedLectures = createAsyncThunk(
    'course/publishedLectures',
    async ({ schoolId, courseId }) => {

        const data = await fetchTotalPublishedLecturesAPI(schoolId, courseId);
        if (data.status === 'fail' && data.error) {
            throw new Error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const fetchIndividualCourse = createAsyncThunk(
    'course/individual/fetch',
    async ({ schoolId, courseId, withAuthor, withTotalLecture }) => {
        const data = await fetchIndividualCourseAPI(schoolId, courseId, withAuthor, withTotalLecture);
        if (data.status === 'fail' && data.error) {
            const { apiStatusCode, error: { message } } = data;
            if (apiStatusCode === 401 && message.includes('Unauthorized access')) {
                routeToLoginPageWithCurrentPath();
                return;
            }
            throw new Error(message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const updateCourse = createAsyncThunk(
    'course/update',
    async (requestPayload) => {
        const data = await updateCourseAPI(requestPayload);
        if (data.status === 'fail' && data.error) {
            throw new Error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const updateCourseDetails = createAsyncThunk(
    'course/updateCourseDetails',
    async (requestPayload) => {
        const data = await updateCourseAPI(requestPayload);

        // If data status equals fail, no need to dispatch
        // global message here. It will be checked and displayed as
        // a form error message.
        return data;
    }
);

export const updateCourseSubdomain = createAsyncThunk(
    'course/updateCourseSubdomain',
    async (requestPayload) => {
        const data = await updateCourseAPI(requestPayload);
        return data;
    }
);

export const updateAuthorIntroduction = createAsyncThunk(
    'course/updateAuthorIntroduction',
    async (requestPayload) => {
        const data = await updateCourseAPI(requestPayload);
        return data;
    }
);

export const updateCourseMedia = createAsyncThunk(
    'course/updateMedia',
    async (requestPayload) => {
        const data = await updateCourseMediaAPI(requestPayload);
        return data;
    }
);

export const updateAuthorMedia = createAsyncThunk(
    'course/updateAuthorMedia',
    async (requestPayload) => {
        const data = await updateAuthorMediaAPI(requestPayload);
        return data;
    }
);

export const fetchCourseEnrollment = createAsyncThunk(
    'course/enrollment',
    async ({ schoolId, courseId }) => {
        const data = await fetchCourseEnrollmentAPI(schoolId, courseId);
        return data;
    }
);

export const fetchProductRelatedOfferList = createAsyncThunk(
    'course/offer-list',
    async ({ schoolId, courseId }) => {
        const data = await fetchProductRelatedOfferListAPI(schoolId, courseId);
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || INTERNAL_SERVER_ERR_MSG);
        }
        return data;
    }
);

export const deleteCourse = createAsyncThunk(
    'course/delete',
    async ({ schoolId, courseId }) => {
        const response = await deleteCourseAPI(schoolId, courseId);
        if (response.status === 'fail' && response.error) {
            throw Error(response.error.message || API_ERROR_RESPONSE)
        }
        return response;
    } 
);

export const fetchMicrolearningAnalytics = createAsyncThunk(
    'course/fetchMicroLearningAnalytics',
    async ({ schoolId, courseId, page, rowSize }, { dispatch }) => {
        const response = await fetchMicrolearningAnalyticsAPI(schoolId, courseId, page, rowSize);
        if (response.status === 'fail' && response.error) {
            throw new Error(response.error.message || API_ERROR_RESPONSE);
        }
        return response;
    }
);

export const fetchWebAnalytics = createAsyncThunk(
    'course/fetchWebAnalytics',
    async ({ schoolId, courseId, page, rowSize }, { dispatch }) => {
        const response = await fetchWebAnalyticsAPI(schoolId, courseId, page, rowSize);
        if (response.status === 'fail' && response.error) {
            throw new Error(response.error.message || API_ERROR_RESPONSE);
        }
        return response;
    }
);

function resetAllErrorMessage(state) {
    state.updateCourseDetailsError = '';
    state.updateCoursePriceError = '';
    state.updateCourseSubdomainError = '';
    state.fetchIndividualCourseError = '';
}

const courseSlice = createSlice({
    name: 'course',
    initialState,
    reducers: {
        addCourse: (state, action) => {
            const { courses } = action.payload;
            state.courses = courses;
        },
        resetProductOffers: (state) => {
            state.productOffers = [];
            state.fetchProductOffersDone = false;
        }
    },
    extraReducers: {
        [updateCourseStore]: (state, action) => {
            const { payload } = action;
            Object.keys(payload).forEach(key => {
                state[key] = payload[key];
            }); 
        },
        // Add reducers for additional action types here, and handle loading state as needed
        [fetchCourses.fulfilled]: (state, action) => {
            const { courses } = action.payload;
            state.courses = courses;
            state.loading = false;
        },
        [fetchCourses.pending]: state => {
            state.loading = true;
        },
        [fetchCourses.rejected]: state => {
            state.loading = false;
        },
        [fetchSimpleCourses.fulfilled]: (state, action) => {
            const { courses } = action.payload;
            state.simpleCourses = courses;
            state.simpleCoursesPending = false;
        },
        [fetchSimpleCourses.pending]: state => {
            state.simpleCoursesPending = true;
        },
        [fetchSimpleCourses.rejected]: state => {
            state.simpleCoursesPending = false;
        },
        [fetchCoursesWithLearner.pending]: state => {
            state.coursesWithLearnerPending = true;
        },
        [fetchCoursesWithLearner.fulfilled]: (state, action) => {
            const { courses } = action.payload;
            state.coursesWithLearner = courses;
            state.coursesWithLearnerPending = false;
        },
        [fetchCoursesWithLearner.rejected]: state => {
            state.coursesWithLearnerPending = false;
        },
        [fetchIndividualCourse.pending]: state => {
            state.fetchIndividualCoursePending = true;
            resetAllErrorMessage(state);
        },
        [fetchIndividualCourse.fulfilled]: (state, action) => {
            const { course } = action.payload;
            if (course) {
                const currCourse = state.course[course.id] || {};
    
                state.course[course.id] = {
                    ...currCourse,
                    ...course
                };
            }

            state.fetchIndividualCoursePending = false;
        },
        [fetchIndividualCourse.rejected]: state => {
            state.fetchIndividualCoursePending = false;
        },
        [fetchTotalPublishedLectures.pending]: state => {
            state.fetchTotalPublishedLecturesPending = true
        },
        [fetchTotalPublishedLectures.fulfilled]: (state, action) => {
            const { 
                meta: {
                    arg: { courseId }
                },
                payload: {
                    status, totalPublishedLectures
                }
            } = action;
            if (status === 'success') {
                const currCourse = state.course[courseId] || {};
                state.course[courseId] = {
                    ...currCourse,
                    totalPublishedLectures
                };
            }
            state.fetchTotalPublishedLecturesPending = false;
        },
        [fetchTotalPublishedLectures.rejected]: state => {
            state.fetchTotalPublishedLecturesPending = false;
        },

        [fetchCourseEnrollment.pending]: state => {
            state.fetchCourseEnrollmentPending = true;
        },

        [fetchCourseEnrollment.fulfilled]: (state, action) => {
            const { enrollmentCount, courseId } = action.payload;
            state.courseEnrollment[courseId] = enrollmentCount;
            state.fetchCourseEnrollmentPending = false;
        },

        [fetchProductRelatedOfferList.fulfilled]: (state, action) => {
            const { status, offers } = action.payload;
            if (status === 'success') {
                state.productOffers = offers;
            }
            state.fetchProductOffersDone = true;
        },

        // update course flow
        [updateCourse.pending]: state => {
            state.updateCourseLoading = true;
            state.updateCourseError = '';
        },
        [updateCourse.fulfilled]: (state, action) => {
            const { course, status, error } = action.payload;
            if (status === 'success') {
                const currCourse = state.course[course.id] || {};
                state.course[course.id] = {
                    ...currCourse,
                    ...course
                };
            } else if (error) {
                state.updateCourseError = error.message;
            }
            state.updateCourseLoading = false;
        },
        [updateCourse.rejected]: state => {
            state.updateCourseLoading = false;
        },
        // update course details flow
        [updateCourseDetails.pending]: state => {
            state.updateCourseDetailsPending = true;
            state.updateCourseDetailsError = '';
        },
        [updateCourseDetails.fulfilled]: (state, action) => {
            const { course, status, error } = action.payload;
            if (status === 'success') {
                const currCourse = state.course[course.id] || {};
                state.course[course.id] = {
                    ...currCourse,
                    ...course
                };
            } else if (error) {
                state.updateCourseDetailsError = error.message
            }
            state.updateCourseDetailsPending = false;
        },
        [updateCourseDetails.rejected]: state => {
            state.updateCourseDetailsPending = false;
        },
        // update course subdomain
        [updateCourseSubdomain.pending]: state => {
            state.updateCourseSubdomainPending = true;
            state.updateCourseSubdomainError = '';
        },
        [updateCourseSubdomain.fulfilled]: (state, action) => {
            const { course, status, error } = action.payload;
            if (status === 'success') {
                const currCourse = state.course[course.id] || {};
                state.course[course.id] = {
                    ...currCourse,
                    ...course
                };
            } else if (error) {
                state.updateCourseSubdomainError = error.message
            }
            state.updateCourseSubdomainPending = false;
        },
        [updateCourseSubdomain.rejected]: state => {
            state.updateCourseSubdomainPending = false;
        },
        // update author introduction
        [updateAuthorIntroduction.pending]: state => {
            state.updateAuthorIntroductionPending = true;
            state.updateAuthorIntroductionError = '';
        },
        [updateAuthorIntroduction.fulfilled]: (state, action) => {
            const { course, status, error } = action.payload;
            if (status === 'success') {
                const currCourse = state.course[course.id] || {};
                state.course[course.id] = {
                    ...currCourse,
                    ...course
                };
            } else if (error) {
                state.updateAuthorIntroductionError = error.message
            }
            state.updateAuthorIntroductionPending = false;
        },
        [updateAuthorIntroduction.rejected]: state => {
            state.updateAuthorIntroductionPending = false;
        },
        // update course media
        [updateCourseMedia.pending]: state => {
            state.updateMediaFilePending = true;
            state.updateMediaFileError = '';
        },
        [updateCourseMedia.fulfilled]: (state, action) => {
            const {
                meta: {
                    arg: {
                        courseId,
                        payload: {
                            media_file_url
                        }
                    }
                },
                payload: { status, error }
            } = action;
            if (status === 'success') {
                state.course[courseId].media_file_url = media_file_url;
            } else if (error) {
                state.updateMediaFileError = error.message;
            }

            state.updateMediaFilePending = false;
        },
        [updateCourseMedia.rejected]: state => {
            state.updateMediaFilePending = false;
        },

        // update author media
        [updateAuthorMedia.pending]: state => {
            state.updateAuthorMediaFilePending = true;
            state.updateAuthorMediaFileError = '';
        },
        [updateAuthorMedia.fulfilled]: (state, action) => {
            const {
                meta: {
                    arg: {
                        courseId,
                        payload: {
                            author_image_url,
                        }
                    }
                },
                payload: { status, error }
            } = action;
            if (status === 'success') {
                state.course[courseId].author_image_url = author_image_url;
            } else if (error) {
                state.updateAuthorMediaFileError = error.message;
            }

            state.updateAuthorMediaFilePending = false;
        },
        [updateAuthorMedia.rejected]: state => {
            state.updateAuthorMediaFilePending = false;
        },
        [deleteCourse.pending]: state => {
            state.deleteCoursePending = true;
        },
        [deleteCourse.fulfilled]: (state, action) => {
            const {
                payload: { status },
                meta: {
                    arg: { courseId }
                }
            } = action;
            state.deleteCoursePending = false;
            if (status === 'success') {
                // remove the school from store on successful delete
                state.course[courseId] = undefined;
            }
        },
        [deleteCourse.rejected]: (state) => {
            state.deleteCoursePending = false;
        },
        [fetchMicrolearningAnalytics.pending]: state => {
            state.schooAnalyticsPending = true;
        },
        [fetchMicrolearningAnalytics.rejected]: state => {
            state.schooAnalyticsPending = false;
            state.initSchoolAnalyticsCompleted = true;
        },
        [fetchMicrolearningAnalytics.fulfilled]: (state, action) => {
            const { page: schoolAnalyticsListPageNum } = action.meta.arg;
            const { rowResults: microLearningStudentUserAnalytics, productName, totalStudentUser, status } = action.payload;

            if (status === 'success') {
                state.productName = productName;
                state.microLearningStudentUserAnalytics = microLearningStudentUserAnalytics;
                state.schoolAnalyticsTotal = totalStudentUser;
                state.schoolAnalyticsListPageNum = schoolAnalyticsListPageNum;
            }

            state.schooAnalyticsPending = false;
            state.initSchoolAnalyticsCompleted = true;
        },
        [fetchWebAnalytics.pending]: state => {
            state.schooAnalyticsPending = true;
        },
        [fetchWebAnalytics.rejected]: state => {
            state.schooAnalyticsPending = false;
            state.initSchoolAnalyticsCompleted = true;
        },
        [fetchWebAnalytics.fulfilled]: (state, action) => {
            const { page: schoolAnalyticsListPageNum } = action.meta.arg;
            const { rowResults: webStudentUserAnalytics, productName, totalStudentUser, status } = action.payload;

            if (status === 'success') {
                state.productName = productName;
                state.webStudentUserAnalytics = webStudentUserAnalytics;
                state.schoolAnalyticsTotal = totalStudentUser;
                state.schoolAnalyticsListPageNum = schoolAnalyticsListPageNum;
            }

            state.schooAnalyticsPending = false;
            state.initSchoolAnalyticsCompleted = true;
        }
    }
});

export const { addCourse, resetProductOffers } = courseSlice.actions;

export default courseSlice.reducer;
