import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { SnackbarUtil } from '../../components/SnackbarUtilsConfig';
import {
    fetchCourseChaptersAPI,
    createCourseChapterAPI,
    updateCourseChapterAPI,
    deleteCourseChapterAPI,
}  from '../../api/courseChapterAPI';
import {
    createCourseLectureAPI,
    updateCourseLecturesAPI,
    updateCourseLectureAPI,
    deleteCourseLectureAPI,
} from '../../api/courseLectureAPI';
import { INTERNAL_SERVER_ERR_MSG } from '../../utils/constant';
import { v4 as uuid } from 'uuid';

const initialState = {
    courseId:  null,
    courseChapters: [],
    courseChaptersElemIdMap: {},
    courseChaptersMap: {},
    loading: 'idle',
    previewUrl: '',
};

const getChapterModel = item => {
    return {
        id: item.id,
        elemId: item.elemId,
        name: item.name,
        position: item.position
    }
}

// fetch chapters thunk
export const fetchCourseChapters = createAsyncThunk(
    'courseChapters/fetch',
    async (params) => {
        const data = await fetchCourseChaptersAPI(params.schoolId, params.courseId, params.withPreview);
        return data;
    }
)

// create chapter thunk
export const createCourseChapter = createAsyncThunk(
    'courseChapter/create',
    async (params) => {
        let data = {};
        const {
            schoolId,
            courseId,
            elemId,
            name,
            position
        } = params;

        const createChapterResp = await createCourseChapterAPI({
            schoolId,
            courseId,
            elemId,
            name,
            position
        });

        if (createChapterResp.status === 'fail') {
            SnackbarUtil.error(INTERNAL_SERVER_ERR_MSG);
            throw createChapterResp;
        }

        const fetchCourseChaptersResp = await fetchCourseChaptersAPI(schoolId, courseId);

        if (fetchCourseChaptersResp.status === 'fail') {
            SnackbarUtil.error(INTERNAL_SERVER_ERR_MSG);
            throw createChapterResp;
        }

        data = fetchCourseChaptersResp;

        return data;
    }
)

// update chapter thunk
// Note: this update chapter only, not lectures
export const updateCourseChapter = createAsyncThunk(
    'courseChapter/update',
    async (params) => {
        const {
            schoolId,
            courseId,
            courseChapter
        } = params;

        let updateObj = getChapterModel(courseChapter);

        try {
            const response = await updateCourseChapterAPI(
                schoolId,
                courseId,
                updateObj
            );

            if (response.error) {
                SnackbarUtil.error(response.error.message);
            }
        } catch (err) {
            SnackbarUtil.error('Request error. Please try again later.');
        }
    }
)

// create lecture thunk
export const createCourseLecture = createAsyncThunk(
    'courseLecture/create',
    async (params) => {
        const schoolId = params.schoolId;
        const courseId = params.courseId;
        const updatedResponse = await createCourseLectureAPI(
            schoolId,
            courseId,
            params.chapterId,
            params.name
        );

        if (!updatedResponse.error) {
            const data = await fetchCourseChaptersAPI(schoolId, courseId);

            return data;
        }
    }
)

// update lecture thunk
export const updateCourseLectures = createAsyncThunk(
    'courseLectures/update',
    async (params, thunkAPI) => {
        const schoolId = params.schoolId;
        const courseId = params.courseId;

        const updatedResponse = await updateCourseLecturesAPI(
            schoolId,
            courseId,
            params.courseLectures
        );

        if (updatedResponse.error) {
            // TODO: has error, do something
        }
    }
)

// update single lecture thunk
export const updateCourseLecture = createAsyncThunk(
    'courseLecture/update',
    async (params) => {
        const {
            schoolId,
            courseId,
            lectureId,
            courseLecture,
        } = params;

        const updatedResponse = await updateCourseLectureAPI(
            schoolId,
            courseId,
            lectureId,
            courseLecture
        );

        if (updatedResponse.error) {
            // TODO: has error, do something
        }
    }
)

// delete single lecture thunk
export const deleteCourseLecture = createAsyncThunk(
    'courseLecture/delete',
    async (params, thunkAPI) => {
        const {
            schoolId,
            courseId,
            lectureId,
        } = params;

        const response = await deleteCourseLectureAPI(
            schoolId,
            courseId,
            lectureId,
        );

        if (response.error) {
            // TODO: has error, do something
        }
    }
)

// delete course chapter thunk
export const deleteCourseChapter = createAsyncThunk(
    'courseChapter/delete',
    async (params, { dispatch }) => {
        const {
            schoolId,
            courseId,
            chapterId,
        } = params;

        const response = await deleteCourseChapterAPI(
            schoolId,
            courseId,
            chapterId,
        );

        if (response.status === 'fail') {
            const msg = response.error && response.error.message;
            SnackbarUtil.error(msg || INTERNAL_SERVER_ERR_MSG);
        }
    }
)

const updateState = function(state, action) {
    let { courseId, courseChapters, previewUrl } = action.payload;

    courseChapters = courseChapters || [];
    state.courseId = courseId;

    let courseChaptersElemIdMap = {};
    let courseChaptersMap = {};

    courseChapters.sort((a,b) => {
        return a.position - b.position;
    })

    courseChapters.forEach(chapter => {
        if (!chapter.hasOwnProperty('elemId')) {
            chapter.elemId = uuid();
        }

        chapter.lectures = chapter.lectures || [];
        chapter.lectures.forEach((lecture) => {
            if (!lecture.hasOwnProperty('elemId')) {
                lecture.elemId = uuid();
            }
        })

        chapter.lectures.sort((a,b) => {
            return a.position - b.position;
        })

        courseChaptersMap[chapter.id] = chapter;
        courseChaptersElemIdMap[chapter.elemId] = chapter;
    });

    state.courseChapters = courseChapters;
    state.courseChaptersMap = courseChaptersMap;
    state.courseChaptersElemIdMap = courseChaptersElemIdMap;
    state.previewUrl = previewUrl;
}

const courseChaptersSlice = createSlice({
    name: 'courseChapters',
    initialState,
    reducers: {
        addCourseChapters: (state, action) => {
            // not used anywhere, has bug
            const { courseId, courseChapters } = action.payload;
            state.courseChapters = courseChapters;
            state.courseId = courseId;

            let courseChaptersElemIdMap = {};
            courseChapters.forEach(item => {
                courseChaptersElemIdMap[item.elemId] = item;
            })
            state.courseChaptersElemIdMap = courseChaptersElemIdMap;
        },
        createCourseChapterAction: (state, action) => {
            const {
                elemId,
                courseId,
                name,
                position,
            } = action.payload;

            if (courseId !== state.courseId) {
                console.error("course id mismatches the stored course id.");
                return;
            }

            const chapterItem = getChapterModel({
                id: null,
                elemId,
                name,
                position,
            });

            let courseChapters = state.courseChapters;
            const courseChaptersElemIdMap = state.courseChaptersElemIdMap;

            courseChapters.splice(parseInt(chapterItem.position), 0, chapterItem);
            courseChaptersElemIdMap[chapterItem.elemId] = chapterItem;

            state.courseChapters = courseChapters;
            state.courseChaptersElemIdMap = courseChaptersElemIdMap;
        },
        deleteCourseChapterAction: (state, action) => {
            const {
                chapterId,
                courseId
            } = action.payload;

            if (courseId !== state.courseId) {
                console.error("course id mismatches the stored course id.");
                return;
            }

            const chapter = state.courseChaptersMap[chapterId];
            const courseChaptersElemIdMap = {};
            const courseChaptersMap = {};
            
            let courseChapters = state.courseChapters;
            courseChapters = courseChapters.filter(item => item.id !== chapter.id);

            courseChapters.forEach((chapter, index) => {
                chapter.position = index;

                courseChaptersElemIdMap[chapter.elemId] = chapter;
                courseChaptersMap[chapter.id] = chapter;
            })

            state.courseChapters = courseChapters;
            state.courseChaptersElemIdMap = courseChaptersElemIdMap;
            state.courseChaptersMap = courseChaptersMap;
        },
        updateCourseChaptersAction: (state, action) => {
            let { courseId, courseChapters } = action.payload;

            courseChapters = courseChapters || [];
            state.courseId = courseId;

            let courseChaptersElemIdMap = {};
            let courseChaptersMap = {};

            courseChapters.forEach(chapter => {
                if (!chapter.hasOwnProperty('elemId')) {
                    chapter.elemId = uuid();
                }

                courseChaptersMap[chapter.id] = chapter;
                courseChaptersElemIdMap[chapter.elemId] = chapter;
            })

            state.courseChapters = courseChapters;
            state.courseChaptersMap = courseChaptersMap;
            state.courseChaptersElemIdMap = courseChaptersElemIdMap;
        },
        updateCourseLecturesAction: (state, action) => {
            // courseLectures is an object
            const { courseLectures } = action.payload;
            const courseChapters = state.courseChapters;

            for (const chapterId in courseLectures) {
                const chapter = state.courseChaptersMap[chapterId];
                chapter.lectures = courseLectures[chapterId];

                let index = -1;
                for (let i = 0, l = courseChapters.length; i < l; i++) {
                    if (courseChapters[i].id === chapter.id) {
                        index = i;
                        break;
                    }
                }

                courseChapters.splice(index, 1, chapter);
                state.courseChaptersElemIdMap[chapter.elemId] = chapter;
                state.courseChaptersMap[chapter.id] = chapter;
            }

            state.courseChapters = courseChapters;
        },
    },
    extraReducers: {
        // Add reducers for additional action types here, and handle loading state as needed
        [fetchCourseChapters.fulfilled]: updateState,
        [createCourseChapter.fulfilled]: updateState,
        [createCourseLecture.fulfilled]: updateState,
    }
});

export const {
    createCourseChapterAction,
    addCourseChapters,
    updateCourseChaptersAction,
    updateCourseLecturesAction,
    deleteCourseChapterAction
} = courseChaptersSlice.actions;

export default courseChaptersSlice.reducer;
