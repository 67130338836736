import { makeStyles } from '@material-ui/core/styles';
import { grey, green } from '@material-ui/core/colors';

export default makeStyles((theme) => ({
    root: {
        position: 'fixed',
        width: '100%',
        zIndex: 1000,
        borderBottom: '1px solid #dadce0',

        '& .appBarContainer': {
            position: 'relative',
            backgroundColor: 'white',
            color: '#5f6368',
            maxHeight: '64px',

            '& .toolbarContainer': {
                padding: '8px',

                '& .leftSection': {
                    minWidth: '238px',
                    paddingLeft: '12px',
                    marginRight: '10px',

                    '& .brandContainer': {
                        display: 'inline-block',
                        cursor: 'pointer',

                        '& .brandIcon': {
                            padding: '3px 0 3px 0',

                            '&:hover': {
                                backgroundColor: 'transparent',
                            }
                        },

                        '& .brandTitle': {
                            position: 'relative',
                            display: 'inline-block',
                            color: 'black',
                            verticalAlign: 'middle',
                            fontFamily: 'Product Sans",Arial,sans-serif',
                            fontSize: '22px',
                            textRendering: 'optimizeLegibility',
                            '-webkit-font-smoothing': 'antialiased',
                            'font-family': 'Be Vietnam Pro, sans-serif',
                            letterSpacing: '-0.8px',
                            paddingRight: theme.spacing(1),

                            '&:hover': {
                                cursor: 'pointer',
                            }
                        },
                    }
                }
            }
        },
    },
    websiteDropdownMenu: {
        '& .hide': {
            display: 'none',
        },

        '&  .MuiMenu-list': {
            width: '274px',

            '& .MuiMenuItem-root': {
                padding: theme.spacing(1.5, 2),
            },

            '& .websiteLogoBox': {
                '& .websiteLogo': {
                    marginRight: theme.spacing(1.25)
                }
            },

            '& .websiteNameBox': {
                width: '170px',
                paddingRight: '8px',

                '& p': {
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    '-webkit-box-orient': 'vertical',
                    '-webkit-line-clamp': 2, /* number of lines to show */
                    lineHeight: '1.5rem',
                    maxHeight: '3rem',
                    textWrap: 'balance',
                    overflowWrap: 'break-word',
                },
            },

            '& .checkIconBox': {
                width: '30px',
                color: green[800],

                '& .MuiSvgIcon-root': {
                    verticalAlign: 'middle'
                }
            },

            '& .createWebsiteIconBox': {
                marginRight: theme.spacing(1),
                color: grey[600],

                '& .MuiSvgIcon-root': {
                    width: '42px',
                    height: '42px',
                    verticalAlign: 'middle',
                }
            },
        },

        '& .websiteDropdownSecondPage': {
            '& .titleBox': {
                borderBottom: `1px solid ${grey[300]}`,

                '& .MuiButtonBase-root': {
                    margin: theme.spacing(1),
                    padding: theme.spacing(0.75),
                    color: grey[600],
                },

                '& .MuiTypography-root': {
                    verticalAlign: 'middle',
                    marginLeft: theme.spacing(6.5),
                },
            },
        },
    },
    arrowBackButton: {
        padding: theme.spacing(1.25),

        '& .arrowBackIcon': {
            width: '26px',
            height: '26px',
        }
    },
    sectionDesktop: {
        display: 'flex',
        paddingRight: '20px',
    },
    newSchoolContainer: {
        padding: '13px 0 11px',
        paddingLeft: '15px',

        '& .new-school-button': {
            width: '155px',
            borderRadius: '25px',
            paddingLeft: '0px',
            height: '48px',
            backgroundColor: 'white',
            color: '#3c4043',
            fontSize: '14px',
            letterSpacing: '.15px',

            '&.MuiButton-contained': {
                boxShadow: '0 1px 2px 0 rgba(60,64,67,0.302), 0 1px 3px 1px rgba(60,64,67,0.149)',

                '&:hover': {
                    boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
                },

                '& .MuiButton-label': {
                    paddingLeft: '5px',

                    '& svg': {
                        width: '40px',
                        height: '40px',
                        marginRight: '6px',
                        color: '#6f6f70',
                    },
                },
            },
        },
    },
    grow: {
        flexGrow: 1,
    },
    appBarAvatar: {
        width: '32px',
        height: '32px',
    },
    navBarLink: {
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',
        color: 'inherit',
        '&:hover': {
            textDecoration: 'none'
        },
        '& li': {
            width: '100%'
        }
    },
    avatarInMenu: {
        width: '46px',
        height: '46px',
        marginRight: theme.spacing(1.5),
        color: 'rgba(0, 0, 0, 0.54)'
    },
    websiteInfoInMenu: {
        position: 'absolute',
        top: 0,
        width: '190px',
        display: 'inline-block',

        '& .avatarName': {
            color: grey[800],
            fontWeight: 500,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            fontSize: '1.2rem',
            marginTop: theme.spacing(1.25),
        },
    },
    userInfoInMenu: {
        width: '190px',
        display: 'inline-block',

        '& .avatarName': {
            color: grey[800],
            fontWeight: 600,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        },

        '& .avatarEmail': {
            color: grey[700],
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
        },
    },
    userInfoBox: {
        position: 'relative',
        width: '260px',
        marginLeft: '12px',
        marginTop: '4px',
        marginBottom: '2px',
    },
    menuDivider: {
        margin: theme.spacing(1, 0),
    },
    devicesIcon: {
        position: 'relative',
        bottom: '1px',
    },
    feedbackIcon: {
        position: 'relative',
        top: '1px',
    },
}));
