import fetchClient from '../utils/fetchClient';

const URL = (schoolId) => {
    return `/api/schools/${schoolId}/coupons`
};

export async function getCouponsAPI({ schoolId, page, rowSize, signal }) {
    let data = null;

    let url = `${URL(schoolId)}?page=${page}`;
    if (rowSize) {
        url = `${url}&rowSize=${rowSize}`;
    }

    await fetchClient(url, { signal })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}

export async function getIndividualCouponAPI({ schoolId, couponId, withOffers, signal }) {
    let data = null;

    let url = `${URL(schoolId)}/${couponId}`;
    if (withOffers) {
        url = `${url}?withOffers=${withOffers}`;
    }

    await fetchClient(url, { signal })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}

export async function createCouponAPI({ schoolId, body}) {
    let data = null;

    await fetchClient(URL(schoolId), {
        body
    })
    .then(
        // success handler
        resData => {
            data = resData;
        },
        // error handler
        errData => {
            data = errData;
        }
    );

    return data;
}

export async function deleteCouponAPI(schoolId, couponId) {
    const url = `${URL(schoolId)}/${couponId}`;;
    let data = {};
    await fetchClient(
        url,
        {
            method: 'DELETE'
        }
    ).then(
        resData => {
            data = resData;
        },
        errData => {
            data = errData;
        }
    );
    return data;
}

export async function linkCouponOfferAPI(schoolId, couponId, offerIds) {
    const url = `${URL(schoolId)}/${couponId}/link-offers`;
    let data = {};
    await fetchClient(
        url,
        {
            body: {
                offerIds
            }
        }
    ).then(
        resData => {
            data = resData;
        },
        errData => {
            data = errData;
        }
    );
    return data;
}

export async function unlinkCouponOfferAPI(schoolId, couponId, offerIds) {
    const url = `${URL(schoolId)}/${couponId}/unlink-offers`;
    let data = {};
    await fetchClient(
        url,
        {
            method: 'DELETE',
            body: {
                offerIds
            }
        }
    ).then(
        resData => {
            data = resData;
        },
        errData => {
            data = errData;
        }
    );
    return data;
}

