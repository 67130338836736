import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { SnackbarUtil } from '../../components/SnackbarUtilsConfig';
import {
    createCustomDomainAPI,
    fetchCustomDomainStatusAPI,
    fetchCloudFlareNameserverAPI,
    deleteDomainAPI
} from '../../api/domainAPI';
import { API_ERROR_RESPONSE } from '../../utils/constant';

const initialState = {
    deleteDomainLoading: false,
    customDomainLoading: false,
    customDomain: null,
    customDomainCompleted: false,
    whoisInfo: null,
    nameservers: [],
    airteachCname: ''
};

export const fetchCustomDomainStatus = createAsyncThunk(
    'domain/status',
    async ({ schoolId, signal }) => {
        const data = await fetchCustomDomainStatusAPI(schoolId, signal);
        if (data instanceof DOMException) {
            throw data;
        }
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const fetchNameServers = createAsyncThunk(
    'domain/nameservers',
    async () => {
        const data = await fetchCloudFlareNameserverAPI();
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const createDomain = createAsyncThunk(
    'domain/create',
    async reqBody => {
        const data = await createCustomDomainAPI(reqBody);
        return data;
    }
);

export const deleteDomain = createAsyncThunk(
    'domain/delete',
    async ({ customDomainId, schoolId }, { dispatch }) => {
        const data = await deleteDomainAPI(customDomainId, schoolId);
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

const domainSlice = createSlice({
    name: 'domain',
    initialState,
    reducers: {
        resetCustomDomainAction: state => {
            state.customDomain = null;
            state.customDomainCompleted = false;
        }
    },
    extraReducers: {
        [fetchCustomDomainStatus.pending]: state => {
            state.customDomainLoading = true;
        },
        [fetchCustomDomainStatus.fulfilled]: (state, action) => {
            const { status, customDomain, whoisInfo } = action.payload;
            state.customDomainLoading = false;
            state.customDomainCompleted = true;
            if (status === 'success') {
                state.customDomain = customDomain;
                state.whoisInfo = whoisInfo;
            }
        },
        [fetchNameServers.fulfilled]: (state, action) => {
            const { status, cloudFlareNs1, cloudFlareNs2, airteachCname } = action.payload;
            if (status === 'success') {
                state.nameservers = [cloudFlareNs1, cloudFlareNs2];
                state.airteachCname = airteachCname;
            }
        },
        [createDomain.fulfilled]: (state, action) => {
            const { status, customDomain, whoisInfo } = action.payload;
            if (status === 'success') {
                state.customDomain = customDomain;
                state.whoisInfo = whoisInfo;
            }
        },
        [deleteDomain.pending]: state => {
            state.deleteDomainLoading = true;
        },
        [deleteDomain.fulfilled]: (state, action) => {
            const { status } = action.payload;
            state.deleteDomainLoading = false;
            if (status === 'success') {
                state.customDomain = null;
                state.whoisInfo = null;
            }
        }
    }
});

export const { resetCustomDomainAction } = domainSlice.actions;

export default domainSlice.reducer;
