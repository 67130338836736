import fetchClient from '../utils/fetchClient';

export async function fetchCloudFlareNameserverAPI() {
    let data;
    await fetchClient(`/api/custom-domain/nameservers`)
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );
    return data;
}

export async function fetchCustomDomainStatusAPI(schoolId, signal) {
    let data;
    await fetchClient(`/api/custom-domain/fetch?schoolId=${schoolId}`, { signal })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );
    return data;
}

export async function createCustomDomainAPI(reqBody) {
    let data;
    await fetchClient('/api/custom-domain/create',
        {
            body: reqBody
        })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );
    return data;
}

export async function deleteDomainAPI(customDomainId, schoolId) {
    const url = `/api/custom-domain/${customDomainId}`;
    let data = {};
    await fetchClient(
        url,
        {
            method: 'DELETE',
            body: {
                schoolId
            }
        }
    ).then(
        resData => {
            data = resData;
        },
        errData => {
            data = errData;
        }
    );
    return data;
}
