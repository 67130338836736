import fetchClient from '../utils/fetchClient';

export async function fetchInitDataAPI() {
    let data;
    await fetchClient(`/api/app/init-data`)
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );
    return data;
}
