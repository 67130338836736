import { createSlice } from '@reduxjs/toolkit';

import { deleteCookie } from "../../utils/cookie";

const initialState = {
    authorize: false,
    user: null,
    error: null
};

export const slice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    ON_LOGIN_SUBMITTED: (state, action) => {
        return { ...state, ...action.payload };
    },
    ON_RESET: () => {
        return initialState;
    },
  },
});

export const {
    ON_LOGIN_SUBMITTED,
    ON_RESET
} = slice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
// export const selectCount = state => state.counter.value;

export const logOut = (error) => {
    deleteCookie("authorize");
    return ON_LOGIN_SUBMITTED({ authorize: false, user: null, error: error });
};

export const loginResetAction = () => ON_RESET();

export default slice.reducer;
