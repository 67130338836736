import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { API_ERROR_RESPONSE } from '../../utils/constant';
import { SnackbarUtil } from '../../components/SnackbarUtilsConfig';
import { runCodeAPI, submidCodeAPI } from '../../api/interactiveCodeAPI';

const DEFAULT_INTERACTIVE_CODE_STATE = {
    hasActiveActionPending: false,
    isCodeRunning: false,
    isCodeSubmitting: false,
    runResult: null,
    runError: null,
    submitResult: null
};

const initialState = {
    lectureItems: {}
};

export const runInteractiveCode = createAsyncThunk(
    'interactiveCode/runInteractiveCode',
    async ({ ids, params }) => {
        const data = await runCodeAPI(ids, params);
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }

        return data;
    }
);

export const submitInteractiveCode = createAsyncThunk(
    'interactiveCode/submitInteractiveCode',
    async ({ ids, params }) => {
        const data = await submidCodeAPI(ids, params);
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }

        return data;
    }
);

const interactiveCodeSlice = createSlice({
    name: 'interactiveCode',
    initialState,
    reducers: {
        resetInteractiveCode: state => {
            state.lectureItems = {};
        }
    },
    extraReducers: {
        [runInteractiveCode.pending]: (state, action) => {
            const { lectureItemId } = action.meta.arg.ids;
            if (!state.lectureItems[lectureItemId]) {
                state.lectureItems[lectureItemId] = {
                    ...DEFAULT_INTERACTIVE_CODE_STATE,
                    isCodeRunning: true,
                    hasActiveActionPending: true
                }
            } else {
                state.lectureItems[lectureItemId].isCodeRunning = true;
                state.lectureItems[lectureItemId].hasActiveActionPending = true;
            }
        },
        [runInteractiveCode.fulfilled]: (state, action) => {
            const { lectureItemId } = action.meta.arg.ids;
            const { status, runResult } = action.payload;

            if (!state.lectureItems[lectureItemId]) {
                state.lectureItems[lectureItemId] = {
                    ...DEFAULT_INTERACTIVE_CODE_STATE,
                    isCodeRunning: false
                }
            } else {
                state.lectureItems[lectureItemId].isCodeRunning = false;
                state.lectureItems[lectureItemId].hasActiveActionPending = false;
            }
           
            if (status === 'success') {
                state.lectureItems[lectureItemId].runResult = runResult;
            }
        },
        [submitInteractiveCode.pending]: (state, action) => {
            const { lectureItemId } = action.meta.arg.ids;

            if (!state.lectureItems[lectureItemId]) {
                state.lectureItems[lectureItemId] = {
                    ...DEFAULT_INTERACTIVE_CODE_STATE,
                    isCodeSubmitting: true,
                    hasActiveActionPending: true
                }
            } else {
                state.lectureItems[lectureItemId].isCodeSubmitting = true;
                state.lectureItems[lectureItemId].hasActiveActionPending = true;
            }
        },
        [submitInteractiveCode.fulfilled]: (state, action) => {
            const { lectureItemId } = action.meta.arg.ids;
            const { status, submitResult, runError } = action.payload;
            if (!state.lectureItems[lectureItemId]) {
                state.lectureItems[lectureItemId] = {
                    ...DEFAULT_INTERACTIVE_CODE_STATE,
                    isCodeSubmitting: false,
                    hasActiveActionPending: false
                }
            } else {
                state.lectureItems[lectureItemId].isCodeSubmitting = false;
                state.lectureItems[lectureItemId].hasActiveActionPending = false;
            }

            if (status === 'success') {
                if (submitResult) {
                    state.lectureItems[lectureItemId].submitResult = submitResult;
                    state.lectureItems[lectureItemId].runError = null;
                } else if (runError) {
                    state.lectureItems[lectureItemId].runError = runError;
                    state.lectureItems[lectureItemId].submitResult = null;
                }
            } else {
                state.lectureItems[lectureItemId].submitResult = null;
            }
        }
    }
});

export const { resetInteractiveCode } = interactiveCodeSlice.actions;

export default interactiveCodeSlice.reducer;
