import React, { useState } from 'react';
import { Detector } from 'react-detect-offline';

import { useSnackbar } from 'notistack';

export default function DetectInternetConnection() {
    const [messageKey, setMessageKey] = useState(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    return (
        <Detector
            polling={true}
            onChange={(online)=> {
                if (online) {
                    closeSnackbar(messageKey);
                    const onlineMessageKey = enqueueSnackbar('You are back online.', {
                        variant: 'info',
                        anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                        autoHideDuration: 10000,
                        transitionDuration: {enter: 0, exit: 0}
                    });

                    setMessageKey(onlineMessageKey);
                } else {
                    closeSnackbar(messageKey);
                    const offlineMessageKey = 
                        enqueueSnackbar('You are offline. Changes made now will not be saved.', {
                            persist: true,
                            variant: 'error',
                            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                            transitionDuration: {enter: 0, exit: 0}
                        });

                    setMessageKey(offlineMessageKey);
                }
            }}
            render={() => {
                return null;
            }}
        />
    )
}
