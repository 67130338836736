import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Link,
    useHistory,
} from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    Avatar,
    Box,
    MenuItem,
    ListItemIcon,
    ListItemText,
    Divider,
    Link as MuiLink
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckIcon from '@material-ui/icons/Check';
import DevicesIcon from '@material-ui/icons/Devices';

import {
    SUPPORT_EMAIL,
    DASHBOARD_PATHNAME,
    WEBSITE_URL_PATH
} from '../../utils/constant';
import { getLandingPageUrl, getWebsiteUrl } from '../../utils/helper';
import CourseTitleEdit from '../course/CourseTitleEdit';
import { logoutUser } from '../../features/user/userSlice';
import { fetchSchools } from '../../features/school/schoolSlice';
import { updateDefaultWebsiteAPI } from '../../api/userAPI';
import useStyles from './DashboardAppBar.styles';

const DROPDOWN_MENU_INDEX = {
    general: 0,
    websiteList: 1
};

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #0000001f',
        boxShadow:  'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),

        '& .MuiListItemIcon-root': {
            minWidth: '38px',
        },
    },
}))(MenuItem);

const TITLE_COLOR = '#747474';
const MENU_ID = 'account-menu';

export default function DashboardAppBar({
    isLectureDashboardMode,
    schoolId,
    courseId,
    titleId,
    title,
    handleTitleSave
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);
    const [showWebsiteDropdownMenuIndex, setShowWebsiteDropdownMenuIndex] = useState(DROPDOWN_MENU_INDEX.general);
    const userInfo = useSelector(state => state.user.userInfo);
    const schools = useSelector(state => state.school.schools);
    const selectedWebsite = (schools && schools.find((school) => school.id === userInfo.defaultSiteId)) || null;

    useEffect(() => {
        dispatch(fetchSchools());

        return () => {
            reset();
        };
    }, []); // eslint-disable-line

    const reset = () => {
        setAnchorEl(null);
        setTimeout(
            () => {
                setShowWebsiteDropdownMenuIndex(DROPDOWN_MENU_INDEX.general)
            }, 1000
        );
    };

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    function handleMenuClose() {
        reset();
    };

    function handleOpenMail() {
        handleMenuClose();
        window.location.href = `mailto:${SUPPORT_EMAIL}?subject=Feedback%20for%20Airteach`;
    }

    function handleItemClick(id, event) {
        if (id === 'signout') {
            setAnchorEl(null);
            dispatch(logoutUser()).then(() => {
                window.location.assign(getLandingPageUrl());
            });
        }
    }

    const renderAvatar = (className) => {
        if (selectedWebsite) {
            return (
                <Avatar
                    className={classes[className]}
                    alt={selectedWebsite.name}
                    src={selectedWebsite.logoImageUrl}
                />
            )
        } else if (!userInfo.defaultSiteId) {
            // This means all websites have been deleted. Render the user profile pic instead.
            // Should not get into this case.
            return (
                <Avatar
                    className={classes[className]}
                    alt={userInfo.fullName}
                    src={userInfo.profilePicture}
                />
            )
        }

        return null;
    }

    const showWebsiteListInDropdownMenu = () => {
        setShowWebsiteDropdownMenuIndex(DROPDOWN_MENU_INDEX.websiteList);
    };

    const backToGeneralMenuDropdown = () => {
        setShowWebsiteDropdownMenuIndex(DROPDOWN_MENU_INDEX.general);
    };

    const onWebsiteItemClick = async (websiteId) => {
        await updateDefaultWebsiteAPI({
            websiteId
        });
        handleMenuClose();
        window.location.assign(getWebsiteUrl(websiteId));
    };

    const onCreateWebsiteClick = async () => {
        handleMenuClose();
        history.push(WEBSITE_URL_PATH);
    };

    const renderWebsiteDropdownMenu = () => {
        return (
            <StyledMenu
                className={classes.websiteDropdownMenu}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <Box className={`websiteDropdownFirstPage ${showWebsiteDropdownMenuIndex === DROPDOWN_MENU_INDEX.general ? '' : 'hide'}`}>
                    <Box className={classes.userInfoBox}>
                        <Box display='inline-box'>
                            {renderAvatar('avatarInMenu')}
                        </Box>
                        {
                            selectedWebsite ? (
                                <Box className={classes.websiteInfoInMenu}>
                                    <Typography className='avatarName' variant='body1'>
                                        {selectedWebsite.name}
                                    </Typography>
                                </Box>
                            ) :
                            (
                                <Box className={classes.userInfoInMenu} mt={0.25}>
                                    <Typography className='avatarName' variant='body1'>
                                        {userInfo ? userInfo.fullName : ''}
                                    </Typography>
                                    <Typography className='avatarEmail' variant='body2'>
                                        {userInfo ? userInfo.email : ''}
                                    </Typography>
                                </Box>
                            )
                        }
                    </Box>
                    <Divider className={classes.menuDivider} light={true} />
                    <Link to='/app/account/' className={classes.navBarLink}>
                        <StyledMenuItem onClick={handleMenuClose} dense={true} >
                            <ListItemIcon>
                                <SettingsOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary= 'Settings' />
                        </StyledMenuItem>
                    </Link>
                    <StyledMenuItem onClick={showWebsiteListInDropdownMenu} dense={true}>
                        <ListItemIcon>
                            <DevicesIcon className={classes.devicesIcon} />
                        </ListItemIcon>
                        <ListItemText primary='Switch website' />
                    </StyledMenuItem>
                    <StyledMenuItem onClick={handleOpenMail} dense={true}>
                        <ListItemIcon>
                            <FeedbackOutlinedIcon className={classes.feedbackIcon} />
                        </ListItemIcon>
                        <ListItemText primary='Send feedback' />
                    </StyledMenuItem>
                    <Divider className={classes.menuDivider} light={true} />
                    <StyledMenuItem onClick={(event) => {handleItemClick('signout', event)}} dense={true}>
                        <ListItemIcon>
                            <ExitToAppOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText>Sign out</ListItemText>
                    </StyledMenuItem>
                </Box>
                <Box className={`websiteDropdownSecondPage ${showWebsiteDropdownMenuIndex === DROPDOWN_MENU_INDEX.websiteList ? '' : 'hide'}`}>
                    <Box className='titleBox'>
                        <IconButton
                            aria-label='back to general menu dropdown'
                            aria-haspopup='true'
                            onClick={backToGeneralMenuDropdown}
                            color='inherit'
                        >
                            <ArrowBackIcon />
                        </IconButton>
                        <Typography variant='body1' display='inline'>
                            Websites
                        </Typography>
                    </Box>
                    <Box className='websiteList'>
                        {
                            schools.map(school => {
                                return (
                                    <MenuItem key={school.id} dense={true} onClick={() => onWebsiteItemClick(school.id)}>
                                        <Box className='websiteLogoBox' display='inline-box'>
                                            <Avatar
                                                className='websiteLogo'
                                                alt={school.name}
                                                src={school.logoImageUrl}
                                            />
                                        </Box>
                                        <Box className='websiteNameBox' display='inline-box'>
                                            <Typography variant='body1'>
                                                {school.name}
                                            </Typography>
                                        </Box>
                                        <Box className='checkIconBox' display='inline-box'>
                                            {
                                                school.id === userInfo.defaultSiteId ? 
                                                <CheckIcon /> : null
                                            }
                                        </Box>
                                    </MenuItem>
                                )
                            })
                        }
                        <MenuItem dense={true} onClick={() => onCreateWebsiteClick()}>
                            <Box className='createWebsiteIconBox' display='inline-box'>
                                <AddCircleIcon />
                            </Box>
                            <Box className='websiteNameBox' display='inline-box'>
                                <Typography variant='body1'>
                                    Create new website
                                </Typography>
                            </Box>
                        </MenuItem>
                    </Box>
                </Box>
            </StyledMenu>
        )
    };

    return (
        <div className={classes.root}>
            <AppBar className='appBarContainer' position='static' elevation={0}>
                <Toolbar className='toolbarContainer'>
                    <Box className='leftSection'>
                        <MuiLink className='brandContainer' href='/'>
                            <IconButton className='brandIcon' disableRipple={true}>
                            <Avatar variant='square' alt='airteach' style={{ height: '38px', width: '38px' }} src='/airteachLogo.svg' />
                            </IconButton>
                            { isLectureDashboardMode ?
                                null
                                : <Typography className='brandTitle' variant='body1' noWrap>airteach</Typography>
                            }
                        </MuiLink>
                            { isLectureDashboardMode ?
                                <>
                                    <Link to={`/app/${DASHBOARD_PATHNAME.websites}/${schoolId}/products/${courseId}/lectures`}>
                                        <IconButton className={classes.arrowBackButton}>
                                            <ArrowBackIcon className='arrowBackIcon' />
                                        </IconButton>
                                    </Link>
                                    <Box component='div' display='inline-block' ml={0.5} mr={2} color={TITLE_COLOR} style={{'verticalAlign': 'middle'}}>
                                        {title ? 
                                            <CourseTitleEdit id={titleId} content={title} saveContentHandler={handleTitleSave} />
                                            : null
                                        }
                                    </Box>
                                </> : null
                            }
                    </Box>
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                        <IconButton
                            edge='end'
                            aria-label='current selected website'
                            aria-controls={MENU_ID}
                            aria-haspopup='true'
                            onClick={handleProfileMenuOpen}
                            color='inherit'
                            style={{padding: '8px'}}
                        >
                            {renderAvatar('appBarAvatar')}
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderWebsiteDropdownMenu()}
        </div>
    );
}
