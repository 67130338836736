import React from 'react';
import {
    SnackbarContent,
    Icon,
    Snackbar
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';

import styles from './styles';

export default function UserAccountEndedInfo() {
    const classes = styles();
    const userInfo = useSelector(state => state.user.userInfo);
    const fetchUserCompleted = useSelector(state => state.user.fetchUserCompleted);

    if (userInfo && userInfo.subscriptionInfo) {
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                open={userInfo && (fetchUserCompleted && !userInfo.subscriptionInfo.isActive)}
            >
                <SnackbarContent
                    className={classes.error}
                    aria-describedby='client-snackbar'
                    message={(
                        <span id='client-snackbar' className={classes.message}>
                            <Icon className={`${classes.icon} ${classes.iconVariant}`}>error</Icon>
                            {userInfo && <span>{`This account was cancelled on ${moment(userInfo.subscriptionInfo.endAt).format('LL')}.`}</span>}
                        </span>
                    )}
                />
            </Snackbar>
        )
    }

    return null;
}
