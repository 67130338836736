import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    schoolContactsRoot: {
        width: '100%',
        height: '100%',
        overflow: 'auto',
        backgroundColor: theme.palette.dashboardBackground,
    }
}));
