import fetchClient from '../utils/fetchClient';

const URL = schoolId => `/api/schools/${schoolId}/courses`;

export async function fetchTextLecturesAPI(schoolId, courseId) {
    let data;
    await fetchClient(`${URL(schoolId)}/${courseId}/text-lectures`)
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );
    return data;
}

export async function fetchIndividualTextLectureAPI(schoolId, courseId, textLectureId) {
    let data;
    await fetchClient(`${URL(schoolId)}/${courseId}/text-lectures/${textLectureId}`)
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );
    return data;
}

export async function fetchTextLectureItemsAPI(schoolId, courseId, textLectureId) {
    let data;
    await fetchClient(`${URL(schoolId)}/${courseId}/text-lectures/${textLectureId}/items`)
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );
    return data;
}

export async function updateTextLecturesAPI(schoolId, courseId, textLectureId, payload) {
    let data;
    await fetchClient(`${URL(schoolId)}/${courseId}/text-lectures/${textLectureId}`, {
        method: 'PUT',
        body: payload
    })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );
    return data;
}

export async function createTextLectureAPI(schoolId, courseId, payload) {
    let data;
    await fetchClient(`${URL(schoolId)}/${courseId}/text-lectures`, {
        body: payload
    })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );
    return data;
}

export async function createTextLectureItemAPI(schoolId, courseId, textLectureId, payload) {
    let data;
    await fetchClient(`${URL(schoolId)}/${courseId}/text-lectures/${textLectureId}/items`, {
        body: payload
    })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );
    return data;
}

export async function updateTextLectureItemAPI(schoolId, courseId, textLectureId, textLectureItemId, payload) {
    let data;
    await fetchClient(`${URL(schoolId)}/${courseId}/text-lectures/${textLectureId}/items/${textLectureItemId}`, {
        method: 'PUT',
        body: payload
    })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );
    return data;
}

export async function deleteTextLectureAPI(schoolId, courseId, textLectureId) {
    let data;
    await fetchClient(`${URL(schoolId)}/${courseId}/text-lectures/${textLectureId}`, {
        method: 'DELETE'
    })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );
    return data;
}

export async function deleteTextLectureItemAPI(schoolId, courseId, textLectureId, textLectureItemId) {
    let data;
    await fetchClient(`${URL(schoolId)}/${courseId}/text-lectures/${textLectureId}/items/${textLectureItemId}`, {
        method: 'DELETE'
    })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );
    return data;
}
