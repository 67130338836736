import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Box
} from '@material-ui/core';
import {
    useRouteMatch,
    useLocation,
    Switch,
    Route,
} from 'react-router-dom';

import FetchLoading from '../../../components/FetchLoading';
import { getSchool } from '../../../features/school/schoolSlice';
import { DASHBOARD_PATHNAME } from '../../../utils/constant';
import SchoolOffers from './SchoolOffers';
import SchoolStudentUsers from './SchoolStudentUsers';
import SchoolAnalytics from './SchoolAnalytics';
import SchoolCoupons from './SchoolCoupons';
import styles from './School.styles';

const SchoolHome = lazy(() => import(/* webpackChunkName: "SchoolHome" */ './SchoolHome'));
const ProductList = lazy(() => import(/* webpackChunkName: "ProductList" */ './ProductList/ProductList'));
const SchoolSettings = lazy(() => import(/* webpackChunkName: "SchoolSettings" */ './SchoolSettings'));
const SchoolPayment = lazy(() => import(/* webpackChunkName: "SchoolPayment" */ './SchoolPayment'));
const SchoolDomain = lazy(() => import(/* webpackChunkName: "SchoolDomain" */ './SchoolDomain'));

export default function School() {
    const classes = styles();
    const dispatch = useDispatch();
    const location = useLocation();
    const match = useRouteMatch();
    const { schoolId } = match && match.params;
    const schoolObj = useSelector(state => state.school.school);

    const [webCourses, setWebCourses] = useState(null);
    const [textCourses, setTextCourses] = useState(null);
    const [initialSchoolLoadDone, setInitialSchoolLoadDone] = useState(false);

    const isProductListPage = useRouteMatch({
        path: `${match.path}/${DASHBOARD_PATHNAME.siteProducts}`,
        exact: true
    });

    useEffect(() => {
        if (schoolId) {
            setInitialSchoolLoadDone(false);
            const params = {
                schoolId,
                withPreview: true
            };
            if (isProductListPage) {
                params.withProducts = true;
            }
            dispatch(getSchool(params))
                .then(() => {
                    setInitialSchoolLoadDone(true);
                });
        }

        return () => {
            // reset
            setWebCourses(null);
            setTextCourses(null);
        }
    }, [location]); // eslint-disable-line

    useEffect(() => {
        const school = schoolObj[schoolId] || {};
        const courses = school.courses || {};

        setWebCourses(courses.web || []);
        setTextCourses(courses.text || []);
    }, [schoolObj]); // eslint-disable-line

    return (
        <Box className={classes.schoolRoot}>
            <>
                <Box className={classes.routeContentContainer}>
                    <Switch>
                        <Route exact path={`${match.path}/${DASHBOARD_PATHNAME.siteHome}`}>
                            <Suspense fallback={<FetchLoading />}>
                                <SchoolHome schoolId={schoolId} />
                            </Suspense>
                        </Route>
                        <Route exact path={`${match.path}/${DASHBOARD_PATHNAME.siteProducts}`}>
                            <Suspense fallback={<FetchLoading />}>
                                <ProductList
                                    schoolId={schoolId}
                                    webCourses={webCourses}
                                    textCourses={textCourses}
                                    initialSchoolLoadDone={initialSchoolLoadDone}
                                />
                            </Suspense>
                        </Route>
                        <Route exact path={`${match.path}/${DASHBOARD_PATHNAME.siteSettings}`}>
                            <Suspense fallback={<FetchLoading />}>
                                <SchoolSettings schoolId={schoolId} initialSchoolLoadDone={initialSchoolLoadDone} />
                            </Suspense>
                        </Route>
                        <Route path={`${match.path}/${DASHBOARD_PATHNAME.siteOffers}`}>
                            <SchoolOffers schoolId={schoolId} />
                        </Route>
                        <Route exact path={`${match.path}/${DASHBOARD_PATHNAME.sitePayment}`}>
                            <Suspense fallback={<FetchLoading />}>
                                <SchoolPayment schoolId={schoolId} />
                            </Suspense>
                        </Route>
                        <Route exact path={`${match.path}/${DASHBOARD_PATHNAME.siteDomain}`}>
                            <Suspense fallback={<FetchLoading />}>
                                <SchoolDomain schoolId={schoolId} />
                            </Suspense>
                        </Route>
                        <Route path={`${match.path}/${DASHBOARD_PATHNAME.siteContacts}`}>
                            <SchoolStudentUsers schoolId={schoolId} />
                        </Route>
                        <Route path={`${match.path}/${DASHBOARD_PATHNAME.siteAnalytics}`}>
                            <SchoolAnalytics schoolId={schoolId} />
                        </Route>
                        <Route path={`${match.path}/${DASHBOARD_PATHNAME.siteCoupons}`}>
                            <SchoolCoupons schoolId={schoolId} />
                        </Route>
                    </Switch>
                </Box>
            </>
        </Box>
    );
}
