import fetchClient from '../utils/fetchClient';

const URL = ids => `/api/schools/${ids.schoolId}/courses/${ids.courseId}/lectures/${ids.lectureId}/items/${ids.lectureItemId}/interactive-code`

export async function runCodeAPI(ids, params) {
    const url = `${URL(ids)}/run`;
    let data = {};

    await fetchClient(url, {
        body: {
            ...params
        }
    }).then(
        // success handler
        resData => {
            data = resData || {};
        },
        // error handler
        errData => {
            data = errData || {};
        }
    );

    return data;
}

export async function submidCodeAPI(ids, params) {
    const url = `${URL(ids)}/submit`;
    let data = {};

    await fetchClient(url, {
        body: {
            ...params
        }
    }).then(
        // success handler
        resData => {
            data = resData || {};
        },
        // error handler
        errData => {
            data = errData || {};
        }
    );

    return data;
}
