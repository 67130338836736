import fetchClient from '../utils/fetchClient';

const URL = (schoolId, offerId) => {
    return `/api/schools/${schoolId}/offers/${offerId}/courses`
};

export async function createOfferCourseAPI({ schoolId, offerId, data }) {
    await fetchClient(
        URL(schoolId, offerId),
        {
            body: data
        })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}

export async function deleteOfferCourseAPI({ schoolId, offerId, courseId }) {
    let data;
    await fetchClient(
        `${URL(schoolId, offerId)}/${courseId}`,
        {
            method: 'DELETE'
        }
    )
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );
    return data;
}
