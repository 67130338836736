import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { SnackbarUtil } from '../../components/SnackbarUtilsConfig';
import { API_ERROR_RESPONSE } from '../../utils/constant';
import { routeToLoginPageWithCurrentPath } from '../../utils/helper';
import {
    fetchSchoolList,
    fetchSchool,
    updateSchoolAPI,
    createSchoolAPI,
    deleteSchoolAPI
}  from '../../api/schoolAPI';
import { updateShowNoPageFoundNow } from '../noPageFound/noPageFoundSlice';

const initialState = {
    school: {},
    schools: [],

    fetchSchoolsPending: false,
    fetchSchoolsError: '',

    getSchoolPending: true,
    getSchoolError: '',

    newCreatedSchool: null,
    createSchoolSuccess: false,
    createSchoolPending: false,
    createSchoolError: '',

    updateSchoolPending: false,
    updateSchoolError: '',

    updateSubdomainPending: false,
    updateSubdomainError: '',

    updateSchoolBannerPending: false,
    updateSchoolBannerError: '',

    updateSchoolLogoPending: false,
    updateSchoolLogoError: '',

    deleteSchoolPending: false,

    countProduct: 0,
    countWebsite: 0
};

export const createSchool = createAsyncThunk(
    'schools/create',
    async (data, thunkAPI) => {
        const response = await createSchoolAPI(data)

        return response;
    }
);

export const fetchSchools = createAsyncThunk(
    'schools/fetch-list',
    async () => {
        const data = await fetchSchoolList()
        return data
    }
);

export const getSchool = createAsyncThunk(
    'schools/fetch',
    async (params, { dispatch }) => {
        const data = await fetchSchool(params.schoolId, params.withPreview, params.withProducts);

        if (data.status === 'fail') {
            const { apiStatusCode, error: { message } } = data;
            if (apiStatusCode === 401 && message.includes('Unauthorized access')) {
                routeToLoginPageWithCurrentPath();
            } else {
                dispatch(updateShowNoPageFoundNow({ showNoPageFoundNow: true }));
            }
            throw new Error();
        }

        return data;
    }
);

export const updateSchool = createAsyncThunk(
    'schools/update',
    async (data) => {
        const response = await updateSchoolAPI(data)

        return response;
    }
);

export const updateSubdomain = createAsyncThunk(
    'schools/updateSubdomain',
    async (data) => {
        const response = await updateSchoolAPI(data)
        return response;
    }
);

export const updateSchoolBanner = createAsyncThunk(
    'schools/updateSchoolBanner',
    async (data) => {
        const response = await updateSchoolAPI(data)
        return response;
    }
);

export const updateSchoolLogo = createAsyncThunk(
    'schools/updateSchoolLogo',
    async (data) => {
        const response = await updateSchoolAPI(data)
        return response;
    }
);

export const deleteSchool = createAsyncThunk(
    'schools/delete',
    async (schoolId) => {
        const response = await deleteSchoolAPI(schoolId);
        if (response.status === 'fail' && response.error) {
            SnackbarUtil.error(response.error.message || API_ERROR_RESPONSE);
        } else if (response.status === 'success') {
            SnackbarUtil.success('School is successfully deleted.')
        }
        return response;
    } 
);

function resetAllErrorMessage(state) {
    state.getSchoolError = '';
    state.createSchoolError = '';
    state.updateSchoolError = '';
    state.updateSubdomainError = '';
    state.updateSchoolBannerError = '';
    state.updateSchoolLogoError = '';
}

const schoolSlice = createSlice({
    name: 'school',
    initialState,
    reducers: {
        addSchool: (state, action) => {
            const { schools } = action.payload;
            state.schools = schools;
        },
        resetCreateSchoolStates: (state) => {
            state.newCreatedSchool = null;
            state.createSchoolSuccess = false;
            state.createSchoolPending = false;
            state.createSchoolError = '';
        },
    },
    extraReducers: {
        // Add reducers for additional action types here, and handle loading state as needed
        [fetchSchools.fulfilled]: (state, action) => {
            const {
                payload: { schools, status, error, countWebsite },
            } = action;

            if (status === "success") {
                state.schools = schools;
                state.countWebsite = countWebsite;
            } else if (error) {
                state.fetchSchoolsError = error.message;
            }

            state.fetchSchoolsPending = false;
        },
        [fetchSchools.pending]: (state, action) => {
            state.fetchSchoolsPending = true;
            state.fetchSchoolsError = '';
        },
        [fetchSchools.rejected]: state => {
            state.fetchSchoolsPending = false;
        },
        [getSchool.pending]: state => {
            state.getSchoolPending = true;

            resetAllErrorMessage(state);
        },
        [getSchool.fulfilled]: (state, action) => {
            const { payload: { school, error, countProduct }, meta: { arg: { schoolId } } } = action;

            state.school[schoolId] = school;

            if (countProduct !== undefined) {
                state.countProduct = countProduct;
            } 

            if (error) {
                state.getSchoolError = error
            }

            state.getSchoolPending = false;
        },
        [createSchool.pending]: state => {
            state.newCreatedSchool = null;
            state.createSchoolSuccess = false;
            state.createSchoolPending = true;
            state.createSchoolError = '';
        },
        [createSchool.fulfilled]: (state, action) => {
            const {
                payload: { school, error },
            } = action;

            if (school && !error) {
                state.newCreatedSchool = school;
                state.createSchoolSuccess = true;
            } else {
                state.newCreatedSchool = null;
                state.createSchoolError = error && error.message;
                state.createSchoolSuccess = false;
            }
            state.createSchoolPending = false;
        },
        [createSchool.rejected]: state => {
            state.newCreatedSchool = null;
            state.createSchoolPending = false;
            state.createSchoolSuccess = false;
        },
        [updateSchool.pending]: state => {
            state.updateSchoolPending = true;
            state.updateSchoolError = '';
        },
        [updateSchool.fulfilled]: (state, action) => {
            const {
                payload: { school, status, error },
                meta: {
                    arg: { id }
                }
            } = action;

            if (status === "success") {
                state.school[id] = school;
            } else if (error) {
                state.updateSchoolError = error.message
            }
            state.updateSchoolPending = false;
        },
        [updateSubdomain.pending]: state => {
            state.updateSubdomainPending = true;
            state.updateSubdomainError = '';
        },
        [updateSubdomain.fulfilled]: (state, action) => {
            const {
                payload: { school, status, error },
                meta: {
                    arg: { id }
                }
            } = action;

            if (status === "success") {
                state.school[id] = school;
            } else if (error) {
                state.updateSubdomainError = error.message
            }
            state.updateSubdomainPending = false;
        },
        [updateSchoolBanner.pending]: state => {
            state.updateSchoolBannerPending = true;
            state.updateSchoolBannerError = '';
        },
        [updateSchoolBanner.fulfilled]: (state, action) => {
            const {
                payload: { school, status, error },
                meta: {
                    arg: { id }
                }
            } = action;
            if (status === "success") {
                state.school[id]= school;
            } else if (error) {
                state.updateSchoolBannerError = error.message
            }

            state.updateSchoolBannerPending = false;
        },
        [updateSchoolLogo.pending]: state => {
            state.updateSchoolLogoPending = true;
            state.updateSchoolLogoError = '';
        },
        [updateSchoolLogo.fulfilled]: (state, action) => {
            const {
                payload: { school, status, error },
                meta: {
                    arg: { id }
                }
            } = action;
            if (status === 'success') {
                state.school[id] = school;
            } else if (error) {
                state.updateSchoolLogoError = error.message
            }
            state.updateSchoolLogoPending = false;
        },
        [deleteSchool.pending]: state => {
            state.deleteSchoolPending = true;
        },
        [deleteSchool.fulfilled]: (state, action) => {
            const {
                payload: { status },
                meta: {
                    arg: { schoolId }
                }
            } = action;
            state.deleteSchoolPending = false;
            if (status === 'success') {
                // remove the school from store on successful delete
                state.school[schoolId] = undefined;
            }
        }
    }
});

export const { addSchool, resetCreateSchoolStates } = schoolSlice.actions;

export default schoolSlice.reducer;
