import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import {
    fetchLectureItemsAPI,
    createLectureItemAPI,
    updateLectureItemAPI,
    deleteLectureItemAPI,
} from '../../api/courseLectureItemAPI';
import {
    updateCourseLectureAPI
} from  '../../api/courseLectureAPI';
import { API_ERROR_RESPONSE } from '../../utils/constant';
import { transformInteractiveCodeTestCase } from '../../utils/interactiveCodeUtil';
import { routeToLoginPageWithCurrentPath } from '../../utils/helper';
import { SnackbarUtil } from '../../components/SnackbarUtilsConfig';
import { updateShowNoPageFoundNow } from '../noPageFound/noPageFoundSlice';

const initialState = {
    course: {},
    lectureId:  null,
    lecture: null,
    lectureItems: [],
    lectureItemsElemIdMap: {},
    lectureItemsMap: {},
    fetchLectureItemsPending: false,
    previewUrl: '',

    // interactive code
    createInteractiveCodePending: false
};

const getLectureItemModel = function(params) {
    const {
        id,
        content,
        position,
        contentType,
        elemId,
        mimeType,
        filename,
        mediaFileUrl,
        codeExample,
        codeExampleType,
        embedCode,
        filestackHandle,
        mcTypeId,
        questionPrompt,
        mcOptions,
        title,
        testFunctionName,
        description,
        languageId,
        entryFile,
        interactiveCodeNodes,
        interactiveCodeTabs,
        interactiveCodeInputOutputTests
    } = params;

    return {
        id,
        content,
        position,
        contentType,
        elemId,
        mimeType,
        filename,
        mediaFileUrl,
        codeExample,
        codeExampleType,
        embedCode,
        filestackHandle,
        mcTypeId,
        questionPrompt,
        mcOptions,
        title,
        testFunctionName,
        description,
        languageId,
        entryFile,
        interactiveCodeNodes,
        interactiveCodeTabs,
        interactiveCodeInputOutputTests
    }
}

const getLectureModel = function(data={}) {
    const {
        id,
        course_chapter_id,
        name,
        position,
    } = data;

    return {
        id,
        courseChapterId: course_chapter_id,
        name,
        position,
    }
}

// fetch lecture items thunk
export const fetchLectureItems = createAsyncThunk(
    'courseLectureItem/fetch',
    async (params, { dispatch }) => {
        const {
            withPreview
        } = params;

        const data = await fetchLectureItemsAPI(params.schoolId, params.courseId, params.lectureId, withPreview);

        if (data.status === 'fail') {
            const { apiStatusCode, error: { message } } = data;
            if (apiStatusCode === 401 && message.includes('Unauthorized access')) {
                routeToLoginPageWithCurrentPath();
            } else {
                dispatch(updateShowNoPageFoundNow({ showNoPageFoundNow: true }));
            }
            throw new Error();
        }

        return {
            courseId: data.courseId,
            courseLecture: data.courseLecture,
            previewUrl: data.previewUrl
        }
    }
);

// create lecture thunk
export const createLectureItem = createAsyncThunk(
    'courseLectureItem/create',
    async (params) => {
        const {
            schoolId,
            courseId,
            lectureId,
            content,
            position,
            contentType,
            elemId,
            codeExample,
            codeExampleType,
            embedCode,
            filestackHandle,
            mcTypeId,
            questionPrompt
        } = params;

        const response = await createLectureItemAPI({
            schoolId,
            courseId,
            lectureId,
            content,
            position,
            contentType,
            elemId,
            codeExample,
            codeExampleType,
            embedCode,
            filestackHandle,
            mcTypeId,
            questionPrompt
        });

        if (response.status === 'fail' && response.error) {
            throw new Error(response.error.message || API_ERROR_RESPONSE);
        }

        return {
            lectureItem: response.lecture_item
        }
    }
);

// update lecture thunk
export const updateLecture = createAsyncThunk(
    'courseLecture/update',
    async (params) => {
        const {
            schoolId,
            courseId,
            lectureId,
            courseLecture
        } = params;

        const response = await updateCourseLectureAPI(
            schoolId,
            courseId,
            lectureId,
            courseLecture
        );

        if (response.error) {
            SnackbarUtil.error('Request error. It seems there is an issue in updating the lecture.');
        }
    }
);

// update lecture item thunk
export const updateLectureItem = createAsyncThunk(
    'courseLectureItem/update',
    async (params) => {
        const {
            schoolId,
            courseId,
            lectureId,
            lectureItemId,
            lectureItem,
            interactiveNodes,
            testCases
        } = params;

        const response = await updateLectureItemAPI(
            schoolId,
            courseId,
            lectureId,
            lectureItemId,
            lectureItem,
            interactiveNodes,
            testCases
        );
        return response;
    }
);

// delete lecture item thunk
export const deleteLectureItem = createAsyncThunk(
    'courseLectureItem/delete',
    async (params) => {
        const {
            schoolId,
            courseId,
            lectureId,
            lectureItemId,
        } = params;

        // if lecture item id is not found, something wrong flag an error message to refresh the page
        if (!lectureItemId) {
            SnackbarUtil.error('Something went wrong. Please refresh your page and try again.');
            return;
        }

        const response = await deleteLectureItemAPI(
            schoolId,
            courseId,
            lectureId,
            lectureItemId,
        );

        if (response.error) {
            SnackbarUtil.error('Request error. It seems there is an issue in deleting the lecture content.');
        }
    }
);

const updateLectureItemsState = function(state, action) {
    const { courseLecture, previewUrl } = action.payload;

    const lecture = courseLecture ? courseLecture[0] : {};
    const lectureItemsElemIdMap = {};
    const lectureItemsMap = {};
    let lectureItems = lecture.lectureItems || [];

    lectureItems = lectureItems.map(lectureItem => {
        const elemId = uuid();

        lectureItem = getLectureItemModel({
            id: lectureItem.id,
            content: lectureItem.content,
            position: lectureItem.position,
            contentType: lectureItem.content_type,
            mimeType: lectureItem.mime_type,
            filename: lectureItem.filename,
            mediaFileUrl: lectureItem.media_file_url,
            codeExample: lectureItem.code_example,
            codeExampleType: lectureItem.code_example_type,
            embedCode: lectureItem.embed_code,
            filestackHandle: lectureItem.filestack_handle,
            mcTypeId: lectureItem.mc_type_id,
            questionPrompt: lectureItem.question_prompt,
            mcOptions: lectureItem.mc_options,
            title: lectureItem.title,
            testFunctionName: lectureItem.test_function_name,
            description: lectureItem.description,
            languageId: lectureItem.language_id,
            entryFile: lectureItem.entry_file,
            interactiveCodeNodes: lectureItem.interactiveCodeNodes.map(node => ({
                path: node.path,
                content: node.content,
                typeId: node.type_id
            })),
            interactiveCodeInputOutputTests: lectureItem.interactiveCodeInputOutputTests.map(testCase => transformInteractiveCodeTestCase(testCase)),
            interactiveCodeTabs: lectureItem.interactive_code_tabs && lectureItem.interactive_code_tabs.map(button => ({ typeId: button.typeId })),
            elemId
        });

        lectureItemsElemIdMap[lectureItem.elemId] = lectureItem;
        lectureItemsMap[lectureItem.id] = lectureItem;

        return lectureItem;
    })

    lectureItems.sort((a,b) => {
        return a.position - b.position;
    })

    // update state
    state.lectureId = lecture.id;
    state.lectureItems = lectureItems;
    state.lectureItemsElemIdMap = lectureItemsElemIdMap;
    state.lectureItemsMap = lectureItemsMap;
    state.lecture = getLectureModel(lecture);
    state.previewUrl = previewUrl;

    state.fetchLectureItemsPending = false;
}

const updateNewLectureItemState = function(state, action) {
    const {
        lectureItem
    } = action.payload;

    const elemId = lectureItem.elem_id;
    const lectureId = lectureItem.course_lecture_id;
    let lectureItems = state.lectureItems;
    let foundIndex = lectureItems.findIndex(item => item.elemId === elemId);

    // update id if elemId is found and the lecture id is the same
    if (foundIndex !== -1 && lectureId === state.lectureId) {
        const oldLectureItem = lectureItems[foundIndex];
        const lectureId = lectureItem.id;
        const newLectureItem = getLectureItemModel({
            id: lectureId,
            content: oldLectureItem.content,
            position: oldLectureItem.position,
            contentType: oldLectureItem.contentType,
            mcTypeId: oldLectureItem.mcTypeId,
            questionPrompt: oldLectureItem.questionPrompt,
            mcOptions: oldLectureItem.mcOptions,
            embedCode: oldLectureItem.embedCode,
            title: oldLectureItem.title,
            testFunctionName: oldLectureItem.testFunctionName,
            description: oldLectureItem.description,
            languageId: oldLectureItem.languageId,
            interactiveCodeNodes: oldLectureItem.interactiveCodeNodes,
            interactiveCodeTabs: oldLectureItem.interactiveCodeTabs,
            interactiveCodeInputOutputTests: oldLectureItem.interactiveCodeInputOutputTests,
            elemId: elemId
        });
        const lectureItemsElemIdMap = {};
        const lectureItemsMap = {};

        lectureItems[foundIndex] = newLectureItem;
        lectureItems.forEach(lectureItem => {
            lectureItemsMap[lectureItem.id] = lectureItem;
            lectureItemsElemIdMap[lectureItem.elemId] = lectureItem;
        })

        // update state
        state.lectureItems = lectureItems;
        state.lectureItemsElemIdMap = lectureItemsElemIdMap;
        state.lectureItemsMap = lectureItemsMap;
    }
}

const courseLectureSlice = createSlice({
    name: 'courseLectures',
    initialState,
    reducers: {
        updateLectureItemsAction: (state, action) => {
            let {
                lectureId,
                lectureItems
            } = action.payload;

            // check we are updating the same lecture
            if (lectureId !== state.lectureId) {
                console.error('Lecture id mismatches the store lecture id.');
                return;
            }

            let lectureItemsElemIdMap = {};
            let lectureItemsMap = {};

            lectureItems.sort((a,b) => {
                return a.position - b.position;
            })

            lectureItems.forEach(lectureItem => {
                if (!lectureItem.hasOwnProperty('elemId')) {
                    lectureItem.elemId = uuid();
                }

                lectureItemsMap[lectureItem.id] = lectureItem;
                lectureItemsElemIdMap[lectureItem.elemId] = lectureItem;
            })

            state.lectureId = lectureId;
            state.lectureItems = lectureItems;
            state.lectureItemsMap = lectureItemsMap;
            state.lectureItemsElemIdMap = lectureItemsElemIdMap;
        },
        createLectureItemAction: (state, action) => {
            const {
                lectureId,
                content,
                position,
                contentType,
                codeExample,
                codeExampleType,
                elemId,
                embedCode,
                filestackHandle,
                mcTypeId,
                questionPrompt,
                mcOptions,
                title,
                testFunctionName,
                description,
                languageId,
                interactiveCodeTabs,
                interactiveCodeNodes = [],
                interactiveCodeInputOutputTests = []
            } = action.payload;

            if (lectureId !== state.lectureId) {
                console.error('Lecture id mismatches the store lecture id.');
                return;
            }

            const lectureItem = getLectureItemModel({
                id: null,
                content,
                position,
                contentType,
                codeExample,
                codeExampleType,
                elemId,
                embedCode,
                filestackHandle,
                mcTypeId,
                questionPrompt,
                mcOptions,
                title,
                testFunctionName,
                description,
                languageId,
                interactiveCodeNodes,
                interactiveCodeInputOutputTests,
                interactiveCodeTabs
            });

            let lectureItems = state.lectureItems;
            const lectureItemsElemIdMap = state.lectureItemsElemIdMap;

            lectureItems.splice(parseInt(lectureItem.position), 0, lectureItem);

            // Update each lecture item position to make sure the items have no duplicate position
            // and are in order.
            lectureItems.forEach((item, index) => {
                item.position = index;
            })

            lectureItemsElemIdMap[lectureItem.elemId] = lectureItem;

            state.lectureItems = lectureItems;
            state.lectureItemsElemIdMap = lectureItemsElemIdMap;
        },
        updateLectureAction: (state, action) => {
            let {
                lectureId,
                courseLecture
            } = action.payload;

            // check we are updating the same lecture
            if (lectureId !== state.lectureId) {
                console.error('Lecture id mismatches the store lecture id.');
                return;
            }

            state.lecture = getLectureModel(courseLecture);
        },
        deleteLectureItemAction:  (state, action) => {
            let {
                lectureItemId
            } = action.payload;

            const lectureItem = state.lectureItemsMap[lectureItemId];

            // update states
            state.lectureItems = state.lectureItems.filter((item) => {
                return item.id !== lectureItemId;
            })
            delete state.lectureItemsElemIdMap[lectureItem.elemId];
            delete state.lectureItemsMap[lectureItemId];
        }
    },
    extraReducers: {
        // Add reducers for additional action types here, and handle loading state as needed
        [fetchLectureItems.fulfilled]: updateLectureItemsState,
        [fetchLectureItems.pending]: state => {
            state.fetchLectureItemsPending = true;
        },
        [fetchLectureItems.rejected]: state => {
            state.fetchLectureItemsPending = false;
        },
        [createLectureItem.fulfilled]: updateNewLectureItemState
    }
});

export const { createLectureItemAction, updateLectureItemsAction, updateLectureAction, deleteLectureItemAction } = courseLectureSlice.actions;

export default courseLectureSlice.reducer;
