import fetchClient from '../utils/fetchClient';

export async function fetchLectureItemsAPI(schoolId, courseId, lectureId, withPreview) {
    const url = `/api/schools/${schoolId}/courses/${courseId}/lectures/${lectureId}/items?withPreview=${withPreview}`;
    let data = {};

    await fetchClient(url)
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}

export async function createLectureItemAPI(lectureItemData) {
    const {
        schoolId,
        courseId,
        lectureId,
        content,
        position,
        contentType,
        elemId,
        codeExample,
        codeExampleType,
        embedCode,
        filestackHandle,
        mcTypeId,
        questionPrompt
    } = lectureItemData;

    const url = `/api/schools/${schoolId}/courses/${courseId}/lectures/${lectureId}/items`;


    let data = {};

    await fetchClient(url, {
        method: 'POST',
        body: {
            contentType,
            content,
            position,
            elemId,
            codeExample,
            codeExampleType,
            embedCode,
            filestackHandle,
            mcTypeId,
            questionPrompt
        }
    }).then(
        // success handler
        resData => {
            data = resData || {};
        },
        // error handler
        errData => {
            data = errData || {};
        }
    );

    return data;
}

export async function updateLectureItemAPI(
    schoolId,
    courseId,
    lectureId,
    lectureItemId,
    lectureItem,
    interactiveNodes,
    testCases
) {
    const url = `/api/schools/${schoolId}/courses/${courseId}/lectures/${lectureId}/items/${lectureItemId}`;
    let data = {}

    await fetchClient(
        url,
        {
            method: 'PUT',
            body: { lectureItem, interactiveNodes, testCases }
        }
    ).then(
        resData => {
            data = resData;
        },
        errData => {
            data = errData;
        }
    );

    return data;
}

export async function deleteLectureItemAPI(schoolId, courseId, lectureId, lectureItemId) {
    const url = `/api/schools/${schoolId}/courses/${courseId}/lectures/${lectureId}/items/${lectureItemId}`;
    let data = {};

    await fetchClient(
        url,
        {
            method: 'DELETE'
        }
    ).then(
        resData => {
            data = resData;
        },
        errData => {
            data = errData;
        }
    );

    return data;
}
