import fetchClient from '../utils/fetchClient';

const URL = (schoolId, isSimple, withLearner) => {
    let url = `/api/schools/${schoolId}/courses`;
    if (isSimple) {
        url = `${url}?simple=true`;
    }
    if (withLearner) {
        url = `${url}${isSimple ? '&withLearner=true' : '?withLearner=true'}`
    }
    return url;
};

export async function fetchCoursesAPI(schoolId, isSimple, withLearner) {
    let data = {};

    await fetchClient(URL(schoolId, isSimple, withLearner))
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}

export async function createCoursesAPI({ schoolId, data }) {
    await fetchClient(
        URL(schoolId),
        {
            body: data
        })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}

export async function fetchCourseEnrollmentAPI(schoolId, courseId) {
    let data = {};

    await fetchClient(`${URL(schoolId)}/${courseId}/enrollment`)
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}

export async function fetchProductRelatedOfferListAPI(schoolId, courseId) {
    let data = {};

    await fetchClient(`${URL(schoolId)}/${courseId}/offer-list`)
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}

export async function fetchIndividualCourseAPI(schoolId, courseId, withAuthor, withTotalLecture) {
    let data = {};
    let url =`${URL(schoolId)}/${courseId}?withAuthor=${withAuthor}`;
    if (withTotalLecture) {
        url = `${url}&withTotalLecture=${withTotalLecture}`;
    }

    await fetchClient(url)
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}

export async function fetchTotalPublishedLecturesAPI(schoolId, courseId) {
    let data = {};

    await fetchClient(`${URL(schoolId)}/${courseId}/published-lectures`)
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}

export async function updateCourseAPI({ payload, schoolId, courseId }) {
    let data = {};

    await fetchClient(
        `${URL(schoolId)}/${courseId}`,
        {
            method: 'PUT',
            body: payload
        })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}

export async function updateCourseMediaAPI({ payload, schoolId, courseId }) {
    let data = {};

    await fetchClient(
        `${URL(schoolId)}/${courseId}/media`,
        {
            method: 'PUT',
            body: payload
        })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}

export async function updateAuthorMediaAPI({ payload, schoolId, courseId }) {
    let data = {};

    await fetchClient(
        `${URL(schoolId)}/${courseId}/author-media`,
        {
            method: 'PUT',
            body: payload
        })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}

export async function deleteCourseAPI(schoolId, courseId) {
    const url = `${URL(schoolId)}/${courseId}`;
    let data = {};
    await fetchClient(
        url,
        {
            method: 'DELETE'
        }
    ).then(
        resData => {
            data = resData;
        },
        errData => {
            data = errData;
        }
    );
    return data;
}

export async function fetchMicrolearningAnalyticsAPI(schoolId, courseId, page, rowSize) {
    let data = {};

    let url = `${URL(schoolId)}/${courseId}/microlearning-analytics?page=${page}`;
    if (rowSize) {
        url = `${url}&rowSize=${rowSize}`;
    }

    await fetchClient(url)
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}

export async function fetchWebAnalyticsAPI(schoolId, courseId, page, rowSize) {
    let data = {};

    let url = `${URL(schoolId)}/${courseId}/web-analytics?page=${page}`;
    if (rowSize) {
        url = `${url}&rowSize=${rowSize}`;
    }

    await fetchClient(url)
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}
