import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    fetchCourseStudentsAPI,
    unenrollCourseStudentsAPI
}  from '../../api/courseStudentAPI';
import { INTERNAL_SERVER_ERR_MSG } from '../../utils/constant';

const initialState = {
    students:  [],
    studentsObj: {},
    totalStudent: 0,
    fetchStudentLoading: false,
    fetchStudentError: '',
};

export const fetchStudents = createAsyncThunk(
    'students/fetch',
    async ({ schoolId, courseId, params, mergeStudents=false}) => {
        const data = await fetchCourseStudentsAPI(schoolId, courseId, params);
        return {
            mergeStudents,
            ...data
        }
    }
);

export const unenrollStudents = createAsyncThunk(
    'students/unenroll',
    async ({ schoolId, courseId, studentIds}) => {
        const data = await unenrollCourseStudentsAPI(schoolId, courseId, studentIds);
        return data;
    }
);

const courseStudentSlice = createSlice({
    name: 'course student',
    initialState,
    reducers: {
        resetCourseStudent: (state) => {
            state.students = [];
            state.totalStudent = 0;
            state.fetchStudentError = '';
            state.studentsObj = {};
        }
    },
    extraReducers: {
        // Add reducers for additional action types here, and handle loading state as needed
        [fetchStudents.fulfilled]: (state, action) => {
            const { students, totalStudent, mergeStudents } = action.payload;
            const newStudents  = mergeStudents ? [].concat(state.students).concat(students) : students;

            // save students in array
            state.students = newStudents;

            // save students in obj for quick reference
            const newStudentsObj = {};
            newStudents.forEach(student => {
                newStudentsObj[student.id] = student;
            });

            state.studentsObj = newStudentsObj;

            state.totalStudent = parseInt(totalStudent, 10);
            state.fetchStudentLoading = false;
        },
        [fetchStudents.pending]: state => {
            state.fetchStudentLoading = true;
        },
        [fetchStudents.rejected]: state => {
            state.fetchStudentLoading = false;
            state.fetchStudentError = INTERNAL_SERVER_ERR_MSG;
        }
    }
});

export const { resetCourseStudent } = courseStudentSlice.actions;

export default courseStudentSlice.reducer;
