import { createSlice, createAction } from '@reduxjs/toolkit';

const initialState = {
    showNoPageFoundNow: false
};

export const updateShowNoPageFoundNow = createAction('showNoPageFoundNow/update');

const noPageFoundSlice = createSlice({
    name: 'noPageFoundSlice',
    initialState,
    extraReducers: {
        [updateShowNoPageFoundNow]: (state, action) => {
            const { showNoPageFoundNow=false } = action.payload;
            state.showNoPageFoundNow = showNoPageFoundNow;
        }
    }
});

export default noPageFoundSlice.reducer;
