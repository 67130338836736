import fetchClient from '../utils/fetchClient';

const URL = (schoolId) => {
    return `/api/schools/${schoolId}/student-users`
};

export async function getStudentUsersAPI({ schoolId, page, rowSize, signal }) {
    let data = null;

    let url = `${URL(schoolId)}?page=${page}`;
    if (rowSize) {
        url = `${url}&rowSize=${rowSize}`;
    }

    await fetchClient(url, { signal })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}

export async function getStudentUserByIdAPI({ schoolId, studentUserId, includeOffers }) {
    let data = null;

    let url = `${URL(schoolId)}/${studentUserId}`;
    if (includeOffers) {
        url = `${url}?includeOffers=true&withProductInfo=true`;
    }
    await fetchClient(url)
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}

export async function createStudentUsersAPI({ schoolId, body }) {
    let data = null;

    await fetchClient(URL(schoolId), {
        body
    })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}

export async function updateStudentUsersAPI({ schoolId, studentUserId, body }) {
    let data = null;

    await fetchClient(`${URL(schoolId)}/${studentUserId}`,
    {
        method: 'PUT',
        body
    })
    .then(
        // success handler
        resData => {
            data = resData;
        },
        // error handler
        errData => {
            data = errData;
        }
    );

    return data;
}

export async function grantStudentUserOffersAPI({ schoolId, body }) {
    let data = null;

    await fetchClient(`/api/schools/${schoolId}/grant-offer-access`, {
        body
    })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}

export async function deleteStudentUserAPI({ schoolId, studentUserId, body }) {
    let data = null;

    await fetchClient(`${URL(schoolId)}/${studentUserId}`,
    {
        method: 'DELETE',
        body
    })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}
