import {
    INSTRUCTION_INTERACTIVE_NODE_PATH,
    INTERACTIVE_CODE_NODE_TYPE_ID,
    INTERACTIVE_CODE_MAIN_FILE_PATH,
    INTERACTIVE_CODE_SETUP_CODE_FILE_PATH
} from './constant';

export function getInstructionInteractiveNode(interactiveCodeNodes) {
    const instructionNode = interactiveCodeNodes.find(node => (
        node.path === INSTRUCTION_INTERACTIVE_NODE_PATH && node.typeId === INTERACTIVE_CODE_NODE_TYPE_ID.file)
    );

    return instructionNode || null;
}

export function getMainProgramFile(interactiveCodeNodes, languageId) {
    const mainProgramNode = interactiveCodeNodes.find(node => (
        node.path === INTERACTIVE_CODE_MAIN_FILE_PATH[languageId] && node.typeId === INTERACTIVE_CODE_NODE_TYPE_ID.file)
    );

    return mainProgramNode || null;
}

export function getSetupCodeFile(interactiveCodeNodes, languageId) {
    const mainProgramNode = interactiveCodeNodes.find(node => (
        node.path === INTERACTIVE_CODE_SETUP_CODE_FILE_PATH[languageId] && node.typeId === INTERACTIVE_CODE_NODE_TYPE_ID.file)
    );

    return mainProgramNode || null;
}

export function getInstructionNodeIndex(interactiveCodeNodes) {
    const index = interactiveCodeNodes.findIndex(node => (
        node.path === INSTRUCTION_INTERACTIVE_NODE_PATH && node.typeId === INTERACTIVE_CODE_NODE_TYPE_ID.file)
    );

    return index;
}

export function getMainProgramNodeIndex(interactiveCodeNodes, languageId) {
    const index = interactiveCodeNodes.findIndex(node => (
        node.path === INTERACTIVE_CODE_MAIN_FILE_PATH[languageId] && node.typeId === INTERACTIVE_CODE_NODE_TYPE_ID.file)
    );

    return index;
}

export function getSetupCodeNodeIndex(interactiveCodeNodes, languageId) {
    const index = interactiveCodeNodes.findIndex(node => (
        node.path === INTERACTIVE_CODE_SETUP_CODE_FILE_PATH[languageId] && node.typeId === INTERACTIVE_CODE_NODE_TYPE_ID.file)
    );

    return index;
}

export function getInteractiveCodeLectureItem(state, lectureItemId) {
    return state.interactiveCode.lectureItems[lectureItemId] || {};
}

export function transformInteractiveCodeTestCase(testCase) {
    return {
        id: testCase.id,
        title: testCase.title,
        input: testCase.input,
        expectedOutput: testCase.expected_output
    }
}
