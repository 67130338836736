import React, { Suspense, lazy, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Toolbar,
    Grid,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
    Drawer,
    List
} from '@material-ui/core';
import {
    Link,
    Switch,
    Route,
    Redirect,
    useLocation,
    useRouteMatch
} from 'react-router-dom';
import ViewDashboard from 'mdi-material-ui/ViewDashboard';
import NoteMultipleOutline from 'mdi-material-ui/NoteMultipleOutline';
import WaterOutline from 'mdi-material-ui/WaterOutline';
import CogOutline from 'mdi-material-ui/CogOutline';
import MessageAlertOutline from 'mdi-material-ui/MessageAlertOutline';
import AccountGroup from 'mdi-material-ui/AccountGroup';
import { withSnackbar } from 'notistack';

import { updateShowNoPageFoundNow } from '../../../features/noPageFound/noPageFoundSlice';
import { isTextCourse } from '../../../utils/helper';
import CourseNavAndInfo from '../../../components/course/courseNavAndInfo/CourseNavAndInfo';
import DashboardAppBar from '../../../components/DashboardAppBar';
import FetchLoading from '../../../components/FetchLoading';

// Redux
import { fetchIndividualCourse } from '../../../features/course/courseSlice';

import {
    DASHBOARD_PATHNAME,
    COURSE_DASHBOARD_PATHNAME,
    SUPPORT_EMAIL
} from '../../../utils/constant';
import styles from './CourseDashboard.styles';

const CourseOverview = lazy(() => import(/* webpackChunkName: "CourseOverview" */ '../CourseOverview'));
const CourseChapterAndLecture = lazy(() => import(/* webpackChunkName: "CourseChapterAndLecture" */ '../CourseChapterAndLecture/CourseChapterAndLecture'));
const CourseStudent = lazy(() => import(/* webpackChunkName: "CourseStudent" */ '../CourseStudent/CourseStudent'));
const CourseSetting = lazy(() => import(/* webpackChunkName: "CourseSetting" */ '../CourseSetting'));
const TextCourseSchedule = lazy(() => import(/* webpackChunkName: "TextCourseSchedule" */ '../TextCourseSchedule'));

const COURSE_PATH_PATTERN = `/app/${DASHBOARD_PATHNAME.websites}/:schoolId/products/:courseId`;
const DASHBOARD_PATH_PATTERN = `${COURSE_PATH_PATTERN}/${COURSE_DASHBOARD_PATHNAME.dashboard}`;
const LECTURES_PATH_PATTERN = `${COURSE_PATH_PATTERN}/${COURSE_DASHBOARD_PATHNAME.lectures}`;
const SCHEDULE_PATH_PATTERN = `${COURSE_PATH_PATTERN}/${COURSE_DASHBOARD_PATHNAME.schedule}`;
const SETTING_PATH_PATTERN = `${COURSE_PATH_PATTERN}/${COURSE_DASHBOARD_PATHNAME.setting}`;
const STUDENT_PATH_PATTERN = `${COURSE_PATH_PATTERN}/${COURSE_DASHBOARD_PATHNAME.student}`;

function CourseDashboard(props) {
    const classes = styles();
    const dispatch = useDispatch();
    const location = useLocation();
    const {
        match: {
            params: {
                schoolId,
                courseId
            }
        },
        enqueueSnackbar
    } = props;

    const SCHOOL_PATH = `/app/${DASHBOARD_PATHNAME.websites}/${schoolId}/products`;
    const COURSE_PATH = `/app/${DASHBOARD_PATHNAME.websites}/${schoolId}/products/${courseId}`;

    const course = useSelector(state => state.course.course && state.course.course[courseId]);
    const fetchIndividualCoursePending = useSelector(state => state.course.fetchIndividualCoursePending);
    const [initialCourseLoadDone, setInitialCourseLoadDone] = useState(false);
    const [initialCourseIsLoading, setInitialCourseIsLoading] = useState(false);

    const isCourseDashboardPage = useRouteMatch(DASHBOARD_PATH_PATTERN);
    const isCourseLecturesPage = useRouteMatch(LECTURES_PATH_PATTERN);
    const isCourseSchedulePage = useRouteMatch(SCHEDULE_PATH_PATTERN);
    const isCourseSettingPage = useRouteMatch(SETTING_PATH_PATTERN);
    const isStudentPage = useRouteMatch(STUDENT_PATH_PATTERN);

    useEffect(() => {
        (async function () {
            const result = await dispatch(fetchIndividualCourse({
                schoolId,
                courseId,
                withAuthor: true,
                withTotalLecture: true
            }));
            if (result.error) {
                dispatch(updateShowNoPageFoundNow({ showNoPageFoundNow: true }));
                if (result.error.message) {
                    enqueueSnackbar(result.error.message , { variant: 'error' });
                }
            }
        })();
    }, [location]); // eslint-disable-line

    useEffect(() => {
        if (fetchIndividualCoursePending && !initialCourseLoadDone) {
            // Is 1st request to fetch initial individual course
            setInitialCourseIsLoading(true);
        } else if (!fetchIndividualCoursePending && initialCourseIsLoading) {
            // 1st request to fetch initial individual course is completed
            setInitialCourseIsLoading(false);
            setInitialCourseLoadDone(true);
        }
    }, [fetchIndividualCoursePending, initialCourseIsLoading, initialCourseLoadDone]);

    function handleOpenMail() {
        window.location.href = `mailto:${SUPPORT_EMAIL}?subject=Feedback%20for%20Airteach`;
    }

    function renderBody() {
        if (!initialCourseLoadDone) {
            return null;
        }

        return (
            <>
                <Drawer
                    className='drawer'
                    variant='permanent'
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <Toolbar />
                    <div className='drawerContainer'>
                        <CourseNavAndInfo schoolPath={SCHOOL_PATH} courseId={courseId} />
                        <List className='sectionNavList'>
                            <Link className='sideBarLink' to={`${COURSE_PATH}/${COURSE_DASHBOARD_PATHNAME.dashboard}`}>
                                <ListItem className='listItem' button key='dashboard' selected={!!isCourseDashboardPage}>
                                        <ListItemIcon className='listItemIcon'>
                                            <ViewDashboard />
                                        </ListItemIcon>
                                        <ListItemText className='listItemText' primary='Dashboard' />
                                </ListItem>
                            </Link>
                            <Link className='sideBarLink' to={`${COURSE_PATH}/${COURSE_DASHBOARD_PATHNAME.lectures}`}>
                                <ListItem className='listItem' button key='lectures' selected={!!isCourseLecturesPage}>
                                    <ListItemIcon className='listItemIcon'>
                                        <NoteMultipleOutline />
                                    </ListItemIcon>
                                    <ListItemText className='listItemText' primary='Lectures' />
                                </ListItem>
                            </Link>
                            {
                                isTextCourse(course) ?
                                <Link className='sideBarLink' to={`${COURSE_PATH}/${COURSE_DASHBOARD_PATHNAME.schedule}`}>
                                    <ListItem className='listItem' button key='schedule' selected={!!isCourseSchedulePage}>
                                        <ListItemIcon className='listItemIcon'>
                                            <WaterOutline />
                                        </ListItemIcon>
                                        <ListItemText className='listItemText' primary='Schedule' />
                                    </ListItem>
                                </Link> : null
                            }
                            <Link className='sideBarLink' to={`${COURSE_PATH}/${COURSE_DASHBOARD_PATHNAME.student}`}>
                                <ListItem className='listItem' button key='setting' selected={!!isStudentPage}>
                                        <ListItemIcon className='listItemIcon'>
                                            <AccountGroup />
                                        </ListItemIcon>
                                        <ListItemText className='listItemText' primary='Student' />
                                </ListItem>
                            </Link>
                            <Link className='sideBarLink' to={`${COURSE_PATH}/${COURSE_DASHBOARD_PATHNAME.setting}`}>
                                <ListItem className='listItem' button key='setting' selected={!!isCourseSettingPage}>
                                        <ListItemIcon className='listItemIcon'>
                                            <CogOutline />
                                        </ListItemIcon>
                                        <ListItemText className='listItemText' primary='Settings' />
                                </ListItem>
                            </Link>
                            <Divider className='menuDivider' />
                            <ListItem className='listItem' onClick={handleOpenMail} button>
                                <ListItemIcon className='listItemIcon feedbackIcon'>
                                    <MessageAlertOutline />
                                </ListItemIcon>
                                <ListItemText className='listItemText' primary='Send feedback' />
                            </ListItem>
                        </List>
                    </div>
                </Drawer>
                <Grid className={classes.contentContainer}>
                    <Switch>
                        <Redirect exact from={COURSE_PATH_PATTERN} to={DASHBOARD_PATH_PATTERN} />
                        <Route exact path={`${DASHBOARD_PATH_PATTERN}`}>
                            <Suspense fallback={<FetchLoading />}>
                                <CourseOverview {...props} />
                            </Suspense>
                        </Route>
                        <Route exact path={`${LECTURES_PATH_PATTERN}`}>
                            <Suspense fallback={<FetchLoading />}>
                                <CourseChapterAndLecture {...props} />
                            </Suspense>
                        </Route>
                        <Route exact path={`${STUDENT_PATH_PATTERN}`}>
                            <Suspense fallback={<FetchLoading />}>
                                <CourseStudent {...props} />
                            </Suspense>
                        </Route>
                        <Route exact path={`${SETTING_PATH_PATTERN}`}>
                            <Suspense fallback={<FetchLoading />}>
                                <CourseSetting {...props} />
                            </Suspense>
                        </Route>
                        <Route exact path={`${SCHEDULE_PATH_PATTERN}`}>
                            <Suspense fallback={<FetchLoading />}>
                                <TextCourseSchedule {...props} />
                            </Suspense>
                        </Route>
                    </Switch>
                </Grid>
            </>
        )
    }

    return (
        <div className={classes.root}>
            <DashboardAppBar />
            {renderBody()}
        </div>
    );
}

export default withSnackbar(CourseDashboard);
