import React, { Suspense, lazy } from 'react';
import { useRouteMatch, Route, Switch } from 'react-router-dom';
import { Box } from '@material-ui/core';

import FetchLoading from '../../../../components/FetchLoading';
import styles from './SchoolOffers.styles';

const OfferDashboard = lazy(() => import(/* webpackChunkName: "OfferDashboard" */ './OfferDashboard'));
const EditOffer = lazy(() => import(/* webpackChunkName: "EditOffer" */ './EditOffer'));

export default function SchoolOffers({ schoolId }) {
    const classes = styles();
    const match = useRouteMatch();

    return (
        <Box className={classes.schoolOffersRoot}>
            <Switch>
                <Route exact path={`${match.path}`}>
                    <Suspense fallback={<FetchLoading />}>
                        <OfferDashboard schoolId={schoolId} />
                    </Suspense>
                </Route>
                <Route exact path={`${match.path}/:offerId/details/edit`}>
                    <Suspense fallback={<FetchLoading />}>
                        <EditOffer schoolId={schoolId} isDetailsPage={true} />
                    </Suspense>
                </Route>
                <Route exact path={`${match.path}/:offerId/pricing/edit`}>
                    <Suspense fallback={<FetchLoading />}>
                        <EditOffer schoolId={schoolId} isPricingPage={true} />
                    </Suspense>
                </Route>
            </Switch>
        </Box>
    )
}
