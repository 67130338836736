import { useSnackbar } from 'notistack'

let useSnackbarRef;
export default function SnackbarUtilsConfig() {
    useSnackbarRef = useSnackbar();
    return null;
}

export const SnackbarUtil = {
    enqueueMsg(msg, variant = 'default') {
        useSnackbarRef.enqueueSnackbar(msg, { variant });
    },
    success(msg) {
        this.enqueueMsg(msg, 'success')
    },
    warning(msg) {
        this.enqueueMsg(msg, 'warning')
    },
    info(msg) {
        this.enqueueMsg(msg, 'info')
    },
    error(msg) {
        this.enqueueMsg(msg, 'error')
    }
}
