import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { API_ERROR_RESPONSE } from '../../utils/constant';
import { SnackbarUtil } from '../../components/SnackbarUtilsConfig';
import {
    getCouponsAPI,
    getIndividualCouponAPI,
    createCouponAPI,
    deleteCouponAPI,
    linkCouponOfferAPI,
    unlinkCouponOfferAPI
} from '../../api/couponAPI';

const initialState = {
    coupons: [],
    coupon: null,
    couponsTotal: 0,
    couponOffers: [],

    getCouponListPending: false,
    initCouponListCompleted: false,

    getIndividualCouponPending: false,
    initIndividualCouponCompleted: false,

    deleteCouponPending: false,

    couponListPageNum: 0
};

export const getCoupons = createAsyncThunk(
    'coupon/list',
    async ({ schoolId, page, rowSize, signal }) => {
        const data = await getCouponsAPI({ schoolId, page, rowSize, signal });
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const getIndividualCoupon = createAsyncThunk(
    'coupon/individual',
    async ({ schoolId, couponId, withOffers, signal }) => {
        const data = await getIndividualCouponAPI({ schoolId, couponId, withOffers, signal });
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const createCoupon = createAsyncThunk(
    'coupon/create',
    async ({ schoolId, requestBody }) => {
        const data = await createCouponAPI({ schoolId, body: requestBody });
        if (data.status === 'fail' && data.error) {
            throw new Error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const deleteCoupon = createAsyncThunk(
    'coupon/delete',
    async ({ schoolId, couponId }) => {
        const data = await deleteCouponAPI(schoolId, couponId);
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const linkCouponOffers = createAsyncThunk(
    'coupon/link-offers',
    async ({ schoolId, couponId, offers }) => {
        const data = await linkCouponOfferAPI(schoolId, couponId, offers.map(offer => offer.id));
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const unlinkCouponOffers = createAsyncThunk(
    'coupon/unlink-offers',
    async ({ schoolId, couponId, offerIds }) => {
        const data = await unlinkCouponOfferAPI(schoolId, couponId, offerIds);
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

const couponSlice = createSlice({
    name: 'coupon',
    initialState,
    reducers: {
        resetCouponsListAction: state => {
            state.coupons = [];
            state.couponsTotal = 0;
            state.couponListPageNum = 0;
            state.initCouponListCompleted = false;
        },
        resetIndividualCouponAction: state => {
            state.coupon = null;
            state.initIndividualCouponCompleted = false;
            state.getIndividualCouponPending = false;
            state.couponOffers = [];
        }
    },
    extraReducers: {
        [getCoupons.fulfilled]: (state, action) => {
            const { page: couponListPageNum } = action.meta.arg;
            const { coupons, total, status } = action.payload;

            if (status === 'success') {
                state.coupons = coupons;
                state.couponsTotal = total;
                state.couponListPageNum = couponListPageNum;
            }

            state.getCouponListPending = false;
            state.initCouponListCompleted = true;
        },
        [getCoupons.pending]: state => {
            state.getCouponListPending = true;
        },

        [getIndividualCoupon.pending]: state => {
            state.getIndividualCouponPending = true;
        },
        [getIndividualCoupon.fulfilled]: (state, action) => {
            const { coupon, offers, status } = action.payload;

            if (status === 'success') {
                state.coupon = coupon;
                if (offers) {
                    state.couponOffers = offers;
                }
            }

            state.getIndividualCouponPending = false;
            state.initIndividualCouponCompleted = true;
        },

        [deleteCoupon.pending]: state => {
            state.deleteCouponPending = true;
        },
        [deleteCoupon.fulfilled]: state => {
            state.deleteCouponPending = false;
        },
        [linkCouponOffers.fulfilled]: (state, action) => {
            const { offers } = action.meta.arg;
            const { status } = action.payload;
            if (status === 'success') {
                state.couponOffers = [
                    ...state.couponOffers,
                    ...offers
                ];
            }
        },
        [unlinkCouponOffers.fulfilled]: (state, action) => {
            const { offerIds } = action.meta.arg;
            const { status } = action.payload;
            if (status === 'success') {
                state.couponOffers = state.couponOffers.filter(offer => !offerIds.includes(offer.id));
            }
        }
    }
});

export const { resetCouponsListAction, resetIndividualCouponAction } = couponSlice.actions;

export default couponSlice.reducer;
