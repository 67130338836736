import fetchClient from '../utils/fetchClient';

export async function fetchPaymentSetupStatusAPI(schoolId, signal) {
    let data;
    await fetchClient(`/api/schools/${schoolId}/payment-account/status`, { signal })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );
    return data;
}

export async function fetchUserSubscriptionStatusAPI() {
    let data;
    await fetchClient('/api/dashboard/subscription/user-subscription-info')
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );
    return data;
}

export async function fetchUserCardsAPI() {
    let data;
    await fetchClient('/api/dashboard/subscription/user-cards')
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );
    return data;
}

export async function cancelSubscriptionAPI(planId) {
    let data;
    await fetchClient('/api/dashboard/subscription/cancel', { body: {
        planId
    } })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );
    return data;
}

export async function rescindCancellationAPI() {
    let data;
    await fetchClient('/api/dashboard/subscription/cancel/rescind', { method: 'POST' })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );
    return data;
}

export async function removeUserCardAPI(pmId) {
    let data;
    await fetchClient('/api/dashboard/subscription/user-cards/detach', {
        body: {
            pmId,
        }
    })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );
    return data;
}

export async function updateUserCardAPI(pmId) {
    let data;
    await fetchClient('/api/dashboard/subscription/update/payment', {
        method: 'PUT',
        body: {
            pmId,
        }
    })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );
    return data;
}

export async function fetchSubscriptionHistoryAPI() {
    let data;
    await fetchClient('/api/dashboard/subscription/history')
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );
    return data;
}


export async function fetchPricingPlansAPI(planIds = [], withFeatures = false) {
    let data = {};
    let endpoint = '/api/dashboard/plans';

    let query = '';
    if (planIds.length) {
        planIds.forEach((id, i) => {
            query += `planIds[]=${id}`
            if (i !== planIds.length - 1) {
                query += '&';
            }
        });
        endpoint += `?${query}`;
    }

    if (withFeatures) {
        endpoint += planIds.length ? '&withFeatures=true' : '?withFeatures=true';
    }

    await fetchClient(endpoint)
        .then(
            resData => {
                data = resData;
            },
            errData => {
                data = errData;
            }
        );
    return data;
}

export async function resubscribeAPI(planId) {
    let data;
    await fetchClient('/api/dashboard/subscription/resubscribe',  {
        body: {
            planId
        }
    })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );
    return data;
}

export async function fetchSubscriptionStatusAPI() {
    let data;
    await fetchClient('/api/dashboard/subscription/status')
        .then(
            resData => {
                data = resData;
            },
            errData => {
                data = errData;
            }
        );
    return data;
}

export async function fetchSubscriptionProratedAmountAPI(newPlanId) {
    let data;
    await fetchClient(`/api/dashboard/subscription/prorated-amount?newPlanId=${newPlanId}`)
        .then(
            resData => {
                data = resData;
            },
            errData => {
                data = errData;
            }
        );
    return data;
}

export async function updateSubscriptionAPI(newPlanId, subscriptionUpdateType, newDashboardPlanType) {
    let data;
    await fetchClient('/api/dashboard/subscription', {
        method: 'PUT',
        body: {
            newPlanId,
            subscriptionUpdateType,
            newDashboardPlanType
        }
    })
        .then(
            resData => {
                data = resData;
            },
            errData => {
                data = errData;
            }
        );
    return data;
}

export async function fetchSubscriptionAPI() {
    let data;
    await fetchClient('/api/dashboard/subscription')
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );
    return data;
}
