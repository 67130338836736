import React, { useState, useEffect } from 'react';
import {
    Box,
    Tab,
    Tabs,
    Grid
} from '@material-ui/core';
import {
    useLocation,
    useRouteMatch,
    Link
} from 'react-router-dom';

import {
    APP_PREFIX_PATHNAME,
    ACCOUNT_PREFIX_PATHNAME,
    DASHBOARD_PATHNAME,
    BILLING_NAV_BAR_ID
} from '../../../../utils/constant';
import styles from './styles';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function BillingNavBar() {
    const classes = styles();
    const match = useRouteMatch();
    const location = useLocation();
    const [value, setValue] = useState(0);

    const isBillingPaymentPage = useRouteMatch(`/${APP_PREFIX_PATHNAME}/${ACCOUNT_PREFIX_PATHNAME}/${DASHBOARD_PATHNAME.billing}/payment`);
    const isBillingHistoryPage = useRouteMatch(`/${APP_PREFIX_PATHNAME}/${ACCOUNT_PREFIX_PATHNAME}/${DASHBOARD_PATHNAME.billing}/history`);

    useEffect(() => {
        if (isBillingPaymentPage) {
            setValue(BILLING_NAV_BAR_ID.payment);
        } else if (isBillingHistoryPage) {
            setValue(BILLING_NAV_BAR_ID.history);
        }
    }, [location]); // eslint-disable-line

    return (
        <Box className={classes.root}>
            <Grid container className={classes.appBar} position='static' elevation={0}>
                <Grid item className={classes.grow}>
                    <Tabs value={value} aria-label='billing nav bar'>
                        <Tab
                            className={isBillingPaymentPage ? 'selected' : ''}
                            to={`${match.url}/payment`}
                            component={Link}
                            label='Payment'
                            {...a11yProps(0)}
                        />
                        <Tab
                            className={isBillingHistoryPage ? 'selected' : ''}
                            to={`${match.url}/history`}
                            component={Link}
                            label='History'
                            {...a11yProps(1)}
                        />
                    </Tabs>
                </Grid>
            </Grid>
        </Box>
    );
}
