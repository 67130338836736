import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
    schoolRoot: {
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        padding: 0,
    },

    routeContentContainer: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        paddingTop: theme.spacing(8),
        overflow: 'hidden',
        zIndex: 100,
    },

    schoolCard: {
        width: '265px',
        height: '100%',
        border: '1px solid #dadce0',
        boxShadow: 'none',

        '& .MuiCardMedia-root': {
            height: '118px',
            backgroundSize: 'contain',
        },

        '& .MuiCardContent-root': {
            padding: '13px 16px',
        },

        '& .info': {
            borderTop: '1px solid rgba(0, 0, 0, 0.12)',

            '& .school-name, & .description': {
                width: '100%',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                lineHeight: '20px',
                fontWeight: '500',
            },

            '& .school-name': {
                fontSize: '13px',
                color: 'rgba(0,0,0,.72)',
            },

            '& .description': {
                fontSize: '12px',
                color: 'rgba(0,0,0,.54)',
            },
        },
    }
}));
