import React, { Suspense, lazy } from 'react';
import { useRouteMatch, Route, Switch } from 'react-router-dom';
import { Box } from '@material-ui/core';

import FetchLoading from '../../../../components/FetchLoading';
import styles from './SchoolAnalytics.styles';

const SchoolAnalyticsDashboard = lazy(() => import(/* webpackChunkName: "SchoolAnalyticsDashboard" */ './SchoolAnalyticsDashboard'));
const SchoolOfferAnalytics = lazy(() => import(/* webpackChunkName: "SchoolOfferAnalytics" */ './SchoolOfferAnalytics'));
const SchoolProductAnalytics = lazy(() => import(/* webpackChunkName: "SchoolProductAnalytics" */ './SchoolProductAnalytics'));

export default function SchoolAnalytics({ schoolId }) {
    const classes = styles();
    const match = useRouteMatch();

    return (
        <Box className={classes.schoolAnalyticsRoot}>
            <Switch>
                <Route exact path={`${match.path}`}>
                    <Suspense fallback={<FetchLoading />}>
                        <SchoolAnalyticsDashboard schoolId={schoolId} />
                    </Suspense>
                </Route>
                <Route exact path={`${match.path}/offers/:offerId`}>
                    <Suspense fallback={<FetchLoading />}>
                        <SchoolOfferAnalytics schoolId={schoolId} />
                    </Suspense>
                </Route>
                <Route exact path={`${match.path}/:productType/:productid`}>
                    <Suspense fallback={<FetchLoading />}>
                        <SchoolProductAnalytics schoolId={schoolId} />
                    </Suspense>
                </Route>
            </Switch>
        </Box>
    );
}
