import { makeStyles } from "@material-ui/styles";
import {
    grey
} from '@material-ui/core/colors';

export default makeStyles((theme) => ({
    root: {
        '& .backButtonContainer': {
            '& .listItemIcon': {
                width: '40px',
                minWidth: '40px',

                '& .MuiSvgIcon-root': {
                    width: '20px',
                    height: '20px',
                    color: grey[600],
                },
            },

            '& .listItemLabelText': {
                position: 'relative',
                top: '1px',
                margin: 0,

                '& .MuiTypography-root': {
                    color: grey[700],
                    fontWeight: 500,
                    fontSize: '14px',
                },
            },
        },

        '& .courseLogoContainer': {
            margin: theme.spacing(0, .75),

            '& .media': {
                height: '120px',
            },
        },

        '& .courseTitle': {
            padding: theme.spacing(0, 3),
            margin: theme.spacing(1.25, 0, 2),
            textAlign: 'center',
            fontSize: '14px',

            '& .label': {
                fontWeight: 500,
                color: grey[900],
                marginBottom: theme.spacing(.25),
            },

            '& .name': {
                color: grey[800],
            },
        },
    },
}));
