import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import { SnackbarUtil } from '../../components/SnackbarUtilsConfig';
import {
    fetchTextLecturesAPI,
    updateTextLecturesAPI,
    createTextLectureAPI,
    fetchIndividualTextLectureAPI,
    fetchTextLectureItemsAPI,
    createTextLectureItemAPI,
    updateTextLectureItemAPI,
    deleteTextLectureAPI,
    deleteTextLectureItemAPI
} from '../../api/textFlowAPI';
import { insertNewItem, updatePositionItem } from '../../utils/helper';
import { API_ERROR_RESPONSE } from '../../utils/constant';

const initialState = {
    textLectures: {},
    fetchTextLecturesPending: false,
    fetchTextLecturesError: '',
    currentTextLecture: {},
    
    textLectureItems: {},
    fetchTextLectureItemsPending: false,
    fetchTextLectureItemsError: '',

    createLecturePending: false,

    currentContentState: {}
};

export const addLecture = createAction('textLectures/add');
export const updateLecture = createAction('textLectures/updateStore');
export const addCurrentTextLecture = createAction('textLectures/addCurrentTextLecture');
export const addTextLectureItem = createAction('textLectureItems/add');
export const updateLectureItem = createAction('textLectureItems/updateStore');

export const createLecture = createAsyncThunk(
    'textLecture/create',
    async ({ schoolId, courseId, lec, position }, { dispatch }) => {
        dispatch(addLecture({ courseId, lec, position }));
        const data = await createTextLectureAPI(schoolId, courseId, { title: lec.title, position });
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const createTextLectureItem = createAsyncThunk(
    'textLectureItem/create',
    async ({ schoolId, courseId, textLectureId, item, position }, { dispatch }) => {
        dispatch(addTextLectureItem({ textLectureId, item, position }));
        const data = await createTextLectureItemAPI(schoolId, courseId, textLectureId, { ...item, position });
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const fetchTextLectures = createAsyncThunk(
    'textLectures/fetch',
    async ({ schoolId, courseId }) => {
        const data = await fetchTextLecturesAPI(schoolId, courseId);
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const fetchTextLectureItems = createAsyncThunk(
    'textLecture/fetchTextLectureItems',
    async ({ schoolId, courseId, textLectureId }) => {
        const data = await fetchTextLectureItemsAPI(schoolId, courseId, textLectureId);
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);
    
export const fetchIndividualTextLecture = createAsyncThunk(
    'textLecture/fetchIndividual',
    async ({ schoolId, courseId, textLectureId }) => {
        const data = await fetchIndividualTextLectureAPI(schoolId, courseId, textLectureId);
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const updateTextLectures = createAsyncThunk(
    'textLectures/update',
    async ({ schoolId, courseId, textLectureId, elemId, payload }, { dispatch }) => {
        dispatch(updateLecture({ courseId, textLectureId, elemId, payload }));
        const data = await updateTextLecturesAPI(schoolId, courseId, textLectureId, payload);
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const deleteTextLecture = createAsyncThunk(
    'textLectures/delete',
    async ({ schoolId, courseId, textLectureId, elemId }, { dispatch }) => {
        dispatch(updateLecture({ courseId, textLectureId, elemId, payload: { is_deleted: true } }));
        const data = await deleteTextLectureAPI(schoolId, courseId, textLectureId);
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const updateTextLectureItems = createAsyncThunk(
    'textLectureItem/update',
    async ({ schoolId, courseId, textLectureId, textLectureItemId, elemId, payload }, { dispatch }) => {
        dispatch(updateLectureItem({ textLectureId, textLectureItemId, elemId, payload }));
        const data = await updateTextLectureItemAPI(schoolId, courseId, textLectureId, textLectureItemId, payload);
        return data;
    }
);

export const deleteTextLectureItem = createAsyncThunk(
    'textLectureItem/delete',
    async ({ schoolId, courseId, textLectureId, textLectureItemId, elemId }, { dispatch }) => {
        dispatch(updateLectureItem({ courseId, textLectureId, elemId, payload: { is_deleted: true } }));
        const data = await deleteTextLectureItemAPI(schoolId, courseId, textLectureId, textLectureItemId);
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

const textFlowSlice = createSlice({
    name: 'textFlow',
    initialState,
    reducers: {
        updateCurrContentState: (state, action) => {
            const { payload } = action;
            if (!Object.keys(payload).length) {
                state.currentContentState = {};
                return;
            }
            state.currentContentState = payload;
        }
    },
    extraReducers: {
        // sync
        [addLecture]: (state, action) => {
            const { courseId, lec, position } = action.payload;
            const textLectures = state.textLectures[courseId];
            state.textLectures[courseId] = insertNewItem(textLectures, lec, position);
        },
        [addTextLectureItem]: (state, action) => {
            const { textLectureId, item, position } = action.payload;
            const textLectureItems = state.textLectureItems[textLectureId];
            state.textLectureItems[textLectureId] = insertNewItem(textLectureItems, item, position);
        },
        [updateLecture]: (state, action) => {
            const { courseId, payload, elemId, textLectureId } = action.payload;
            const textLectures = state.textLectures[courseId];
            if (payload.hasOwnProperty('is_deleted')) {
                state.textLectures[courseId] = textLectures.filter(lec => lec.elemId !== elemId);
            } else if (payload.hasOwnProperty('position')) {
                const [sourceIndex, destIndex] = payload.position;
                state.textLectures[courseId] = updatePositionItem(textLectures, { ...textLectures[sourceIndex] }, sourceIndex, destIndex)
            } else {
                // update the textLecture array
                if (textLectures) {
                    textLectures.forEach(e => {
                        if (e.elemId === elemId || e.id === textLectureId) {
                            Object.keys(payload).forEach(key => {
                                e[key] = payload[key]
                            });
                        }
                    });
                }
                // if currentTextLecture has it, we also update it here too
                if (state.currentTextLecture[textLectureId]) {
                    state.currentTextLecture[textLectureId] = {
                        ...state.currentTextLecture[textLectureId],
                        ...payload
                    }
                }
            }
        },
        [updateLectureItem]: (state, action) => {
            const { textLectureId, textLectureItemId, elemId, payload } = action.payload;
            const textLectureItems = state.textLectureItems[textLectureId];
            if (payload.hasOwnProperty('is_deleted')) {
                state.textLectureItems[textLectureId] = textLectureItems.filter(lec => lec.elemId !== elemId);
            } else if (payload.hasOwnProperty('position')) {
                const [sourceIndex, destIndex] = payload.position;
                state.textLectureItems[textLectureId] = updatePositionItem(textLectureItems, { ...textLectureItems[sourceIndex] }, sourceIndex, destIndex)
            } else {
                // update the textLecture array
                if (textLectureItems) {
                    textLectureItems.forEach(e => {
                        if (e.elemId === elemId || e.id === textLectureItemId) {
                            Object.keys(payload).forEach(key => {
                                e[key] = payload[key]
                            });
                        }
                    });
                }
            }
        },
        [addCurrentTextLecture]: (state, action) => {
            const { lecture } = action.payload;
            if (!state.currentTextLecture[lecture.id]) {
                state.currentTextLecture[lecture.id] = lecture;
            }
        },

        // async
        [createLecture.pending]: state => {
            state.createLecturePending = true;
        },
        [createLecture.fulfilled]: (state, action) => {
            state.createLecturePending = false;
            const {
                payload: { status, textLecture },
                meta: {
                    arg: { courseId, lec }
                }
            } = action;
            if (status === 'success') {
                const textLectures = state.textLectures[courseId];
                textLectures.forEach((ele, index) => {
                    if (ele.elemId === lec.elemId) {
                        textLectures[index] = {
                            ...ele,
                            ...textLecture,
                            elemId: lec.elemId
                        };
                    }
                });
            }
        },
        [createLecture.rejected]: state => {
            state.createLecturePending = false;
        },
        [fetchTextLectures.pending]: state => {
            state.fetchTextLecturesError = '';
            state.fetchTextLecturesPending = true;
        },
        [fetchTextLectures.fulfilled]: (state, action) => {
            const {
                payload: { textLectures, status, error },
                meta: {
                    arg: { courseId }
                }
            } = action;
            if (status === 'success') {
                state.textLectures[courseId] = textLectures.map(lec => ({
                    ...lec,
                    elemId: uuid()
                }));
            } else {
                state.fetchTextLecturesError = error ? error.message : API_ERROR_RESPONSE;
            }
            state.fetchTextLecturesPending = false;
        },
        [fetchIndividualTextLecture.fulfilled]: (state, action) => {
            const {
                payload: { textLecture, status },
                meta: {
                    arg: { textLectureId }
                }
            } = action;
            if (status === 'success') {
                state.currentTextLecture[textLectureId] = {
                    ...textLecture,
                    elemId: uuid()
                };
            }
        },
        [fetchTextLectureItems.pending]: state => {
            state.fetchTextLectureItemsPending = true;
            state.fetchTextLectureItemsError = '';
        },
        [fetchTextLectureItems.fulfilled]: (state, action) => {
            const {
                payload: { textLectureItems, status, error },
                meta: {
                    arg: { textLectureId }
                }
            } = action;
            if (status === 'success') {
                state.textLectureItems[textLectureId] = textLectureItems.map(item => ({
                    ...item,
                    elemId: uuid()
                }));
            } else {
                state.fetchTextLectureItemsError = error ? error.message : API_ERROR_RESPONSE;
            }
            state.fetchTextLectureItemsPending = false;
        },
        [createTextLectureItem.fulfilled]: (state, action) => {
            const {
                payload: { status, textLectureItem },
                meta: {
                    arg: { textLectureId, item }
                }
            } = action;
            if (status === 'success') {
                const textLectureItems = state.textLectureItems[textLectureId];
                textLectureItems.forEach((ele, index) => {
                    if (ele.elemId === item.elemId) {
                        textLectureItems[index] = {
                            ...ele,
                            ...textLectureItem,
                            elemId: item.elemId
                        };
                    }
                });
            }
        }
    }
});

export const { updateCurrContentState } = textFlowSlice.actions;

export default textFlowSlice.reducer;
