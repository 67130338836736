import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { SnackbarUtil } from '../../components/SnackbarUtilsConfig';
import { API_ERROR_RESPONSE } from '../../utils/constant';
import {
    getStudentUsersAPI,
    createStudentUsersAPI,
    grantStudentUserOffersAPI,
    getStudentUserByIdAPI,
    updateStudentUsersAPI,
    deleteStudentUserAPI
} from '../../api/studentUserAPI';

const initialState = {
    studentUsers: [],
    studentOffers: [],
    studentUser: null,
    studentUsersTotal: 0,
    getStudentUserPending: false,
    getStudentUsersPending: false,
    createStudentUserPending: false,
    grantStudentUserOfferPending: false,
    initStudentUsersCompleted: false,
    initStudentUserCompleted: false,

    studentUserListPageNum: 0
};

export const getStudentUsers = createAsyncThunk(
    'studentUser/get',
    async ({ schoolId, page, rowSize, signal }) => {
        const data = await getStudentUsersAPI({ schoolId, page, rowSize, signal });
        if (data instanceof DOMException) {
            throw data;
        }
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const getStudentUserById = createAsyncThunk(
    'studentUser/get-by-id',
    async ({ schoolId, studentUserId, includeOffers }) => {
        const data = await getStudentUserByIdAPI({ schoolId, studentUserId, includeOffers });
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const createStudentUsers = createAsyncThunk(
    'studentUser/create',
    async ({ schoolId, body }) => {
        const response = await createStudentUsersAPI({ schoolId, body });
        if (response.status === 'fail' && response.error) {
            throw new Error(response.error.message || API_ERROR_RESPONSE);
        }
        return response;
    }
);

export const grantStudentUserOffers = createAsyncThunk(
    'studentUser/grant-offers',
    async ({ schoolId, body }) => {
        const response = await grantStudentUserOffersAPI({ schoolId, body });
        if (response.status === 'fail' && response.error) {
            throw new Error(response.error.message || API_ERROR_RESPONSE);
        }
        return response;
    }
);

export const updateStudentUser = createAsyncThunk(
    'studentUser/update',
    async ({ schoolId, studentUserId, body }) => {
        const response = await updateStudentUsersAPI({ schoolId, studentUserId, body });
        if (response.status === 'fail' && response.error) {
            throw new Error(response.error.message || API_ERROR_RESPONSE);
        }
        return response;
    }
);

export const deleteStudentUser = createAsyncThunk(
    'studentUser/delete',
    async ({ schoolId, studentUserId, body }) => {
        const response = await deleteStudentUserAPI({ schoolId, studentUserId, body });
        if (response.status === 'fail' && response.error) {
            throw new Error(response.error.message || API_ERROR_RESPONSE);
        }
        return response;
    }
);

const studentUserSlice = createSlice({
    name: 'studentUser',
    initialState,
    reducers: {
        resetStudentUsersListAction: state => {
            state.studentUsers = [];
            state.studentUsersTotal = 0;
            state.studentUserListPageNum = 0;
            state.initStudentUsersCompleted = false;
        },
        resetStudentUserDetailsAction: state => {
            state.studentUser = null;
            state.studentOffers = [];
            state.initStudentUserCompleted = false;
        }
    },
    extraReducers: {
        [getStudentUsers.fulfilled]: (state, action) => {
            const { page: studentUserListPageNum } = action.meta.arg;
            const { studentUsers, total, status } = action.payload;

            if (status === 'success') {
                state.studentUsers = studentUsers;
                state.studentUsersTotal = total;
                state.studentUserListPageNum = studentUserListPageNum;
            }

            state.getStudentUsersPending = false;
            state.initStudentUsersCompleted = true;
        },
        [getStudentUsers.pending]: state => {
            state.getStudentUsersPending = true;
        },
        [getStudentUserById.fulfilled]: (state, action) => {
            const { studentUser, status } = action.payload;

            if (status === 'success') {
                const { offerList: studentOffers, ...rest } = studentUser
                state.studentUser = rest;
                state.studentOffers = studentOffers || [];
            }

            state.getStudentUsersPending = false;
            state.initStudentUserCompleted = true;
        },
        [getStudentUserById.pending]: state => {
            state.getStudentUserPending = true;
        },
        [getStudentUserById.rejected]: state => {
            state.getStudentUserPending = false;
            state.initStudentUserCompleted = true;
        },
        [createStudentUsers.fulfilled]: (state, action) => {
            const { studentUser, status } = action.payload;

            if (status === 'success' && state.studentUserListPageNum === 0) {
                state.studentUsers = [
                    studentUser,
                    ...state.studentUsers
                ];
            }
            state.createStudentUserPending = false;
        },
        [createStudentUsers.pending]: state => {
            state.createStudentUserPending = true;
        },
        [createStudentUsers.rejected]: state => {
            state.createStudentUserPending = false;
        },
        [grantStudentUserOffers.fulfilled]: (state, action) => {
            const { type } = action.meta.arg;
            const { offerList: studentOffers, studentUser, status } = action.payload;

            if (status === 'success') {
                if (type === 'studentDetail') {
                    state.studentUser = studentUser;
                } else if (type === 'studentList' && state.studentUsers.length) {
                    const newStudentUsers = [...state.studentUsers];
                    const studentUserIndex = newStudentUsers.findIndex(user => user.id === studentUser.id);
                    if (studentUserIndex > -1) {
                        newStudentUsers[studentUserIndex] = studentUser;
                        state.studentUsers = newStudentUsers;
                    }
                }
                if (studentOffers && studentOffers.length) {
                    state.studentOffers = studentOffers;
                }
            }
            state.grantStudentUserOfferPending = false;
        },
        [grantStudentUserOffers.pending]: state => {
            state.grantStudentUserOfferPending = true;
        },
        [grantStudentUserOffers.rejected]: state => {
            state.grantStudentUserOfferPending = false;
        },
        [updateStudentUser.fulfilled]: (state, action) => {
            const { studentUser, status } = action.payload;
    
            if (status === 'success' && studentUser) {
                state.studentUser = studentUser;
            }
            state.updateStudentUserPending = false;
        },
        [updateStudentUser.rejected]: state => {
            state.updateStudentUserPending = false;
        }
    }
});

export const { resetStudentUsersListAction, resetStudentUserDetailsAction } = studentUserSlice.actions;

export default studentUserSlice.reducer;
