import fetchClient from '../utils/fetchClient';

const URL = '/api/schools';

export async function createSchoolAPI(data) {
    let returnData = {};

    await fetchClient(
        URL,
        {
            method: 'POST',
            body: data
        })
        .then(
            // success handler
            resData => {
                returnData = resData;
            },
            // error handler
            errData => {
                returnData = errData;
            }
        );

    return returnData;
}

export async function fetchSchoolList() {
    let returnData = {};

    await fetchClient(URL)
        .then(
            // success handler
            resData => {
                returnData = resData;
            },
            // error handler
            errData => {
                returnData = errData;
            }
        );

    return returnData;
}

export async function fetchSchool(schoolId, withPreview, withProducts) {
    let url = `${URL}/${schoolId}?withPreview=${withPreview}`;
    let returnData = {};

    if (withProducts) {
        url = `${url}&withProducts=${withProducts}`
    }

    await fetchClient(url)
        .then(
            // success handler
            resData => {
                returnData = resData;
            },
            // error handler
            errData => {
                returnData = errData;
            }
        );

    return returnData;
}

export async function updateSchoolAPI(data) {
    const url = `${URL}/${data.id}`;
    const payload = {};
    let returnData = {};

    if (data.hasOwnProperty("name")) {
        payload.name = data.name;
    }

    if (data.hasOwnProperty("subdomain")) {
        payload.subdomain = data.subdomain;
    }

    if (data.hasOwnProperty("description")) {
        payload.description = data.description;
    }

    if (data.hasOwnProperty('bannerImageUrl')) {
        payload.bannerImageUrl = data.bannerImageUrl;
    }

    if (data.hasOwnProperty('bannerFilestackHandle')) {
        payload.bannerFilestackHandle = data.bannerFilestackHandle;
    }

    if (data.hasOwnProperty('logoImageUrl')) {
        payload.logoImageUrl = data.logoImageUrl;
    }

    if (data.hasOwnProperty('logoFilestackHandle')) {
        payload.logoFilestackHandle = data.logoFilestackHandle;
    }

    if (data.hasOwnProperty('email')) {
        payload.email = data.email;
    }

    if (data.hasOwnProperty('phoneNumber')) {
        payload.phoneNumber = data.phoneNumber;
    }

    await fetchClient(
        url,
        {
            method: 'PUT',
            body: payload
        })
        .then(
            // success handler
            resData => {
                returnData = resData;
            },
            // error handler
            errData => {
                returnData = errData;
            }
        );

    return returnData;
}

export async function deleteSchoolAPI(schoolId) {
    const url = `${URL}/${schoolId}`;
    let data = {};
    await fetchClient(
        url,
        {
            method: 'DELETE'
        }
    ).then(
        resData => {
            data = resData;
        },
        errData => {
            data = errData;
        }
    );
    return data;
}
