import { makeStyles } from '@material-ui/styles';
import {
    grey
} from '@material-ui/core/colors';

export default makeStyles(theme => ({
    root: {
        display: 'flex',
        flex: 1,
        width: '100%',

        '& .drawer': {
            position: 'absolute',
            width: theme.spacing(30),
            height: '100%',
            flexShrink: 0,
            zIndex: 0,

            '& .drawerContainer': {
                overflow: 'auto',
                border: 'none',

                '& .sideBarLink': {
                    textDecoration: 'none',
                    display: 'flex',
                    alignItems: 'center',

                    '&:active, &:focus, &:focus-within, &:target, &:hover, &:visited, &:focus-visible': {
                        color: 'grey',
                    },
                },

                '& .sectionNavList': {
                    paddingTop: theme.spacing(.25),

                    '& .menuDivider': {
                        margin: theme.spacing(1, 0),
                    },

                    '& .listItem': {
                        height: '40px',
                        'padding-left': '24px',
                        'padding-right': '24px',


                        '&.Mui-selected': {
                            backgroundColor: theme.palette.selectedNavItemBackground,

                            '& .listItemIcon, & .listItemText .MuiTypography-root': {
                                color: theme.palette.selectedNavItem,
                            },
                        },

                        '& .listItemIcon': {
                            minWidth: '24px',
                            marginRight: theme.spacing(2),
                            color: grey[700],
                            fontWeight: 600,

                            '& .MuiSvgIcon-root': {
                                width: '20px',
                                height: '20px',
                            },

                            '&.feedbackIcon': {
                                position: 'relative',
                                top: '1px',
                            },
                        },

                        '& .listItemText': {
                            height: '24px',

                            '& .MuiTypography-root': {
                                color: grey[700],
                                overflow: 'hidden',
                                'white-space': 'nowrap',
                                'text-overflow': 'ellipsis',
                                fontSize: '14px',
                                'font-weight': '400',
                                'line-height': '24px',
                                fontWeight: 600,
                            }
                        }
                    }
                },

            },
        },
    },

    contentContainer: {
        width: 'calc(100% - 240px)',
        marginTop: theme.spacing(8),
        marginLeft: theme.spacing(30),
        backgroundColor: theme.palette.common.white,
        overflow: 'hidden',
    },

    paper: {
        border: '1px solid #d3d4d5'
    },

    formControl: {
        margin: theme.spacing(1),
        minWidth: 220,
        
        '& .MuiSelect-root': {
            'background-color': 'transparent',
        },
    },
    textUpdate: {
        marginLeft: '23px',
        width:  '560px',
        'background-color': '#eceff1',

        '& .MuiInputBase-root': {
            'padding': '12px 16px',
        },
    },

    drawerPaper: {
        width: theme.spacing(30)
    },

    sideBarLink: {
        textDecoration: 'none',
        display: 'flex',
        alignItems: 'center',

        '&:active, &:focus, &:focus-within, &:target, &:hover, &:visited, &:focus-visible': {
            color: 'grey',
        },
    }
}));
