import { makeStyles } from '@material-ui/styles';

import {
    DASHBOARD_NAV_DRAWER_WIDTH,
    APP_TOOL_BAR_HEIGHT_MULTIPLIER
} from '../../utils/constant';

export default makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',

        '& .dashboardContentContainer': {
            marginLeft: theme.spacing(32),
            backgroundColor: theme.palette.common.white,
            position: 'absolute',
            width: `calc(100% - ${DASHBOARD_NAV_DRAWER_WIDTH}px)`,
            height: '100%',
            paddingTop: theme.spacing(APP_TOOL_BAR_HEIGHT_MULTIPLIER),
        }
    }
}));
