import React, { Suspense, lazy, useState, useEffect } from 'react';
import {
    Switch,
    Route,
    useLocation,
    useRouteMatch,
    Redirect,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    Box
} from '@material-ui/core';

import DashboardAppBar from '../../components/DashboardAppBar';
import FetchLoading from '../../components/FetchLoading';
import DashboardNavSection from './DashboardNavSection/DashboardNavSection';
import School from './School/School';
import { DASHBOARD_SECTION_ID, DASHBOARD_PATHNAME } from '../../utils/constant';

import styles from './Dashboard.styles';

const Setting = lazy(() => import(/* webpackChunkName: "UserSetting" */ './Setting'));

export default function Dashboard() {
    const classes = styles();
    const user = useSelector(state => state.user);

    // state
    const [currentSectionId, setCurrentSectionId] = useState(DASHBOARD_SECTION_ID.none);

    const location = useLocation();
    const isWebsitePage = useRouteMatch(`/app/${DASHBOARD_PATHNAME.websites}/:schoolId`);
    const isSettingsPage = useRouteMatch(`/app/${DASHBOARD_PATHNAME.settings}`);
    const isSiteHomePage = useRouteMatch(`/app/sites/:schoolId/${DASHBOARD_PATHNAME.siteHome}`);
    const isSiteProductsPage = useRouteMatch(`/app/sites/:schoolId/${DASHBOARD_PATHNAME.siteProducts}`);
    const isSiteSettingsPage = useRouteMatch(`/app/sites/:schoolId/${DASHBOARD_PATHNAME.siteSettings}`);
    const isSiteOffersPage = useRouteMatch(`/app/sites/:schoolId/${DASHBOARD_PATHNAME.siteOffers}`);
    const isSitePaymentPage = useRouteMatch(`/app/sites/:schoolId/${DASHBOARD_PATHNAME.sitePayment}`);
    const isSiteDomainPage = useRouteMatch(`/app/sites/:schoolId/${DASHBOARD_PATHNAME.siteDomain}`);
    const isSiteContactsPage = useRouteMatch(`/app/sites/:schoolId/${DASHBOARD_PATHNAME.siteContacts}`);
    const isSiteAnalyticsPage = useRouteMatch(`/app/sites/:schoolId/${DASHBOARD_PATHNAME.siteAnalytics}`);
    const isSiteCouponsPage = useRouteMatch(`/app/sites/:schoolId/${DASHBOARD_PATHNAME.siteCoupons}`);
    const match = useRouteMatch();

    useEffect(() => {
        // order does matter
        if (isSiteHomePage) {
            setCurrentSectionId(DASHBOARD_SECTION_ID.siteHome);
        } else if (isSiteProductsPage) {
            setCurrentSectionId(DASHBOARD_SECTION_ID.siteProducts);
        } else if (isSiteSettingsPage) {
            setCurrentSectionId(DASHBOARD_SECTION_ID.siteSettings);
        } else if (isSiteOffersPage) {
            setCurrentSectionId(DASHBOARD_SECTION_ID.siteOffers);
        } else if (isSitePaymentPage) {
            setCurrentSectionId(DASHBOARD_SECTION_ID.sitePayment);
        } else if (isSiteDomainPage) {
            setCurrentSectionId(DASHBOARD_SECTION_ID.siteDomain);
        } else if (isSiteContactsPage) {
            setCurrentSectionId(DASHBOARD_SECTION_ID.siteContacts);
        } else if (isSiteAnalyticsPage) {
            setCurrentSectionId(DASHBOARD_SECTION_ID.siteAnalytics);
        } else if (isSiteCouponsPage) {
            setCurrentSectionId(DASHBOARD_SECTION_ID.siteCoupons);
        } else if (isWebsitePage) {
            setCurrentSectionId(DASHBOARD_SECTION_ID.websites);
        } else if (isSettingsPage) {
            setCurrentSectionId(DASHBOARD_SECTION_ID.settings);
        // } else if (isWebsitesPage) {
        //     // this goes last
        //     setCurrentSectionId(DASHBOARD_SECTION_ID.websites);
        } else {
            // default to school page
            setCurrentSectionId(DASHBOARD_SECTION_ID.siteHome);
        }
    }, [location]); // eslint-disable-line

    return (
        <Box className={classes.root}>
            <DashboardAppBar />
            <DashboardNavSection sectionId={currentSectionId} />
            <Box className='dashboardContentContainer'>
                <Switch>
                    <Route path={`${match.path}/${DASHBOARD_PATHNAME.websites}/:schoolId`}>
                        <School />
                    </Route>
                    <Route path={`${match.path}/${DASHBOARD_PATHNAME.settings}`}>
                        <Suspense fallback={<FetchLoading />}>
                            <Setting user={user} />
                        </Suspense>
                    </Route>
                    <Redirect to={`${match.path}/${DASHBOARD_PATHNAME.websites}`} />
                </Switch>
            </Box>
        </Box>
    )
}
