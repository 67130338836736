import React, { Suspense, lazy } from 'react';
import { useRouteMatch, Route, Switch } from "react-router-dom";
import { Box } from '@material-ui/core';

import FetchLoading from '../../../../components/FetchLoading';
import styles from './SchoolStudentUsers.styles';

const StudentUserDashboard = lazy(() => import(/* webpackChunkName: "StudentUserDashboard" */ './StudentUserDashboard'));
const StudentUserDetails = lazy(() => import(/* webpackChunkName: "StudentUserDetails" */ './StudentUserDetails'));
const StudentUserEdit = lazy(() => import(/* webpackChunkName: "StudentUserEdit" */ './StudentUserEdit'));

export default function SchoolStudentUsers({ schoolId }) {
    const classes = styles();
    const match = useRouteMatch();

    return (
        <Box className={classes.schoolContactsRoot}>
            <Switch>
                <Route exact path={`${match.path}`}>
                    <Suspense fallback={<FetchLoading />}>
                        <StudentUserDashboard schoolId={schoolId} />
                    </Suspense>
                </Route>
                <Route exact path={`${match.path}/:studentUserId/details`}>
                    <Suspense fallback={<FetchLoading />}>
                        <StudentUserDetails schoolId={schoolId} />
                    </Suspense>
                </Route>
                <Route exact path={`${match.path}/:studentUserId/edit`}>
                    <Suspense fallback={<FetchLoading />}>
                        <StudentUserEdit schoolId={schoolId} />
                    </Suspense>
                </Route>
            </Switch>
        </Box>
    );
}
