import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    error: {
        backgroundColor: theme.palette.error.main
    },
    icon: {
        fontSize: 20
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1)
    },
    message: {
        display: "flex",
        alignItems: "center"
    }
}));
