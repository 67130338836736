import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core/styles";
import CssBaseline from '@material-ui/core/CssBaseline';
import {
    blue, red, brown, green, grey, yellow
} from '@material-ui/core/colors';

import './index.css';

import store from './store';
import Routes from './routes';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

export const theme = createTheme({
    typography: {
        useNextVariants: true,
        button: {
            textTransform: 'none'
        }
    },
    palette: {
        logoColor: '#ff3c00',
        // primary color is the brand color
        primary: {
            light: blue[100],
            main: blue[600],
            dark: blue[700],
        },
        secondary: {
            light: brown[50],
            main: brown[800],
            dark: brown[900],
        },
        error: {
            light: red[50],
            main: red[800],
            dark: red[900],
        },
        warning: {
            light: yellow[50],
            main: yellow[800],
            dark: yellow[900],
        },
        success: {
            light: green[50],
            main: green[800],
        },
        questionInput: blue[800],
        dashboardBackground: grey[200],
        selectedNavItem: '#1967d3',
        selectedNavItemBackground: '#e8f0ff',
        checkCircleIconColor: green[500],
    },
    appBarHeight: '64px',
    dragAndDrop: {
        // CAUTION: this is used in multiple components. Test all the components
        // after making a change.
        isDragging: {
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
            border: '3px solid #9fbeff',
        },
    },
    instructionsRendering: {
        '& p': {
            fontSize: '1rem',
            marginTop: 0,
            whiteSpace: 'pre-wrap',
            wordWrap: 'break-word',
            wordBreak: 'normal',
        },

        '& .hljs': {
            borderRadius: '4px',
        },

        '& blockquote': {
            borderLeft: '.25em solid #d2d2d2',
            marginLeft: '0px',
            paddingLeft: '24px',
            color: grey[500]
        }
    },
});

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <CssBaseline />
            <Router>
                <Routes />
            </Router>
        </Provider>
    </ThemeProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
