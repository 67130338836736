import fetchClient from '../utils/fetchClient';

export async function fetchCourseChaptersAPI(schoolId, courseId, withPreview) {
    const url = `/api/schools/${schoolId}/courses/${courseId}/chapters?withPreview=${withPreview}`;
    let data = {};

    await fetchClient(url)
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}

export async function createCourseChapterAPI({
    schoolId,
    courseId,
    elemId,
    name,
    position
}) {
    const url = `/api/schools/${schoolId}/courses/${courseId}/chapters`;
    let data = {};

    await fetchClient(
        url,
        {
            body: {
                elemId,
                name,
                position
            }
        })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}

// TODO: Update this
export async function updateCourseChapterAPI(schoolId, courseId, courseChapter) {
    const url = `/api/schools/${schoolId}/courses/${courseId}/chapters/${courseChapter.id}`;
    let data = {};

    await fetchClient(
        url,
        {
            method: 'PUT',
            body: {
                courseChapter
            }
        })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}

export async function deleteCourseChapterAPI(schoolId, courseId, chapterId) {
    const url = `/api/schools/${schoolId}/courses/${courseId}/chapters/${chapterId}`;
    let data = {};

    await fetchClient(
        url,
        {
            method: 'DELETE'
        })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}
