import React from 'react';
import styles from './NoPageFound.styles';
import {
    Box,
    Typography,
    Avatar,
    Button
} from '@material-ui/core';
import DashboardAppBar from '../DashboardAppBar';

export default function NoPageFound() {
    const classes = styles();

    return (
        <Box className={classes.root} >
            <DashboardAppBar />
            <Box className='noPageFoundWrapper' >
                <Box className='noPageFoundContainer'>
                    <Avatar
                        className='brokenLinkImageContainer'
                        alt='Broken link'
                        src='https://airteach-asset.s3.us-west-1.amazonaws.com/link_broken.png'
                    />
                    <Typography className='title' variant='h4' align='center' gutterBottom={true}>This Page Isn't Available</Typography>
                    <Typography className='description' variant='subtitle1' align='center'>The link may be broken, or the page may have been removed.</Typography>
                    <Typography className='description' variant='subtitle1' align='center'>Check to see if the link you're trying to open is correct.</Typography>
                    <Button className='dashboardButton' variant='contained' href='/' color='primary' size='large'>
                        Go to Dashboard
                    </Button>
                </Box>
            </Box>
        </Box>
    );
}
