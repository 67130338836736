import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';

import { SnackbarUtil } from '../../components/SnackbarUtilsConfig';
import { API_ERROR_RESPONSE } from '../../utils/constant';
import { updateIndividualOfferStore } from '../../utils/helper';
import {
    getOffersAPI,
    getOfferAPI,
    createOfferAPI,
    fetchOfferOverviewAnalyticsAPI,
    updateOfferAPI,
    deleteOfferAPI,
    getSelectableProducts,
}  from '../../api/offerAPI';
import {
    deleteOfferCourseAPI,
    createOfferCourseAPI
}  from '../../api/offerCourseAPI';
import { updateShowNoPageFoundNow } from '../noPageFound/noPageFoundSlice';

const initialState = {
    offers: [],
    offer: null,
    offerCourses: [],
    selectableProducts: [],
    getOfferSelectableProductsPending: false,
    getOfferSelectableProductsErrMsg: '',
    getOffersPending: false,
    getOffersErrMsg: '',
    getOfferPending: false,
    getOfferErrMsg: '',
    getOfferCompleted: false,
    newCreatedOffer: null,
    createOfferPending: false,
    createOfferErrMsg: '',
    updateOfferInfoPending: false,
    updateOfferInfoErrMsg: '',
    updateOfferPricePending: false,
    updateOfferPriceErrMsg: '',
    updateOfferImgPending: false,
    updateOfferImgErrMsg: '',
    deleteOfferPending: false,

    // analytics data
    offerTabOverviewAnalytics: null,
    offerTabOverviewAnalyticsLoading: false,

    // offer course
    createOfferProductPending: false,
    createOfferProductErrMsg: ''
};

export const updateOfferStore = createAction('offer/updateStore');

export const getOffers = createAsyncThunk(
    'offers/get',
    async ({ schoolId, signal }) => {
        const data = await getOffersAPI({ schoolId, signal });
        if (data instanceof DOMException) {
            throw data;
        }
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const getOffer = createAsyncThunk(
    'offer/get',
    async ({ schoolId, offerId, includeProduct, signal }, { dispatch }) => {
        const data = await getOfferAPI({ schoolId, offerId, includeProduct, signal });
        if (data.apiStatusCode !== 200) {
            dispatch(updateShowNoPageFoundNow({ showNoPageFoundNow: true }));
        }
        if (data instanceof DOMException) {
            throw data;
        }
        return data;
    }
);

export const createOffer = createAsyncThunk(
    'offer/create',
    async ({ schoolId, payload }) => {
        const data = await createOfferAPI({ schoolId, data: payload });
        if (data.status === 'fail' && data.error) {
            throw new Error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
);

export const fetchOfferOverviewAnalytics = createAsyncThunk(
    'offer/overview-analytics',
    async ({ schoolId, offerId, selectDays }) => {
        const data = await fetchOfferOverviewAnalyticsAPI({ schoolId, offerId, selectDays });

        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
            throw new Error();
        }

        return data;
    }
);

export const getOfferSelectableProducts = createAsyncThunk(
    'offer/selectable-products',
    async ({ schoolId, offerId }) => {
        const data = await getSelectableProducts({ schoolId, offerId });

        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
            throw new Error();
        }

        return data;
    }
);

export const deleteOffer = createAsyncThunk(
    'offer/delete',
    async ({ schoolId, offerId }) => {
        const data = await deleteOfferAPI({ schoolId, offerId });
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
            throw new Error();
        } else if (data.successMessage) {
            SnackbarUtil.success(data.successMessage);
        }
        return data;
    }
);

export const updateOfferInfo = createAsyncThunk(
    'offer/update-offer-info',
    async ({
        schoolId,
        offerId,
        payload
    }) => {
        const data = await updateOfferAPI({
            schoolId,
            offerId,
            payload
        });
        return data;
    }
);

export const updateOfferPrice = createAsyncThunk(
    'offer/update-price',
    async ({
        schoolId,
        offerId,
        payload
    }) => {
        const data = await updateOfferAPI({
            schoolId,
            offerId,
            payload
        });
        return data;
    }
);

export const updateOfferImg = createAsyncThunk(
    'offer/update-img',
    async ({
        schoolId,
        offerId,
        payload
    }) => {
        const data = await updateOfferAPI({
            schoolId,
            offerId,
            payload
        });
        return data;
    }
);

export const updateOfferPublish = createAsyncThunk(
    'offer/update-publish',
    async ({
        schoolId,
        offerId,
        payload
    }) => {
        const data = await updateOfferAPI({
            schoolId,
            offerId,
            payload
        });
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
            throw new Error();
        }
        return data;
    }
);

export const updateOfferShowOnHomepageSlice = createAsyncThunk(
    'offer/update-show-on-homepage',
    async ({
        schoolId,
        offerId,
        payload
    }, { dispatch, getState }) => {
        dispatch(updateOfferStore({
            offers: updateIndividualOfferStore(getState().offer.offers, offerId, payload)
        }));
        const data = await updateOfferAPI({
            schoolId,
            offerId,
            payload
        });
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
            throw new Error();
        }
        return data;
    }
);

export const deleteOfferProduct = createAsyncThunk(
    'offerProduct/delete',
    async ({ schoolId, offerId, courseId }) => {
        const data = await deleteOfferCourseAPI({ schoolId, offerId, courseId });

        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
            throw new Error();
        }

        return data;
    }
);

export const createOfferProduct = createAsyncThunk(
    'offerProduct/create',
    async ({ schoolId, offerId, payload }) => {
        const data = await createOfferCourseAPI({ schoolId, offerId, data: payload });

        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
            throw new Error();
        }

        return data;
    }
);

const offerSlice = createSlice({
    name: 'offer',
    initialState,
    reducers: {
        resetOfferAction: state => {
            state.offer = null;
        },
        deleteOfferProductAction:  (state, action) => {
            const {
                courseId,
            } = action.payload;

            const updatedOffer = {...state.offer};
            updatedOffer.offerCourses = updatedOffer.offerCourses.filter(course => {
                return course.courseId !== courseId;
            });

            state.offer = updatedOffer;
            state.offerCourses = updatedOffer.offerCourses;
        }
    },
    extraReducers: {
        [updateOfferStore]: (state, action) => {
            const { payload } = action;
            Object.keys(payload).forEach(key => {
                state[key] = payload[key];
            }); 
        },

        [getOffers.fulfilled]: (state, action) => {
            const { offers, status, error } = action.payload;

            if (status === 'success') {
                state.offers = offers;
            } else if (error) {
                state.getOffersErrMsg = error.message
            }

            state.getOffersPending = false;
        },
        [getOffers.pending]: state => {
            state.offers = [];
            state.getOffersPending = true;
            state.getOffersErrMsg = '';
        },
        [getOffer.fulfilled]: (state, action) => {
            const { offer, status, error } = action.payload;

            if (status === 'success') {
                state.offer = offer;
                state.offerCourses = offer.offerCourses;
            } else if (error) {
                state.getOfferErrMsg = error.message
            }

            state.getOfferPending = false;
            state.getOfferCompleted = true;
        },
        [getOffer.pending]: state => {
            state.getOfferPending = true;
            state.getOfferErrMsg = '';
        },
        [getOfferSelectableProducts.fulfilled]: (state, action) => {
            const { selectableProducts, status, error } = action.payload;

            if (status === 'success') {
                state.selectableProducts = selectableProducts;
            } else if (error) {
                state.getOfferSelectableProductsErrMsg = error.message
            }

            state.getOfferSelectableProductsPending = false;
        },
        [getOfferSelectableProducts.pending]: state => {
            state.getOfferSelectableProductsPending = true;
            state.getOfferSelectableProductsErrMsg = '';
        },
        [getOfferSelectableProducts.rejected]: state => {
            state.getOfferSelectableProductsPending = false;
        },
        [createOffer.fulfilled]: (state, action) => {
            const { offer, status, error } = action.payload;

            if (status === 'success') {
                state.newCreatedOffer = offer;
                state.offers = [
                    offer,
                    ...state.offers
                ];
            } else if (error) {
                state.createOfferErrMsg = error.message
            }

            state.createOfferPending = false;
        },
        [createOffer.pending]: state => {
            state.newCreatedOffer = null;
            state.createOfferPending = true;
            state.createOfferErrMsg = '';
        },
        [createOffer.rejected]: state => {
            state.createOfferPending = false;
        },
        [updateOfferInfo.fulfilled]: (state, action) => {
            const { status, offer, error } = action.payload;

            if (status === 'success') {
                state.offer = offer;
            } else if (error) {
                state.updateOfferInfoErrMsg = error.message
            }

            state.updateOfferInfoPending = false;
        },
        [updateOfferInfo.pending]: state => {
            state.updateOfferInfoPending = true;
            state.updateOfferInfoErrMsg = '';
        },
        [updateOfferInfo.rejected]: state => {
            state.updateOfferInfoPending = false;
        },
        [updateOfferPrice.fulfilled]: (state, action) => {
            const { status, offer, error } = action.payload;

            if (status === 'success') {
                state.offer = offer;
            } else if (error) {
                state.updateOfferPriceErrMsg = error.message;
            }

            state.updateOfferPricePending = false;
        },
        [updateOfferPrice.pending]: state => {
            state.updateOfferPricePending = true;
            state.updateOfferPriceErrMsg = '';
        },
        [updateOfferPrice.rejected]: state => {
            state.updateOfferPricePending = false;
        },
        [updateOfferImg.fulfilled]: (state, action) => {
            const { payload } = action.meta.arg;
            const { status, error } = action.payload;

            if (status === 'success') {
                state.offer = {
                    ...state.offer,
                    offerImageUrl: payload.imageUrl
                };
            } else if (error) {
                state.updateOfferImgErrMsg = error.message
            }

            state.updateOfferImgPending = false;
        },
        [updateOfferImg.pending]: state => {
            state.updateOfferImgPending = true;
            state.updateOfferImgErrMsg = '';
        },
        [updateOfferImg.rejected]: state => {
            state.updateOfferImgPending = false;
        },

        [fetchOfferOverviewAnalytics.pending]: state => {
            state.offerTabOverviewAnalyticsLoading = true;
        },
        [fetchOfferOverviewAnalytics.fulfilled]: (state, action) => {
            const {
                payload: {
                    status,
                    earning,
                    graphData,
                    revenue,
                    total,
                    currencyCode
                }
            } = action;
            if (status === "success") {
                state.offerTabOverviewAnalytics = {
                    earning,
                    graphData,
                    revenue,
                    total,
                    currencyCode
                };
            }
            state.offerTabOverviewAnalyticsLoading = false;
        },
        [fetchOfferOverviewAnalytics.rejected]: state => {
            state.offerTabOverviewAnalyticsLoading = false;
        },
        [updateOfferPublish.pending]: (state, action) => {
            const { offerId, payload } = action.meta.arg;
            const offerIndex = state.offers.findIndex(offer => offer.id === offerId);
            if (offerIndex >= 0) {
                const newOffers = [...state.offers];
                newOffers[offerIndex] = {
                    ...newOffers[offerIndex],
                    ...payload
                };
                state.offers = newOffers;
            }
        },
        [updateOfferPublish.rejected]: (state, action) => {
            const { offerId, payload } = action.meta.arg;
            const offerIndex = state.offers.findIndex(offer => offer.id === offerId);
            // if updateing API failed, we revert back to its original state
            if (offerIndex >= 0) {
                const newOffers = [...state.offers];
                newOffers[offerIndex] = {
                    ...newOffers[offerIndex],
                    isPublished: !payload.isPublished
                };
                state.offers = newOffers;
            }
            state.updateOfferInfoPending = false;
        },
        [updateOfferShowOnHomepageSlice.rejected]: (state, action) => {
            const { offerId, payload } = action.meta.arg;
            const offerIndex = state.offers.findIndex(offer => offer.id === offerId);
            if (offerIndex >= 0) {
                const newOffers = [...state.offers];
                newOffers[offerIndex] = {
                    ...newOffers[offerIndex],
                    isShowOnHomepage: !payload.isShowOnHomepage
                };
                state.offers = newOffers;
            }
        },
        [deleteOffer.pending]: state => {
            state.deleteOfferPending = true;
        },
        [deleteOffer.fulfilled]: (state, action) => {
            const { offerId } = action.meta.arg;

            if (offerId) {
                state.offers = state.offers.filter(offer => offer.id !== offerId);
            }

            state.deleteOfferPending = false;
        },
        [deleteOffer.rejected]: state => {
            state.deleteOfferPending = false;
        },
        [createOfferProduct.fulfilled]: (state, action) => {
            const { offer, status, error } = action.payload;

            if (status === 'success') {
                state.offer = offer;
                state.offerCourses = offer.offerCourses;
            } else if (error) {
                state.createOfferProductErrMsg = error.message
            }

            state.createOfferProductPending = false;
        },
        [createOfferProduct.pending]: state => {
            state.createOfferProductPending = true;
            state.createOfferProductErrMsg = '';
        },
        [createOfferProduct.rejected]: state => {
            state.createOfferProductPending = false;
        },
    }
});

export const {
    deleteOfferProductAction,
    resetOfferAction
} = offerSlice.actions;

export default offerSlice.reducer;
