import React from 'react';
import { useSelector } from 'react-redux';
import {
    Drawer,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Typography
} from '@material-ui/core';
import {
    Link,
} from 'react-router-dom';
import {
    PaymentOutlined as PaymentOutlinedIcon,
    LockOutlined as LockOutlinedIcon,
    Devices as DevicesIcon,
} from '@material-ui/icons';
import CogOutline from 'mdi-material-ui/CogOutline';

import {
	APP_PREFIX_PATHNAME,
	ACCOUNT_PREFIX_PATHNAME,
    ACCOUNT_DASHBOARD_SECTION_ID,
    ACCOUNT_DASHBOARD_PATHNAME
} from '../../utils/constant';

import styles from './AccountDashboardNavigation.styles';

const SECTIONS = [
    {
        id: ACCOUNT_DASHBOARD_SECTION_ID.user,
        name: 'Account',
        path: ACCOUNT_DASHBOARD_PATHNAME.settings,
        icon: (<CogOutline />)
    },
    {
        id: ACCOUNT_DASHBOARD_SECTION_ID.websites,
        name: 'Websites',
        path: ACCOUNT_DASHBOARD_PATHNAME.websites,
        icon: (<DevicesIcon />)
    },
    {
        id: ACCOUNT_DASHBOARD_SECTION_ID.billing,
        name: 'Billing',
        path: ACCOUNT_DASHBOARD_PATHNAME.billing,
        icon: (<PaymentOutlinedIcon />)
    }
];

const INACTIVE_ACCOUNT_SECTION = {
    id: ACCOUNT_DASHBOARD_SECTION_ID.inactive,
    name: 'Account inactive',
    path: ACCOUNT_DASHBOARD_PATHNAME.inactive,
    icon: (<LockOutlinedIcon />)
};

export default function AccountDashboardNavigation(props) {
    const classes = styles();
    const userInfo = useSelector(state => state.user.userInfo);
    const fetchUserCompleted = useSelector(state => state.user.fetchUserCompleted);
    const {
        sectionId,
    } = props;
    const subDisabled = fetchUserCompleted && !(userInfo && userInfo.subscriptionInfo && userInfo.subscriptionInfo.isActive);
    const sectionsToRender = subDisabled ? [...SECTIONS, INACTIVE_ACCOUNT_SECTION] : SECTIONS;

    return (
        <Drawer
            className={classes.root}
            variant='permanent'
            classes={{
                paper: classes.drawerPaper,
            }}
            anchor='left'
        >
            <Typography className={classes.settingsTitle} variant='h5'>Settings</Typography>
            <List className='list-section main-menu'>
                {
                    sectionsToRender.map(section => {
                        const content = (
                            <ListItem
                                button
                                key={section.id}
                                className={sectionId === section.id ? 'selected' : ''}
                            >
                                <ListItemIcon>
                                    {section.icon}
                                </ListItemIcon>
                                <ListItemText primary={section.name} />
                            </ListItem>
                        );
                        return (
                            <Link to={{
                                pathname: `/${APP_PREFIX_PATHNAME}/${ACCOUNT_PREFIX_PATHNAME}/${section.path}`,
                                state: {sectionId: section.id},
                            }} key={section.id}>
                                {content}
                            </Link>
                        );
                    })
                }
            </List>
        </Drawer>
    );
}
