import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchInitDataAPI }  from '../../api/initDataAPI';

const initialState = {
    initData: null,
    loading: false,
    fetchInitDataCompleted: false
};

export const fetchInitData = createAsyncThunk(
    'initData/fetch',
    async () => {
        const getInitData = await fetchInitDataAPI();
        return getInitData;
    }
)

const initDataSlice = createSlice({
    name: 'initData',
    initialState,
    extraReducers: {
        [fetchInitData.fulfilled]: (state, action) => {
            const { data } = action.payload;
            state.initData = data;
            state.loading = false;
            state.fetchInitDataCompleted = true;
        },
        [fetchInitData.pending]: state => {
            state.loading = true;
        },
        [fetchInitData.rejected]: state => {
            state.loading = false;
        },
    }
});

export default initDataSlice.reducer;