import { combineReducers } from 'redux';

import loginReducer from '../features/login/loginSlice';
import schoolReducer from '../features/school/schoolSlice';
import userReducer from '../features/user/userSlice';
import courseReducer from '../features/course/courseSlice';
import courseChapterReducer from '../features/courseChapter/courseChapterSlice';
import courseLectureReducer from '../features/courseLecture/courseLectureSlice';
import courseStudentReducer from '../features/courseStudent/courseStudentSlice';
import textFlowReducer from '../features/textFlow/textFlowSlice';
import paymentReducer from '../features/payment/paymentSlice';
import noPageFoundReducer from '../features/noPageFound/noPageFoundSlice';
import domainReducer from '../features/domain/domainSlice';
import initDataReducer from '../features/initData/initDataSlice';
import offerReducer from '../features/offer/offerSlice';
import studentUserReducer from '../features/studentUser/studentUserSlice';
import couponReducer from '../features/coupon/couponSlice';
import interactiveCodeReducer from '../features/interactiveCode/interactiveCodeSlice';

export default combineReducers({
    login: loginReducer,
    user: userReducer,
    school: schoolReducer,
    course: courseReducer,
    courseChapter: courseChapterReducer,
    courseLecture: courseLectureReducer,
    courseStudent: courseStudentReducer,
    textFlow: textFlowReducer,
    payment: paymentReducer,
    noPageFound: noPageFoundReducer,
    domain: domainReducer,
    initData: initDataReducer,
    offer: offerReducer,
    studentUser: studentUserReducer,
    coupon: couponReducer,
    interactiveCode: interactiveCodeReducer
});
