import React, { Suspense, lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography } from '@material-ui/core';
import {
    Switch,
    Route,
    useRouteMatch,
    Redirect,
} from "react-router-dom";

import { fetchUserSubscriptionStatus } from '../../../features/payment/paymentSlice';
import { BILLING_DASHBOARD_PATHNAME } from '../../../utils/constant'
import FetchLoading from '../../../components/FetchLoading';
import BillingNavBar from './BillingNavBar';
import styles from './Billing.styles';

const BillingPayment = lazy(() => import(/* webpackChunkName: "BillingPayment" */ './BillingPayment'));
const BillingHistory = lazy(() => import(/* webpackChunkName: "BillingHistory" */ './BillingHistory'));

export default function Billing({ user }) {
    const classes = styles();
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const subscriptionInfo = useSelector(state => state.payment.subscriptionInfo);

    useEffect(() => {
        if (!subscriptionInfo) {
            dispatch(fetchUserSubscriptionStatus());
        }
    }, []); // eslint-disable-line

    function renderContent() {
        if (!user.fetchUserCompleted ) {
            return null;
        }
        return (
            <>
                <Box className={classes.content}>
                    <Typography className={classes.billingHeader} variant="body1">Billing</Typography>
                    <BillingNavBar />
                    <Box className={classes.routeContentContainer}>
                        <Box className={classes.scrollableContainer}>
                            <Switch>
                                <Route path={`${match.path}/${BILLING_DASHBOARD_PATHNAME.payment}`}>
                                    <Suspense fallback={<FetchLoading />}>
                                        <BillingPayment />
                                    </Suspense>
                                </Route>
                                <Route path={`${match.path}/${BILLING_DASHBOARD_PATHNAME.history}`}>
                                    <Suspense fallback={<FetchLoading />}>
                                        <BillingHistory />
                                    </Suspense>
                                </Route>
                                <Redirect to={`${match.path}/${BILLING_DASHBOARD_PATHNAME.payment}`} />
                            </Switch>
                        </Box>
                    </Box>
                </Box>
            </>
        )
    }

    return (
        renderContent()
    );
}
