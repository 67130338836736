import fetchClient from '../utils/fetchClient';

const URL = (schoolId) => {
    return `/api/schools/${schoolId}/offers`
};

export async function getOffersAPI({ schoolId, simple, signal }) {
    let data = null;

    await fetchClient(
        URL(schoolId) + (simple ? '?simple=true' : ''), { signal })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}

export async function getOfferAPI({ schoolId, offerId, includeProduct, signal }) {
    let data = null;

    await fetchClient(
        URL(schoolId) + `/${offerId}${includeProduct ? '?includeProduct=true' : ''}`, { signal })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}

export async function createOfferAPI({ schoolId, data }) {
    await fetchClient(
        URL(schoolId),
        {
            body: data
        })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}

export async function updateOfferAPI({
    schoolId,
    offerId,
    payload
 }) {
    let data;
    await fetchClient(
        `${URL(schoolId)}/${offerId}`,
        {
            method: 'PUT',
            body: payload
        }
    )
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );
    return data;
}

export async function deleteOfferAPI({ schoolId, offerId }) {
    let data;
    await fetchClient(
        `${URL(schoolId)}/${offerId}`,
        {
            method: 'DELETE'
        }
    )
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );
    return data;
}

export async function getSelectableProducts({ schoolId, offerId }) {
    let data = null;

    await fetchClient(
        URL(schoolId) + `/${offerId}/selectable-products`)
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}

export async function fetchOfferOverviewAnalyticsAPI({ schoolId, offerId, selectDays }) {
    let data;
    await fetchClient(
        `${URL(schoolId)}/${offerId}/analytics/tab-overview?days=${selectDays}`)
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );
    return data;
}
