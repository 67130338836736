import React, { Suspense, lazy, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Route,
    Switch,
    Redirect,
    useLocation,
    useHistory,
    useRouteMatch
} from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import styled from 'styled-components';

// App Dashboard
import Dashboard from './app/dashboard/Dashboard';
// course
import CourseDashboard from './app/courseDashboard/CourseDashboard';
// account
import AccountDashboard from './app/AccountDashboard';

import NoPageFound from './components/NoPageFound';

// Redux
import { fetchUser } from './features/user/userSlice';
import { updateShowNoPageFoundNow } from './features/noPageFound/noPageFoundSlice';
import { fetchInitData } from "./features/initData/initDataSlice";

import SnackbarUtilsConfig from './components/SnackbarUtilsConfig';
import { DASHBOARD_PATHNAME } from './utils/constant';
import UserAccountEndedInfo from './app/dashboard/Billing/UserAccountEndedInfo/UserAccountEndedInfo';
import DetectInternetConnection from './components/common/DetectInternetConnection';
import FetchLoading from './components/FetchLoading';

// lecture
const LectureItemsDashboard = lazy(() => import(/* webpackChunkName: "LectureItemsDashboard" */ './components/common/LectureItemsDashboard'));

// text lecture
const CreateTextLecture = lazy(() => import(/* webpackChunkName: "CreateTextLecture" */ './app/textFlow/CreateTextLecture'));

const accountRoute = `/app/${DASHBOARD_PATHNAME.account}`;
const accountWebsitesRoute = `/app/${DASHBOARD_PATHNAME.account}/${DASHBOARD_PATHNAME.settingsWebsites}`;
const billingRoute = `/app/${DASHBOARD_PATHNAME.billing}`;
const settingsRoute = `/app/${DASHBOARD_PATHNAME.settings}`;
const defaultPath = ['/', `/app/${DASHBOARD_PATHNAME.websites}/`];

const StyledSnackbarProvider = styled(SnackbarProvider)`
  & {
    padding: 12px 16px;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
    border: none;
    border-radius: 7px;

    &[class*='variantSuccess'] {
        background-color: #d3ffde;
    }

    &[class*='variantSuccess'] .MuiSvgIcon-root {
        color: #23c68e;
    }

    &[class*='variantWarning'] {
        background-color: #ffeec6;
    }

    &[class*='variantWarning'] .MuiSvgIcon-root {
        color: #ffa726;
    }

    &[class*='variantError'] {
        background-color: #fee3e1;
    }

    &[class*='variantError'] .MuiSvgIcon-root {
        color: #ef4d62;
    }

    &[class*='variantInfo'] {
        background-color: #c4f1ff;
    }

    &[class*='variantInfo'] .MuiSvgIcon-root {
        color: #3dcaff;
    }

    #notistack-snackbar {
        color: #1c1d1c;
        font-weight: 500;
        font-size: 15px;
        padding: 8px 0 6px;
    }
  }
`;

function Routes() {
    const location = useLocation();
    const dispatch = useDispatch();
    const history = useHistory();
    const userInfo = useSelector(state => state.user.userInfo);
    const fetchUserCompleted = useSelector(state => state.user.fetchUserCompleted);
    const noPageFound = useSelector(state => state.noPageFound.showNoPageFoundNow);
    const fetchInitDataCompleted = useSelector(state => state.initData.fetchInitDataCompleted);
    const isBillingPage = useRouteMatch(billingRoute);
    const isAccountPage = useRouteMatch(accountRoute);
    const isSettingsPage = useRouteMatch(settingsRoute);
    const subInactive = fetchUserCompleted && !(userInfo && userInfo.subscriptionInfo && userInfo.subscriptionInfo.isActive);
    const noWebsiteFound = fetchUserCompleted && !(userInfo && userInfo.defaultSiteId);

    useEffect(() => {
        dispatch(fetchInitData());
        dispatch(fetchUser());
    }, []); // eslint-disable-line

    useEffect(() => {
        if (fetchUserCompleted && !userInfo) {
            window.location.href = `${window.location.origin}/login?redirect=${location.pathname}`;
        }

        // If user is subscribed but doesn't have any website setup yet, route them to the create a website page.
        // This happens when:
        // - user first creates an account therefore no website is created yet.
        // - user deletes all their website.
        if (!subInactive && noWebsiteFound) {
            history.push(accountWebsitesRoute);
        }
    }, [fetchUserCompleted, userInfo]); // eslint-disable-line

    useEffect(() => {
        // reset
        if (noPageFound) {
            dispatch(updateShowNoPageFoundNow({
                showNoPageFoundNow: false
            }));
        }

        // if user is not subscribed and their subscription is cancelled we route them back to payment page
        if (subInactive && !isBillingPage && !isSettingsPage && !isAccountPage) {
            history.push(accountRoute);
        }
    }, [location, fetchUserCompleted]); // eslint-disable-line

    const renderBody = () => {
        if (noPageFound) {
            return <NoPageFound />;
        }

        if (userInfo) {
            const defaultRoute = `/app/${DASHBOARD_PATHNAME.websites}/${userInfo.defaultSiteId}/${DASHBOARD_PATHNAME.siteHome}`;
            const reRoutePath = subInactive ? billingRoute : defaultRoute;

            return (
                <Switch>
                    {/* app */}
                    <Route
                        exact
                        match
                        path={defaultPath}
                        render={(props) => <Redirect to={{ pathname: reRoutePath, state: { from: props.location } }} />}
                    />

                    {/* Lectures */}
                    <Route
                        path={`/app/${DASHBOARD_PATHNAME.websites}/:schoolId/products/:courseId/lectures/:lectureId`}
                        render={(props) => (
                            <Suspense fallback={<FetchLoading />}>
                                <LectureItemsDashboard {...props} />
                            </Suspense>
                        )}
                    />
                    <Route
                        path={`/app/${DASHBOARD_PATHNAME.websites}/:schoolId/products/:courseId/text-lectures/:textLectureId`}
                        render={(props) => (
                            <Suspense fallback={<FetchLoading />}>
                                <CreateTextLecture {...props} />
                            </Suspense>
                        )}
                    />

                    {/* Course */}
                    <Route path={`/app/${DASHBOARD_PATHNAME.websites}/:schoolId/products/:courseId`} component={CourseDashboard} />

                    {/* Account */}
                    <Route path={`/app/${DASHBOARD_PATHNAME.account}`} component={AccountDashboard} />

                    {/* School */}
                    {/* This matches routes like /app/sites/3, /app/settings, etc */}
                    <Route path='/app' component={Dashboard} />
                </Switch>
            )
        }

        return null;
    }

    return (
        <StyledSnackbarProvider
            maxSnack={100}
            anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom',
            }}
        >
            <SnackbarUtilsConfig />
            <div id='app-container'>
                <div className='main-container'>
                    <div className='main-content'>
                        {
                            fetchInitDataCompleted ? (
                                <>
                                    {
                                        renderBody()
                                    }
                                    <UserAccountEndedInfo />
                                </>
                            ) : null
                        }
                    </div>
                </div>
            </div>
            <DetectInternetConnection />
        </StyledSnackbarProvider>
    );
}

export default Routes;
