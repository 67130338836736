import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(1.5),
    },
    appBar: {
        position: 'relative',
        color: '#5f6368',
        backgroundColor: theme.palette.common.white,
        borderBottom: '1px solid #dadce0',
        zIndex: 200,

        '& .MuiTabs-root': {
            minWidth: '82px',
            fontSize: '22px',

            '& .MuiTab-root': {
                minWidth: '110px',
            },
        },

        '& .MuiTabs-indicator': {
            height: '2px',
            backgroundColor: theme.palette.primary.main,
        },

        '& .MuiButtonBase-root': {
            textTransform: 'capitalize',
            opacity: 1,
            fontWeight: '500'
        },

        '& a': {
            textDecoration: 'none',
            color: '#5f6368',

            '&.selected': {
                color: '#1967d3',
                fontWeight: '500'
            }
        }
    }
}));
