import { createSlice, createAction } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { SnackbarUtil } from '../../components/SnackbarUtilsConfig';
import { fetch, updateUserAPI, logOutAPI, updateUserPasswordAPI }  from '../../api/userAPI';
import { API_ERROR_RESPONSE } from '../../utils/constant';

const initialState = {
    userInfo: null,
    isLoggedIn: false,
    fetchUserPending: false,
    fetchUserCompleted: false,
    userUpdateLoading: false,
    userUpdatePictureLoading: false,
    userLogoutLoading: false,
    userUpdatePasswordLoading: false
};

export const updateUserStore = createAction('user/update-store');

export const fetchUser = createAsyncThunk(
    'user/fetch',
    async (_data) => {
        const returnedData = await fetch();
        // if data failed we do not log the error, we will route user back to login
        return returnedData;
    }
)

export const updateUser = createAsyncThunk(
    'user/update',
    async (data) => {
        const returnedData = await updateUserAPI(data);
        if (returnedData.status === 'fail' && returnedData.error) {
            throw new Error(returnedData.error.message || API_ERROR_RESPONSE);
        }
        return returnedData;
    }
)

export const logoutUser = createAsyncThunk(
    'user/logout',
    async () => {
        const data = await logOutAPI();
        if (data.status === 'fail' && data.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        }
        return data;
    }
)

export const updateUserPicture = createAsyncThunk(
    'userPicture/update',
    async (data) => {
        const returnedData = await updateUserAPI(data);
        if (returnedData.status === 'fail' && returnedData.error) {
            throw new Error(returnedData.error.message || API_ERROR_RESPONSE);
        }
        return returnedData;
    }
);

export const updateUserPassword = createAsyncThunk(
    'userPassword/update',
    async (data) => {
        const returnedData = await updateUserPasswordAPI(data);
        if (returnedData.status === 'fail' && returnedData.error) {
            SnackbarUtil.error(data.error.message || API_ERROR_RESPONSE);
        } else if (returnedData.status === 'success') {
            SnackbarUtil.success('Password updated successfully.');
        }
        return returnedData;
    }
);

const userSlice = createSlice({
    name: 'user',
    initialState,
    extraReducers: {
        // sync
        [updateUserStore]: (state, action) => {
            state.userInfo = {
                ...(state.userInfo || {}),
                ...action.payload
            }
        },

        // async
        // Add reducers for additional action types here, and handle loading state as needed
        [fetchUser.pending]: state => {
            state.fetchUserPending = true;
            state.fetchUserCompleted = false;
        },
        [fetchUser.fulfilled]: (state, action) => {
            // Add user to the state array
            const { user } = action.payload;
            state.userInfo = user;
            state.isLoggedIn = true;
            state.fetchUserPending = false;
            state.fetchUserCompleted = true;
        },
        [fetchUser.rejected]: state => {
            state.fetchUserPending = false;
            state.fetchUserCompleted = true;
        },
        [updateUserPicture.pending]: state => {
            state.userUpdatePictureLoading = true;
        },
        [updateUserPicture.fulfilled]: (state, action) => {
            const { status, user } = action.payload;
            if (status === 'success') {
                state.userInfo = {
                    ...(state.userInfo || {}),
                    ...user
                };
            }
            state.userUpdatePictureLoading = false;
        },
        [updateUserPicture.rejected]: state => {
            state.userUpdatePictureLoading = false;
        },
        [updateUserPassword.pending]: state => {
            state.userUpdatePasswordLoading = true;
        },
        [updateUserPassword.fulfilled]: state => {
            state.userUpdatePasswordLoading = false;
        },
        [updateUserPassword.rejected]: state => {
            state.userUpdatePasswordLoading = false;
        },
        [updateUser.pending]: state => {
            state.userUpdateLoading = true;
        },
        [updateUser.fulfilled]: (state, action) => {
            const { status, user } = action.payload;
            if (status === 'success') {
                state.userInfo = {
                    ...(state.userInfo || {}),
                    ...user
                };
            }
            state.userUpdateLoading = false;
        },
        [updateUser.rejected]: (state) => {
            state.userUpdateLoading = false;
        },
        [logoutUser.pending]: state => {
            state.userLogoutLoading = true;
        },
        [logoutUser.fulfilled]: (state) => {
            state.userLogoutLoading = false;
        },
        [logoutUser.rejected]: state => {
            state.userLogoutLoading = false;
        }
    }
});

export default userSlice.reducer;
