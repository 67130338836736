import { makeStyles } from '@material-ui/core/styles';
import {
    grey
} from '@material-ui/core/colors';

export default makeStyles(theme => ({
    content: {
        width: '100%',
        height: '100%',
        backgroundColor: theme.palette.common.white,
        paddingLeft: theme.spacing(3),
        overflow: 'hidden',
    },
    billingHeader: {
        fontSize: '1.1rem',
        marginTop: theme.spacing(2.75),
        color: grey[800],
        fontWeight: 500,
    },
    routeContentContainer: {
        position: 'absolute',
        width: 'calc(100% - 24px)',
        height: '100%',
        top: 0,
        paddingTop: theme.spacing(21.5),
        overflow: 'hidden',
        zIndex: 100,
    },
    scrollableContainer: {
        width: '100%',
        height: '100%',
        overflowY: 'auto'
    }
}));
