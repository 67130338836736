import React from 'react';
import { useSelector } from 'react-redux';
import {
    Drawer,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Grid
} from '@material-ui/core';
import {
    useRouteMatch,
    Link,
} from 'react-router-dom';
import {
    AttachMoneyOutlined as AttachMoneyOutlinedIcon,
    HomeOutlined as HomeOutlinedIcon,
    SettingsOutlined as SettingsOutlinedIcon,
    LocalOfferOutlined as LocalOfferOutlinedIcon,
    LanguageOutlined as LanguageOutlinedIcon,
    ContactsOutlined as ContactsOutlinedIcon,
    TimelineOutlined as TimelineOutlinedIcon,
    CastOutlined as CastOutlinedIcon,
} from '@material-ui/icons';
import CogOutline from 'mdi-material-ui/CogOutline';
import MessageAlertOutline from 'mdi-material-ui/MessageAlertOutline';
import TicketPercentOutline from 'mdi-material-ui/TicketPercentOutline';

import {
    DASHBOARD_SECTION_ID,
    DASHBOARD_PATHNAME,
    SUPPORT_EMAIL
} from '../../../utils/constant';

import styles from './DashboardNavSection.styles';

const REQUIRE_SUBSCRIPTION_SECTIONS = [
    DASHBOARD_SECTION_ID.settings,
    DASHBOARD_SECTION_ID.siteHome,
    DASHBOARD_SECTION_ID.siteProducts,
    DASHBOARD_SECTION_ID.siteSettings,
    DASHBOARD_SECTION_ID.siteOffers,
    DASHBOARD_SECTION_ID.sitePayment,
    DASHBOARD_SECTION_ID.siteDomain,
    DASHBOARD_SECTION_ID.siteContacts,
    DASHBOARD_SECTION_ID.siteAnalytics,
    DASHBOARD_SECTION_ID.siteCoupons,
];

const SECTIONS = [
    {
        id: DASHBOARD_SECTION_ID.siteHome,
        name: 'Home',
        path: DASHBOARD_PATHNAME.siteHome,
        icon: (<HomeOutlinedIcon />)
    },
    {
        id: DASHBOARD_SECTION_ID.siteProducts,
        name: 'Products',
        path: DASHBOARD_PATHNAME.siteProducts,
        icon: (<CastOutlinedIcon />)
    },
    {
        id: DASHBOARD_SECTION_ID.siteSettings,
        name: 'Settings',
        path: DASHBOARD_PATHNAME.siteSettings,
        icon: (<SettingsOutlinedIcon />)
    },
    {
        id: DASHBOARD_SECTION_ID.siteOffers,
        name: 'Offers',
        path: DASHBOARD_PATHNAME.siteOffers,
        icon: (<LocalOfferOutlinedIcon />)
    },
    {
        id: DASHBOARD_SECTION_ID.sitePayment,
        name: 'Earn',
        path: DASHBOARD_PATHNAME.sitePayment,
        icon: (<AttachMoneyOutlinedIcon />)
    },
    {
        id: DASHBOARD_SECTION_ID.siteDomain,
        name: 'Domain',
        path: DASHBOARD_PATHNAME.siteDomain,
        icon: (<LanguageOutlinedIcon />)
    },
    {
        id: DASHBOARD_SECTION_ID.siteContacts,
        name: 'Contacts',
        path: DASHBOARD_PATHNAME.siteContacts,
        icon: (<ContactsOutlinedIcon />)
    },
    {
        id: DASHBOARD_SECTION_ID.siteAnalytics,
        name: 'Analytics',
        path: DASHBOARD_PATHNAME.siteAnalytics,
        icon: (<TimelineOutlinedIcon />)
    },
    {
        id: DASHBOARD_SECTION_ID.siteCoupons,
        name: 'Coupons',
        path: DASHBOARD_PATHNAME.siteCoupons,
        icon: (<TicketPercentOutline />)
    },
];

export default function DashboardNavSection(props) {
    const classes = styles();
    const match = useRouteMatch();
    const userInfo = useSelector(state => state.user.userInfo);
    const fetchUserCompleted = useSelector(state => state.user.fetchUserCompleted);
    const {
        sectionId,
    } = props;
    const subDisabled = fetchUserCompleted && !(userInfo && userInfo.subscriptionInfo && userInfo.subscriptionInfo.isActive);
    const defaultSiteId = userInfo && userInfo.defaultSiteId;

    function handleOpenMail() {
        window.location.href = `mailto:${SUPPORT_EMAIL}?subject=Feedback%20for%20Airteach`;
    }

    if (!defaultSiteId) {
        return null;
    }

    return (
        <Drawer
            className={classes.root}
            variant='permanent'
            classes={{
                paper: classes.drawerPaper,
            }}
            anchor='left'
        >
            <Grid
                container
                direction='column'
                wrap='nowrap'
                // justifyContent='space-between'
                // alignItems='stretch'
                className={classes.gridContainer}

            >
                <Grid item className={classes.grow}>
                    <List className='listSection top'>
                        {
                            SECTIONS.map(section => {
                                const content = (
                                    <ListItem
                                        button
                                        key={section.id}
                                        disabled={REQUIRE_SUBSCRIPTION_SECTIONS.indexOf(section.id) !== -1 ? subDisabled : false}
                                        className={sectionId === section.id ? 'selected' : ''}
                                        disableRipple={true}
                                    >
                                        <ListItemIcon>
                                            {section.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={section.name} />
                                    </ListItem>
                                );

                                if (
                                    subDisabled
                                    && REQUIRE_SUBSCRIPTION_SECTIONS.indexOf(section.id) !== -1
                                ) {
                                    return content;
                                }

                                return (
                                    <Link to={{
                                        pathname: `${match.url}/${DASHBOARD_PATHNAME.websites}/${defaultSiteId}/${section.path}`,
                                        state: {sectionId: section.id},
                                    }} key={section.id}>
                                        {content}
                                    </Link>
                                );
                            })
                        }
                    </List>
                </Grid>
                <Grid item>
                    <List className='listSection bottom'>
                        <Link to={{
                            pathname: `${match.url}/${DASHBOARD_PATHNAME.settings}`,
                            state: {sectionId: DASHBOARD_SECTION_ID.settings},
                        }} key={DASHBOARD_SECTION_ID.settings}>
                            <ListItem
                                button
                                key={DASHBOARD_SECTION_ID.settings}
                            >
                                <ListItemIcon>
                                    <CogOutline />
                                </ListItemIcon>
                                <ListItemText primary='Account Settings' />
                            </ListItem>
                        </Link>
                        <ListItem onClick={handleOpenMail} button>
                            <ListItemIcon className='feedbackIcon'>
                                <MessageAlertOutline />
                            </ListItemIcon>
                            <ListItemText primary='Send feedback' />
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Drawer>
    );
}
