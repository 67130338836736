import { makeStyles } from '@material-ui/styles';
import {
    grey
} from '@material-ui/core/colors';
import {
    DASHBOARD_NAV_DRAWER_WIDTH
} from '../../utils/constant';

export default makeStyles(theme => ({
    root: {
        position: 'absolute',
        width: DASHBOARD_NAV_DRAWER_WIDTH,
        height: '100%',
        zIndex: 0,
        flexShrink: 0,
        paddingTop: theme.appBarHeight,

        '& .list-section': {
            paddingRight: '16px',
            overflow: 'auto',

            '&.main-menu': {
                paddingTop: theme.spacing(1),

                '& a': {
                    textDecoration: 'none',
                    color: '#1967d3',
                },

                '& .MuiButtonBase-root': {
                    paddingTop: '4px',
                    paddingBottom: '4px',
                    paddingLeft: '28px',
                    'border-top-right-radius': '40px',
                    'border-bottom-right-radius': '40px',

                    '& .MuiListItemIcon-root': {
                        minWidth: '24px',
                        paddingRight: '20px',
                    },

                    '& .MuiListItemText-root .MuiTypography-root': {
                        height: '26px',
                        lineHeight: '27px',
                        fontSize: '14px',
                        color: grey[800],
                        fontWeight: '600',
                    },

                    '&.selected': {
                        backgroundColor: theme.palette.selectedNavItemBackground,

                        '& .MuiListItemIcon-root': {
                            color: theme.palette.selectedNavItem,
                        },

                        '& .MuiListItemText-root .MuiTypography-root': {
                            color: theme.palette.selectedNavItem,
                        },
                    },
                },

                '& .feedbackIcon': {
                    position: 'relative',
                    top: '1px',
                },
            },
        },
    },
    settingsTitle: {
    	padding: theme.spacing(2.5, 1, 1.25, 3.5),
    	fontSize: '1.3rem',
    	fontWeight: 600,
    	color: grey[700],
    },
    drawerPaper: {
        width: DASHBOARD_NAV_DRAWER_WIDTH,
        borderRight: 'none',
        paddingTop: theme.appBarHeight,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    grow: {
        flexGrow: 1,
    },
}));
