import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { 
} from '@material-ui/icons';

import { DEFAULT_LECTURE_NAME } from "../../utils/constant";

const styles = theme => ({
    root: {
        'position': 'relative',
        'display': 'inline-block',
        'height': '27px',
        'width': 'auto',
        'font-family': 'Roboto,RobotoDraft,Helvetica,Arial,sans-serif',
    },
    input: {
        display: 'inline-block',
        height: '26px',
        padding: '2px 7px',
        letterSpacing: '0.2px',
        'font-size': '18px',
        'font-variant-ligatures': 'no-contextual',
        'line-height': '26px',
        'min-width': '1px',
        'border-width': '1px',
        'border-style': 'solid',
        'border-color': 'transparent',
        'border-image': 'initial',
        'margin': '0px',
        'border-radius': '2px',
        '-webkit-writing-mode': 'horizontal-tb',
        'text-rendering': 'auto',
        'letter-spacing': 'normal',
        'word-spacing': 'normal',
        'text-transform': 'none',
        'text-indent': '0px',
        'text-shadow': 'none',
        'text-align': 'start',
        '-webkit-appearance': 'textfield',
        '-webkit-rtl-ordering': 'logical',
        'cursor': 'text',
        'color': 'white',

        '&:focus': {
            'box-shadow': 'none',
            'margin': '-1px',
            'border-width': '2px',
            'border-style': 'solid',
            'border-color': 'rgb(26, 115, 232) !important',
            'border-image': 'initial',
            'border-radius': '4px !important',
            'outline': 'none',
            '-webkit-appearance': 'none'
        },

        '&:hover': {
            'border-color': 'rgb(229, 229, 229)'
        }
    },
    'inputLabel': {
        'position': 'absolute',
        'top': '-1px',
        'visibility': 'visible',
        'pointer-events': 'none',
        'font-size': '18px',
        'line-height': '22px',
        'font-variant-ligatures': 'no-contextual',
        'margin': '0',
        'overflow': 'hidden',
        'padding': '2px 8px',
        'text-overflow': 'ellipsis',
        'white-space': 'pre',
        'z-index': '1',
        'letter-spacing': 'normal',
        'font-family': 'system-ui',
        'color': 'black',

        '& .courseTitleText': {
            'display': 'inline',
        }
    },
    iconButton: {
        padding: 10,
    },
    titleDisplay: {
        margin: 0,
        padding: '0 16px',
        border: '1px solid transparent',

        '&:hover': {
            border: '1px solid grey'
        }
    }
});

class CourseTitleEdit extends Component {
    inputLabelRef = React.createRef();
    inputRef = React.createRef();
    inputLabelInnerRef = React.createRef();

    state = {
        inputWidth: 'auto',
        showInputLabel: false,
    };

    inputClick = event => {
        this.updateWidth();

        this.setState({
            showInputLabel: false
        });
    }

    inputHandler = event => {
        if (event.currentTarget) {
            let inputLabelInnerElem = this.inputLabelInnerRef.current;
            const { value } = event.currentTarget;
            inputLabelInnerElem.innerText = value;

            this.updateWidth();
        }
    }

    inputBlur = event => {
        const {
            id,
            saveContentHandler
        } = this.props;

        this.setState({
            showInputLabel: true
        });

        if (this.inputRef.current && !this.inputRef.current.value) {
            this.updateContent(DEFAULT_LECTURE_NAME);
        }

        let inputElem = this.inputRef.current;

        if (saveContentHandler) {
            saveContentHandler(id, inputElem.value);
        }
    }

    updateWidth = () => {
        // update width
        let inputLabelElem = this.inputLabelRef.current;
        let inputLabelElemWidth = inputLabelElem.offsetWidth || 40;

        let inputElem = this.inputRef.current;
        let inputElemWidth = inputElem.offsetWidth;
        let inputElemVal = inputElem.value;

        if (!inputElemVal) {
            this.setState({
                inputWidth: '23px',
            });
        } else if ((parseFloat(inputLabelElemWidth) + 2) !== parseFloat(inputElemWidth)) {
            this.setState({
                inputWidth: inputLabelElemWidth + 2 + 'px',
            });
        }
    }

    updateContent = (content) => {
        this.inputRef.current.value = content;
        this.inputLabelInnerRef.current.innerText = content;
    }

    componentDidMount() {
        const { content } = this.props;

        this.updateContent(content);
        this.updateWidth();
    }

    componentDidUpdate(prevProps) {
        const { content } = this.props;

        if (content !== prevProps.content) {
            this.updateContent(content);
        }

        const { value } = this.inputRef.current;
        if (value) {
            this.updateWidth();
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <div
                    className={classes.inputLabel}
                    ref={this.inputLabelRef}
                    style={{
                        visibility: this.state.showInputLabel ? 'visible' : 'hidden'
                    }}
                >
                    <span className="courseTitleText" ref={this.inputLabelInnerRef} />
                </div>
                <input
                    className={classes.input}
                    ref={this.inputRef}
                    placeholder=""
                    onClick={this.inputClick}
                    onInput={this.inputHandler}
                    onBlur={this.inputBlur}
                    style={{
                        width: `${this.state.inputWidth}`,
                        color: this.state.showInputLabel ? 'white' : 'black'
                    }}
                />
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => ({
});

const mapStateToProps = state => ({
});

CourseTitleEdit.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(CourseTitleEdit));
