import React from 'react';
import { useSelector } from 'react-redux';
import {
    Link
} from 'react-router-dom';
import {
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    CardMedia
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import styles from './CourseNavAndInfo.styles';

export default function CourseNavAndInfo({ schoolPath, courseId }) {
    const classes = styles();
    let course = useSelector(state => state.course.course);
    course = course[courseId];

    return (
        <Box className={classes.root}>
            <List>
                <Link className='sideBarLink backButtonContainer' to={`${schoolPath}`}>
                    <ListItem className='listItem' button key='website dashboard'>
                            <ListItemIcon className='listItemIcon'>
                                <ArrowBackIcon />
                            </ListItemIcon>
                            <ListItemText className='listItemLabelText' primary='Back to Website' />
                    </ListItem>
                </Link>
            </List>
            {
                course && course.media_file_url ?
                    <Box className='courseLogoContainer'>
                        <CardMedia
                            className="media"
                            component="img"
                            image={course && course.media_file_url}
                            title="course image"
                        />
                    </Box> : null
            }
            {
                course ?
                    <Box className='courseTitle'>
                        <Typography variant='body2' className='label'>Your product</Typography>
                        <Typography variant='body2' className='name'>{course.name}</Typography>
                    </Box> : null
            }
        </Box>
    );
}
