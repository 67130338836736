import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';

import styles from './styles';

export default function FetchLoading({ className = '' }) {
    const classes = styles();

    return (
        <Grid
            container
            justifyContent='center'
            alignItems='center'
            className={className ? `${className} ${classes.loadContainer}` : classes.loadContainer}
        >
            <CircularProgress
                color='primary'
                size={50}
            />
        </Grid>
    );
}
