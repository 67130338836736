import React, { Suspense, lazy } from 'react';
import { useRouteMatch, Route, Switch } from 'react-router-dom';
import { Box } from '@material-ui/core';

import FetchLoading from '../../../../components/FetchLoading';
import styles from './SchoolCoupons.styles';

const SchoolCouponsDashboard = lazy(() => import(/* webpackChunkName: "SchoolCouponsDashboard" */ './SchoolCouponsDashboard'));
const CouponDetail = lazy(() => import(/* webpackChunkName: "CouponDetail" */ './CouponDetail'));

export default function SchoolCoupons({ schoolId }) {
    const classes = styles();
    const match = useRouteMatch();

    return (
        <Box className={classes.schoolCouponsRoot}>
            <Switch>
                <Route exact path={`${match.path}`}>
                    <Suspense fallback={<FetchLoading />}>
                        <SchoolCouponsDashboard schoolId={schoolId} />
                    </Suspense>
                </Route>
                <Route exact path={`${match.path}/:couponId/details`}>
                    <Suspense fallback={<FetchLoading />}>
                        <CouponDetail schoolId={schoolId} />
                    </Suspense>
                </Route>
            </Switch>
        </Box>
    );
}
