import fetchClient from '../utils/fetchClient';

const COURSE_STUDENT_QUERY_PARAMS = new Set(['limit', 'afterId', 'sortBy', 'studentId']);

export async function fetchCourseStudentsAPI(schoolId, courseId, params={}) {
    let data = {};
    let queryParamsArr = [];
    let url = `/api/schools/${schoolId}/courses/${courseId}/students`;

    for (let key in params) {
        if (COURSE_STUDENT_QUERY_PARAMS.has(key)) {
            queryParamsArr.push([key, params[key]]);
        }
    }

    let queryString = new URLSearchParams(queryParamsArr).toString();
    url = queryString.length ? `${url}?${queryString}` : url;

    await fetchClient(url)
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}

export async function unenrollCourseStudentsAPI(schoolId, courseId, studentIds) {
    let data = {};
    let url = `/api/schools/${schoolId}/courses/${courseId}/students/unenroll`;

    await fetchClient(url,
        {
            body: {
                studentIds
            }
        })
        .then(
            // success handler
            resData => {
                data = resData;
            },
            // error handler
            errData => {
                data = errData;
            }
        );

    return data;
}
