import React, { Suspense, lazy, useState, useEffect } from 'react';
import {
    Switch,
    Route,
    useLocation,
    useRouteMatch,
    Redirect,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';

import DashboardAppBar from '../../components/DashboardAppBar';
import FetchLoading from '../../components/FetchLoading';
import AccountDashboardNavigation from '../../components/AccountDashboardNavigation/AccountDashboardNavigation';
import Billing from '../dashboard/Billing';
import {
    APP_PREFIX_PATHNAME,
    ACCOUNT_DASHBOARD_SECTION_ID,
    ACCOUNT_DASHBOARD_PATHNAME,
    ACCOUNT_PREFIX_PATHNAME
} from '../../utils/constant';
import styles from './AccountDashboard.styles';

const Setting = lazy(() => import(/* webpackChunkName: 'UserSetting' */ '../dashboard/Setting'));
const Account = lazy(() => import(/* webpackChunkName: 'Account' */ '../dashboard/Account'));
const SchoolList = lazy(() => import(/* webpackChunkName: "SchoolList" */ '../dashboard/SchoolList'));

export default function AccountDashboard() {
    const classes = styles();
    const user = useSelector(state => state.user);

    // state
    const [currentSectionId, setCurrentSectionId] = useState(ACCOUNT_DASHBOARD_SECTION_ID.none);

    const location = useLocation();
    const isUserSettingsPage = useRouteMatch(`/${APP_PREFIX_PATHNAME}/${ACCOUNT_PREFIX_PATHNAME}/${ACCOUNT_DASHBOARD_PATHNAME.userSettings}`);
    const isWebsitesPage = useRouteMatch(`/${APP_PREFIX_PATHNAME}/${ACCOUNT_PREFIX_PATHNAME}/${ACCOUNT_DASHBOARD_PATHNAME.websites}`);
    const isBillingPage = useRouteMatch(`/${APP_PREFIX_PATHNAME}/${ACCOUNT_PREFIX_PATHNAME}/${ACCOUNT_DASHBOARD_PATHNAME.billing}`);
    const isInactivePage = useRouteMatch(`/${APP_PREFIX_PATHNAME}/${ACCOUNT_PREFIX_PATHNAME}/${ACCOUNT_DASHBOARD_PATHNAME.inactive}`);

    useEffect(() => {
        // Ordering does matter, DO NOT CHANGE.
        if (isUserSettingsPage) {
            setCurrentSectionId(ACCOUNT_DASHBOARD_SECTION_ID.user);
        } else if (isWebsitesPage) {
            setCurrentSectionId(ACCOUNT_DASHBOARD_SECTION_ID.websites);
        } else if (isBillingPage) {
            setCurrentSectionId(ACCOUNT_DASHBOARD_SECTION_ID.billing);
        } else if (isInactivePage) {
            setCurrentSectionId(ACCOUNT_DASHBOARD_SECTION_ID.inactive);
        } else {
            // default to the user settings page
            setCurrentSectionId(ACCOUNT_DASHBOARD_SECTION_ID.user);
        }
    }, [location]); // eslint-disable-line

    return (
        <Box className={classes.root}>
            <DashboardAppBar />
            <AccountDashboardNavigation sectionId={currentSectionId} />
            <Box className='dashboardContentContainer'>
                <Switch>
                    {/* Need to be in this order, from most specific to least specific */}
                    <Route path={`/${APP_PREFIX_PATHNAME}/${ACCOUNT_PREFIX_PATHNAME}/${ACCOUNT_DASHBOARD_PATHNAME.user}`}>
                        <Suspense fallback={<FetchLoading />}>
                            <Setting user={user} />
                        </Suspense>
                    </Route>
                    <Route path={`/${APP_PREFIX_PATHNAME}/${ACCOUNT_PREFIX_PATHNAME}/${ACCOUNT_DASHBOARD_PATHNAME.websites}`}>
                        <Suspense fallback={<FetchLoading />}>
                            <SchoolList />
                        </Suspense>
                    </Route>
                    <Route path={`/${APP_PREFIX_PATHNAME}/${ACCOUNT_PREFIX_PATHNAME}/${ACCOUNT_DASHBOARD_PATHNAME.billing}`}>
                        <Billing user={user} />
                    </Route>
                    <Route path={`/${APP_PREFIX_PATHNAME}/${ACCOUNT_PREFIX_PATHNAME}/${ACCOUNT_DASHBOARD_PATHNAME.inactive}`}>
                        <Suspense fallback={<FetchLoading />}>
                            <Account user={user} />
                        </Suspense>
                    </Route>
                    <Redirect to={`/${APP_PREFIX_PATHNAME}/${ACCOUNT_PREFIX_PATHNAME}/${ACCOUNT_DASHBOARD_PATHNAME.user}`} />
                </Switch>
            </Box>
        </Box>
    );
}

