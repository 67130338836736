import { v4 as uuid } from 'uuid';

export const APP_NAME = 'Airteach';
export const SUPPORT_EMAIL = 'support@airteach.io';

export const API_ERROR_RESPONSE = 'Request error. Please try again later.';
export const INTERNAL_SERVER_ERR_MSG = 'Internal server error. Please try again later.';

export const DASHBOARD_APP_SUBDOMAIN = 'dashboard';
export const LANDING_PAGE_SUBDOMAIN = 'www';

export const UNLIMITED = 1000000; // a number that represent an unlimited amount

export const APP_PREFIX_PATHNAME = 'app';
export const ACCOUNT_PREFIX_PATHNAME = 'account';

export const DASHBOARD_SUBSCRIPTION_INACTIVE_REASON_ID = {
    no_payment_purchase: 1,
    payment_failed: 2,
    policy_violation: 3,
    requires_action: 4
};

export const LECTURE_ITEM_KEY = {
    TEXT: 'paragraph',
    IMAGE: 'image',
    EMBED_MEDIA: 'embed media',
    VIDEO: 'video',
    FILE: 'file',
    EMBED_CODE: 'embed code',
    CODE_EXAMPLE: 'code example',
    QUIZ: 'quiz',
    MULTIPLE_CHOICE: 'multiple choice question',
    OPEN_ENDED: 'open ended question',
    ONE_WORD: 'one word question',
    IMAGE_TEXT: 'image + paragraph',
    INTERACTIVE_CODE: 'interactive code'
};

// The dashboard plans that are shown in UI
export const DASHBOARD_PLAN_FIELDS = {
    BASIC_MONTHLY: 'basicMonthly',
    BASIC_ANNUAL: 'basicAnnual',
    PRO_MONTHLY: 'proMonthly',
    PRO_ANNUAL: 'proAnnual',
    ADVANCED: 'advanced'
};

export const LECTURE_ITEM_ID = {
    [LECTURE_ITEM_KEY.TEXT]: 1,
    [LECTURE_ITEM_KEY.IMAGE]: 2,
    [LECTURE_ITEM_KEY.VIDEO]: 3,
    [LECTURE_ITEM_KEY.FILE]: 4,
    [LECTURE_ITEM_KEY.EMBED_CODE]: 5,
    [LECTURE_ITEM_KEY.CODE_EXAMPLE]: 6,
    [LECTURE_ITEM_KEY.QUIZ]: 7,
    [LECTURE_ITEM_KEY.MULTIPLE_CHOICE]: 8,
    [LECTURE_ITEM_KEY.OPEN_ENDED]: 9,
    [LECTURE_ITEM_KEY.ONE_WORD]: 10,
    [LECTURE_ITEM_KEY.EMBED_MEDIA]: 11,
    [LECTURE_ITEM_KEY.INTERACTIVE_CODE]: 12
};

export const TEXT_LECTURE_ITEM_ID = {
    [LECTURE_ITEM_KEY.TEXT]: 1,
    [LECTURE_ITEM_KEY.IMAGE_TEXT]: 2,
    [LECTURE_ITEM_KEY.MULTIPLE_CHOICE]: 3,
    [LECTURE_ITEM_KEY.OPEN_ENDED]: 4,
    [LECTURE_ITEM_KEY.ONE_WORD]: 5
};
export const TEXT_CONTENT_TYPE = ['', '', '', LECTURE_ITEM_KEY.MULTIPLE_CHOICE, LECTURE_ITEM_KEY.OPEN_ENDED, LECTURE_ITEM_KEY.ONE_WORD];

// MC Type
export const SINGLE_ANSWER_TYPE_ID = 1;
export const MULTIPLE_ANSWER_TYPE_ID = 2;

// Lecture default snippets
export const DEFAULT_CODE_EXAMPLE_STR = '// This is a code example block. You can use it to add code example to your lecture.';
export const DEFAULT_INTERACTIVE_CODE_EXAMPLE_STR = '// This is an interactive code block. You can use it to add interactive code to your lecture.'
export const DEFAULT_EMBED_CODE_STR = '<p>This is an embed code block. You can use it to add embed code to your lecture.</p>';
export const DEFAULT_TEXT_STR = '<p>This is a text block. You can use it to add text to your lecture.</p>';
export const TEXTFLOW_DEFAULT_IMAGE_TEXT_STR = '<p>This is an optional text block to go along with your image.</p>';
export const DEFAULT_MULTIPLE_CHOICE_STR = 'This is a multiple choice question block. Edit to add question and options.';
export const DEFAULT_OPEN_ENDED_STR = 'This is an open ended question block. Edit to add question.';
export const DEFAULT_ONE_WORD_STR = 'This is an one word question block. Edit to add question and answer.'
export const DEFAULT_QUIZ_STR = 'This content is a quiz block. Edit to add a question and options.';
export const DEFAULT_CHAPTER_NAME = 'Untitled chapter';
export const DEFAULT_LECTURE_NAME = 'Untitled lecture';

export const TABLE_ROW_SIZE_OPTIONS = [10, 20, 30, 50, 100];

// course type id
export const WEB_COURSE_TYPE = 1;
export const TEXT_COURSE_TYPE = 2;

// upload file size limit
export const IMAGE_UPLOAD_SIZE_LIMIT =  10485760; // 10MB
export const FILE_UPLOAD_SIZE_LIMIT =  104857600; // 100MB
export const VIDEO_UPLOAD_SIZE_LIMIT =  1073741824; // 1GB

// text lecture image size limit
export const TEXT_IMAGE_SIZE_LIMIT = 2097152; // 2MB

// text flow
export const CURRENT_TEXT_LECTURE_NEW = 'CURRENT_TEXT_LECTURE_NEW';
export const TEXT_LECTURE_NEW = 'TEXT_LECTURE_NEW';
export const TEXT_LECTURE_LIST = 'TEXT_LECTURE_LIST';

// code example type key
export const CODE_EXAMPLE_TYPE_KEY = {
    JAVASCRIPT: 'JavaScript',
    JAVA: 'Java',
    PYTHON: 'Python',
    GO: 'Go',
    C: 'C',
    CPP: 'C++',
    RUBY: 'Ruby',
    TYPESCRIPT: 'TypeScript',
    JSON: 'JSON',
    HTML: 'HTML',
    XML: 'XML',
    EMBED_CODE: 'Embed Code',
}

// code example type id
export const CODE_EXAMPLE_TYPE_ID = {
    [CODE_EXAMPLE_TYPE_KEY.JAVASCRIPT]: 1,
    [CODE_EXAMPLE_TYPE_KEY.JAVA]: 2,
    [CODE_EXAMPLE_TYPE_KEY.PYTHON]: 3,
    [CODE_EXAMPLE_TYPE_KEY.GO]: 4,
    [CODE_EXAMPLE_TYPE_KEY.C]: 5,
    [CODE_EXAMPLE_TYPE_KEY.RUBY]: 6,
    [CODE_EXAMPLE_TYPE_KEY.CPP]: 7,
    [CODE_EXAMPLE_TYPE_KEY.TYPESCRIPT]: 8,
    [CODE_EXAMPLE_TYPE_KEY.JSON]: 9,
    [CODE_EXAMPLE_TYPE_KEY.HTML]: 10,
    [CODE_EXAMPLE_TYPE_KEY.XML]: 11,
    [CODE_EXAMPLE_TYPE_KEY.EMBED_CODE]: 12,
};

export const DASHBOARD_SECTION_ID = {
    none: 0,
    websites: 1,
    settings: 2,
    siteHome: 3,
    siteProducts: 4,
    siteSettings: 5,
    siteOffers: 6,
    sitePayment: 7,
    siteDomain: 8,
    siteContacts: 9,
    siteAnalytics: 10,
    siteCoupons: 11
};

export const DASHBOARD_PATHNAME = {
    websites: 'sites',
    settings: 'account',
    settingsWebsites: 'websites',
    billing: 'billing',
    account: 'account',
    siteHome: 'home',
    siteProducts: 'products',
    siteSettings: 'settings',
    siteOffers: 'offers',
    sitePayment: 'payment',
    siteDomain: 'domain',
    siteContacts: 'contacts',
    siteAnalytics: 'analytics',
    siteCoupons: 'coupons'
};

export const COURSE_DASHBOARD_SECTION_ID = {
    none: 0,
    dashboard: 1,
    lectures: 2,
    schedule: 3,
    pricing: 4,
    setting: 5
};

export const COURSE_DASHBOARD_PATHNAME = {
    dashboard: 'dashboard',
    lectures: 'lectures',
    schedule: 'schedule',
    setting: 'setting',
    student: 'student'
};

export const BILLING_DASHBOARD_PATHNAME = {
    payment: 'payment',
    history: 'history'
};

export const ACCOUNT_DASHBOARD_SECTION_ID = {
    none: 0,
    user: 1,
    websites: 2,
    billing: 3,
    inactive: 4,

};

export const ACCOUNT_DASHBOARD_PATHNAME = {
    user: 'user',
    billing: 'billing',
    websites: 'websites',
    inactive: 'inactive'
};

// these id must start from 0, and increment by 1
// as it is associated with the UI tab component index
export const WEBSITE_NAV_BAR_TAB_INDEX = {
    home: 0,
    courses: 1,
    settings: 2,
    offers: 3,
    payment: 4,
    domain: 5,
    contacts: 6,
    analytics: 7,
    coupons: 8
};

export const OFFER_NAV_BAR_ID = {
    details: 0,
    pricing: 1,
};

export const BILLING_NAV_BAR_ID = {
    payment: 0,
    history: 1,
};

export const LECTURE_OPTION = {
    publish: 'publish',
    delete: 'delete',
    rename: 'rename',
};

export const BREADCRUM_CONTAINER_HEIGHT = 47;
export const WEBSITE_NAV_BAR_HEIGHT = 49;

// drag and drop (DND) media box height
export const DND_CONTENT_HEIGHT = 250;

export const BUTTON_HEIGHT = '36px';

// cookie name
export const COOKIES = {
    SESS_ID: 'at_app_sess_id', // session id
    SESS_ID_SID: 'at_app_sess_id_sid',
    REM_USER_ID: 'at_app_rem_user_id', // remember me
    REM_USER_ID_SID: 'at_app_rem_user_id_sid',
};

export const COURSE_STUDENT_ORDER_BY_FIELDS = {
    full_name_asc: 'NAME_ASC',
    full_name_desc: 'NAME_DESC',
    email_asc: 'EMAIL_ASC',
    email_desc: 'EMAIL_DESC',
    joined_at_asc: 'JOIN_DATE_ASC',
    joined_at_desc: 'JOIN_DATE_DESC',
    last_login_asc: 'LAST_LOGIN_ASC',
    last_login_desc: 'LAST_LOGIN_DESC'
};

export const COURSE_TYPES = {
    web: 'web',
    sms: 'sms'
};

export const CUSTOM_DOMAIN_STATUS = {
    awaiting_dns_change: 1,
    awaiting_cloudflare_config: 2,
    awaiting_status_check: 3,
    active: 4,
    fail: 5
};

export const CUSTOM_DOMAIN_ERROR_CODE = {
    name_server_not_found: 1,
    domain_cname_is_non_empty: 2,
    subdomain_expected_cname_not_found: 3,
    cloudflare_failed: 4,
    verify_ssl_not_complete: 5
};

export const OFFER_PRICING_PLAN_ID = {
    free: 1,
    oneTimePurchase: 2,
    grantAccess: 3
};

export const ZERO_DECIMAL_CURRENCY_CODES = [
    'BIF',
    'JPY',
    'KMF',
    'KRW',
    'MGA',
    'RWF',
    'UGX',
    'VND',
    'VUV',
    'XAF'
];

export const CUSTOM_DOMAIN_CHECK_THRESHHOLD = 12;

export const APP_LOGO_URL = 'https://airteach-asset.s3.us-west-1.amazonaws.com/airteaching-logo.png';

export const DASHBOARD_NAV_DRAWER_WIDTH = 256;

export const APP_TOOL_BAR_HEIGHT = 64;
export const APP_TOOL_BAR_HEIGHT_MULTIPLIER = 8; // 8 * 8 = 64

// Analytics types
export const PRODUCT_MODAL_TYPE = 'product';
export const OFFER_MODAL_TYPE = 'offer';

export const CONTENT_BLOCK_LIST_TITLE_HEIGHT = 28;

// Iframely hashed key
// When this is changed, also update the iframely key in index.html.
export const IFRAMELY_HASHED_KEY = '33260994f66e48feb3562f969abbfaa3';

const LANGUAGES_ID = {
    javascript: 1,
    java: 2,
    python: 3,
    go: 4,
    c: 5,
    ruby: 6,
    cplusplus: 7,
    typescript: 8
};

export const LANGUAGE_ICON_CSS = {
    [LANGUAGES_ID.javascript]: 'devicon-javascript-plain',
    [LANGUAGES_ID.python]: 'devicon-python-plain',
    [LANGUAGES_ID.c]: 'devicon-c-plain',
    [LANGUAGES_ID.cplusplus]: 'devicon-cplusplus-plain',
    [LANGUAGES_ID.ruby]: 'devicon-ruby-plain',
    [LANGUAGES_ID.java]: 'devicon-java-plain',
    [LANGUAGES_ID.go]: 'devicon-go-plain',
    [LANGUAGES_ID.typescript]: 'devicon-typescript-plain'
}

export const LANGUAGES_LABEL = {
    [LANGUAGES_ID.javascript]: 'JavaScript',
    [LANGUAGES_ID.java]: 'Java',
    [LANGUAGES_ID.python]: 'Python',
    [LANGUAGES_ID.go]: 'Golang',
    [LANGUAGES_ID.c]: 'C',
    [LANGUAGES_ID.ruby]: 'Ruby',
    [LANGUAGES_ID.cplusplus]: 'C++',
    [LANGUAGES_ID.typescript]: 'TypeScript'
};

export const INTERACTIVE_CODE_TAB_TYPE_ID = {
    console: 1,
    instructions: 2,
    tests: 3
};

export const INTERACTIVE_CODE_NODE_TYPE_ID = {
    folder: 1,
    file: 2
};

export const INSTRUCTION_INTERACTIVE_NODE_PATH = '/instruction.md';
export const INTERACTIVE_CODE_MAIN_FILE_PATH = {
    [LANGUAGES_ID.javascript]: '/index.js',
    [LANGUAGES_ID.python]: '/index.py',
    [LANGUAGES_ID.java]: '/Main.java'
};

export const INTERACTIVE_CODE_SETUP_CODE_FILE_PATH = {
    [LANGUAGES_ID.javascript]: '/setupCode.js',
    [LANGUAGES_ID.python]: '/setupCode.py'
};

export const LANGUAGE_ID_INITIAL_TEMPLATE = {
    [LANGUAGES_ID.javascript]: testFunctionName => (
        `function ${testFunctionName}() {\n\n}`
    ),
    [LANGUAGES_ID.python]: testFunctionName => (
        `def ${testFunctionName}():\n\treturn`
    ),
    [LANGUAGES_ID.java]: testFunctionName => (
        `class Solution {\n\tpublic void ${testFunctionName}() {\n\n\t}\n}`
    )
};

export const INTERACTIVE_CODE_BUTTON_LABEL = {
    save: 'Save',
    run: 'Run',
    submit: 'Submit'
}

export const DEFAULT_INPUT_OUTPUT_TEST_CASE = {
    id: '',
    title: '',
    input: '',
    expectedOutput: '',
    elemId: uuid()
}

export const TOOLTIP_DELAY = 1000;

export const WEBSITE_URL_WITH_SUBDOMAIN = 'https://subdomain.airteach.io';

export const WEBSITE_URL_PATH = `/app/${DASHBOARD_PATHNAME.account}/${DASHBOARD_PATHNAME.settingsWebsites}?createNewWebsite=true`;

export const INTERACTIVE_CODE_TITLE_HEIGHT = 32;
export const INTERACTIVE_CODE_TITLE_MARGIN_BOTTOM = 16;

export const QUERY_STRING = {
    websiteCreated: 'websiteCreated'
};

export const SOMETHING_WENT_WRONG_MESSAGE = 'Something went wrong. Please try again later.';
