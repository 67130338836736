import {
    CODE_EXAMPLE_TYPE_KEY,
    CODE_EXAMPLE_TYPE_ID,
    DASHBOARD_APP_SUBDOMAIN,
    LANDING_PAGE_SUBDOMAIN,
    WEB_COURSE_TYPE,
    TEXT_COURSE_TYPE,
    DASHBOARD_PATHNAME
} from './constant';

const INTERVAL_TIME_MS = 300;

export function capitalize(name) {
    return name
        .split(' ')
        .map(str => str[0].toUpperCase() + str.slice(1).toLowerCase())
        .join(' ');
}

export function checkIsObj(str) {
    const strTrim = str.trim();
    if (strTrim[0] === "{" && strTrim[strTrim.length - 1] === "}") {
        return "object";
    } else if (strTrim[0] === "[" && strTrim[strTrim.length - 1] === "]") {
        return "array";
    }
    return null;
}

export function convertToStringObj(str) {
    let txt = str;
    // a regex to search for all the keys in an object string
    const objKeysRegex = /({|,)(?:\s*)(?:')?([A-Za-z_$.][A-Za-z0-9_ \-.$]*)(?:')?(?:\s*):/g;
    // convert all keys to quoted keys
    txt = txt.replace(objKeysRegex, "$1\"$2\":");
    if (checkIsObj(txt)) {
        txt = txt.replace(/ /g, "");
    }
    return txt;
}

export function stringToProperCase(str) {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

export function formatCurrency(num) {
    if (typeof num !== 'number') {
        return '';
    }

    return num / 100;
}

export function insertNewItem(arr, newItem, index) {
    return arr
        .slice(0, index)
        .concat(newItem, arr.slice(index));
}

export function updatePositionItem(arr, item, sourceIndex, destIndex) {
    const newArr = arr.slice(0, sourceIndex).concat(arr.slice(sourceIndex + 1));
    return newArr.slice(0, destIndex).concat(item, newArr.slice(destIndex));
}

export function getCodeExampleTypeStr(id) {
    let type;

    switch(id) {
        case CODE_EXAMPLE_TYPE_ID[CODE_EXAMPLE_TYPE_KEY.JAVASCRIPT]:
            type = CODE_EXAMPLE_TYPE_KEY.JAVASCRIPT
            break;
        case CODE_EXAMPLE_TYPE_ID[CODE_EXAMPLE_TYPE_KEY.JAVA]:
            type = CODE_EXAMPLE_TYPE_KEY.JAVA
            break;
        case CODE_EXAMPLE_TYPE_ID[CODE_EXAMPLE_TYPE_KEY.PYTHON]:
            type = CODE_EXAMPLE_TYPE_KEY.PYTHON
            break;
        case CODE_EXAMPLE_TYPE_ID[CODE_EXAMPLE_TYPE_KEY.GO]:
            type = CODE_EXAMPLE_TYPE_KEY.GO
            break;
        case CODE_EXAMPLE_TYPE_ID[CODE_EXAMPLE_TYPE_KEY.C]:
            type = CODE_EXAMPLE_TYPE_KEY.C
            break;
        case CODE_EXAMPLE_TYPE_ID[CODE_EXAMPLE_TYPE_KEY.RUBY]:
            type = CODE_EXAMPLE_TYPE_KEY.RUBY
            break;
        case CODE_EXAMPLE_TYPE_ID[CODE_EXAMPLE_TYPE_KEY.JSON]:
            type = CODE_EXAMPLE_TYPE_KEY.JSON
            break;
        case CODE_EXAMPLE_TYPE_ID[CODE_EXAMPLE_TYPE_KEY.TYPESCRIPT]:
            type = CODE_EXAMPLE_TYPE_KEY.TYPESCRIPT
            break;
        case CODE_EXAMPLE_TYPE_ID[CODE_EXAMPLE_TYPE_KEY.HTML]:
            type = CODE_EXAMPLE_TYPE_KEY.HTML
            break;
        case CODE_EXAMPLE_TYPE_ID[CODE_EXAMPLE_TYPE_KEY.XML]:
            type = CODE_EXAMPLE_TYPE_KEY.XML
            break;
        case CODE_EXAMPLE_TYPE_ID[CODE_EXAMPLE_TYPE_KEY.EMBED_CODE]:
            type = CODE_EXAMPLE_TYPE_KEY.EMBED_CODE
            break;
        default:
            type = "UNKNOWN"
    }

    return type;
}

// If this is updated, need to update the same function in student-ui repo too
export function getCodeExampleEditorTypeKey(id) {
    let type;

    switch(id) {
        case CODE_EXAMPLE_TYPE_ID[CODE_EXAMPLE_TYPE_KEY.JAVASCRIPT]:
            type = CODE_EXAMPLE_TYPE_KEY.JAVASCRIPT
            break;
        case CODE_EXAMPLE_TYPE_ID[CODE_EXAMPLE_TYPE_KEY.JAVA]:
            type = CODE_EXAMPLE_TYPE_KEY.JAVA
            break;
        case CODE_EXAMPLE_TYPE_ID[CODE_EXAMPLE_TYPE_KEY.PYTHON]:
            type = CODE_EXAMPLE_TYPE_KEY.PYTHON
            break;
        case CODE_EXAMPLE_TYPE_ID[CODE_EXAMPLE_TYPE_KEY.GO]:
            type = CODE_EXAMPLE_TYPE_KEY.GO
            break;
        case CODE_EXAMPLE_TYPE_ID[CODE_EXAMPLE_TYPE_KEY.CPP]:
        case CODE_EXAMPLE_TYPE_ID[CODE_EXAMPLE_TYPE_KEY.C]:
            type = "c_cpp"
            break;
        case CODE_EXAMPLE_TYPE_ID[CODE_EXAMPLE_TYPE_KEY.RUBY]:
            type = CODE_EXAMPLE_TYPE_KEY.RUBY
            break;
        case CODE_EXAMPLE_TYPE_ID[CODE_EXAMPLE_TYPE_KEY.JSON]:
            type = CODE_EXAMPLE_TYPE_KEY.JSON
            break;
        case CODE_EXAMPLE_TYPE_ID[CODE_EXAMPLE_TYPE_KEY.TYPESCRIPT]:
            type = CODE_EXAMPLE_TYPE_KEY.TYPESCRIPT
            break;
        case CODE_EXAMPLE_TYPE_ID[CODE_EXAMPLE_TYPE_KEY.HTML]:
            type = CODE_EXAMPLE_TYPE_KEY.HTML
            break;
        case CODE_EXAMPLE_TYPE_ID[CODE_EXAMPLE_TYPE_KEY.XML]:
            type = CODE_EXAMPLE_TYPE_KEY.XML
            break;
        case CODE_EXAMPLE_TYPE_ID[CODE_EXAMPLE_TYPE_KEY.EMBED_CODE]:
            type = CODE_EXAMPLE_TYPE_KEY.HTML
            break;
        default:
            type = "UNKNOWN"
    }

    return type.toLowerCase();
}

export function getLandingPageUrl() {
    const location = window.location;
    const host = location.host.replace(DASHBOARD_APP_SUBDOMAIN, LANDING_PAGE_SUBDOMAIN);

    return `${location.protocol}//${host}`;
}

export function isWebCourse(course={}) {
    return course.course_types_id === WEB_COURSE_TYPE;
}

export function isTextCourse(course={}) {
    return course.course_types_id === TEXT_COURSE_TYPE;
}

export function logPolling(method, MAX_TIME_MS) {
    const times = Math.floor(MAX_TIME_MS / INTERVAL_TIME_MS);
    async function runTime(current) {
        if (current <= 0) {
            return;
        }
        const isDone = await method(current <= 1);
        if (isDone) {
            return;
        }
        runTime(current - 1);
    }
    runTime(times);
}

export function updateIndividualOfferStore(offers, offerId, payload) {
    const newOffers = [...offers];
    const offerIndex = newOffers.findIndex(offer => offer.id === offerId);
    if (offerIndex >= 0) {
        const offer = newOffers[offerIndex];
        newOffers[offerIndex] = {
            ...offer,
            ...payload
        }
    }
    return newOffers;
}

export function isReactQuillEmpty(value) {
    if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0 && !value.includes("<img")) {
        return true;
    }

    return false;
}

export function isNil(value) {
    return value === null || value === undefined;
}

export function routeToLoginPageWithCurrentPath() {
    window.location.assign(`${window.location.origin}/login?redirect=${window.location.pathname}`);
}

/*
    remove leading 0 and . and convert decimal to whole number
    e.g. 0.01 -> 1
    e.g. 123.45 -> 12345
*/
export function convertDecimalToIntegerInCents(value) {
    let newVal = value.replace(/[.]/gi, '');
    for (let i = 0; i < newVal.length; i++) {
        const currVal = newVal[i];
        if (currVal !== '0') {
            return newVal.slice(i);
        }
    }
    return '';
}

export function getWebsiteUrl(websiteId) {
    return `${window.location.origin}/app/${DASHBOARD_PATHNAME.websites}/${websiteId}/${DASHBOARD_PATHNAME.siteHome}`;
}

/*
    Remove html tags and just return the string value
    e.g. <p>Hello world</p> -> Hello world
*/
export function removeHtmlTagsInString(value) {
    if (!value) {
        return '';
    }
    return value.replace(/(<([^>]+)>)/gi, '');
}

/*
    Converts a string with spaces to camel case
    e.g. Hello world => helloWorld
*/
export function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => (
        index === 0 ? word.toLowerCase() : word.toUpperCase()
    )).replace(/\s+/g, '');
}
